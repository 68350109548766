<div class="container">
  <div class="header">
    <div class="heading-container">
      <ng-content select="[slot=heading]"></ng-content>
    </div>
    <div class="operations-container">
      @if (!disableNavigation && !isEditing && isSmallScreen()) {
        <button class="open-left-drawer" mat-flat-button (click)="toggleNavigationDrawer()">
          <mat-icon class="material-symbols-outlined" matButtonIcon>view_sidebar</mat-icon>
          {{ 'TRANS__OBJECT_PAGE_V2__LAYOUT__SHOW_MENU' | translate }}
        </button>
      }
      <ng-content select="[slot=operations]"></ng-content>
    </div>
    <div class="search-navigation-container">
      <ng-content select="[slot=search-navigation]"></ng-content>
    </div>
  </div>
  <div class="content">
    <mat-sidenav-container [class.small-screen]="isSmallScreen()" #matSidenavContainer>

      @if (!disableNavigation) {
        <mat-sidenav class="left" #navigationDrawer [opened]="!isEditing && !isSmallScreen()" [disableClose]="!isSmallScreen()" [mode]="isSmallScreen() ? 'over' : 'side'"
                     [fixedInViewport]="isSmallScreen()"
                     [fixedTopGap]="isSmallScreen() ? 50 : undefined" [fixedBottomGap]="0" position="start"
        >
          <div class="left-drawer-inner-container">
            <ng-content select="[slot=left-drawer]"></ng-content>
          </div>
        </mat-sidenav>
      }

      <mat-sidenav-content [class.edit-mode]="isEditing">
        <ng-content></ng-content>
        <div class="floating-button-container">
          <button class="toggle-right-drawer" mat-fab extended (click)="rightDrawer.toggle()">
            <mat-icon class="material-symbols-outlined">view_sidebar</mat-icon>
            <!-- todo: move label to parent component -->
            @if (rightDrawer.opened) {
              {{ 'TRANS__OBJECT_PAGE_V2__LAYOUT__HIDE_IMAGES' | translate }}
            } @else {
              {{ 'TRANS__OBJECT_PAGE_V2__LAYOUT__SHOW_IMAGES' | translate }}
            }
          </button>
        </div>
      </mat-sidenav-content>

      <mat-sidenav appResizable [appResizableEnabled]="!isSmallScreen()" (appResizableAfterResized)="matSidenavContainer.updateContentMargins()" class="right" #rightDrawer [opened]="!isSmallScreen()" [mode]="isSmallScreen() ? 'over' : 'side'"
                   [fixedInViewport]="isSmallScreen()"
                   [fixedTopGap]="isSmallScreen() ? 50 : undefined" [fixedBottomGap]="0" position="end" [style.width]="defaultImageDrawerWidth">
        <ng-content select="[slot=right-drawer]"></ng-content>
        @if (isSmallScreen()) {
          <div class="floating-button-container">
            <button class="toggle-right-drawer" mat-mini-fab extended (click)="rightDrawer.close()">
              <mat-icon class="material-symbols-outlined">close</mat-icon>
            </button>
          </div>
        }
      </mat-sidenav>

    </mat-sidenav-container>
  </div>
</div>
