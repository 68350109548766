<mat-card>
  <mat-card-content>
    <div class="progress-dialog">
      <mat-spinner diameter="50"></mat-spinner>
      @if (!!data?.descriptorText) {
        @if (!!data?.count) {
          <div class="progress-dialog__text bold" [translate]="data.descriptorText" [translateParams]="{count: data.count}"></div>
        }
        @else {
          <div class="progress-dialog__text bold" [translate]="data.descriptorText"></div>
        }
      }
    </div>
  </mat-card-content>
</mat-card>
