import {Component, effect, EventEmitter, Input, OnInit, Output, signal, WritableSignal} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {UiToolsService} from '../../core/ui-tools.service';
import {MediaHelperService} from '../../core/media-helper.service';
import {CmsApiService} from '../../core/cms-api.service';
import {UploadService} from '../../core/upload.service';
import {UploadInfo} from '../../core/definitions/upload-info';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {GetArtifactParams} from '../../core/definitions/get-artifact-params';
import {ImageItem} from '../../core/definitions/image-item';
import {MediaItem} from '../../core/definitions/media-item';
import {VideoItem} from '../../core/definitions/video-item';
import {AttachmentItem} from '../../core/definitions/attachment-item';
import {CopyObjectParams} from '../../core/definitions/copy-object-params';
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

export interface snackbarObject {
  text: string,
  type: string
}

@Component({
  selector: 'app-media-selector-general',
  templateUrl: './media-selector-general.component.html',
  styleUrls: ['./media-selector-general.component.scss']
})
export class MediaSelectorGeneralComponent implements OnInit {

  @Input() uploadInfo: UploadInfo;
  @Input() parentObject: SuperObjectModel;
  @Input() files: MediaItem[];

  @Output() uploadComplete: EventEmitter<boolean>;
  @Output() updateImage: EventEmitter<object>;
  @Output() deleteImage?: EventEmitter<MediaItem>;

  AConst = AConst;
  objectType: string;
  multipleFiles = true;
  fileType: string;
  fileAccept: string;
  fromCamera = false;
  selectFromParent = false;
  selectImg = false;
  cameraOpen = false;
  parentMedia: Array<MediaItem> = [];
  computer = false;
  selectedItems = [];
  snackBarSignal: WritableSignal<snackbarObject> = signal({text: '', type: ''})

  constructor(private uiTools: UiToolsService,
              private mediaHelper: MediaHelperService,
              private cms: CmsApiService,
              private uploadService: UploadService,
              private translate: TranslateService,
              public _snackBar: MatSnackBar) {
    this.uploadComplete = new EventEmitter<boolean>();
    this.updateImage = new EventEmitter<object>();
    this.deleteImage = new EventEmitter<MediaItem>();

    effect(() => {
      if (this.snackBarSignal().text !== '') {
        this._snackBar.open(this.snackBarSignal().text,
          null,
          {
            duration: 8000,
            panelClass: `media-selector-general__snackbar-panel-${this.snackBarSignal().type}`,
            horizontalPosition: 'center',
            verticalPosition: 'bottom'
          }
        );
      }
    });
  }

  ngOnInit() {
    const contexts = this.parentObject.contexts;
    const parentId = this.parentObject.parent_id;
    const contextId = this.parentObject.context?.context_id;
    this.objectType = this.uploadInfo.object_type;
    this.multipleFiles = this.uploadInfo.multiple_files;
    this.fileType = this.uploadInfo.file_type;
    this.fileAccept = this.uploadInfo.file_accept;
    this.fromCamera = this.uploadInfo.from_camera;
    this.selectFromParent = this.uploadInfo.select_from_parent;


    if (contexts && contexts.length > 0) {
      contexts.forEach(context => {
        this.loadParentMedia(context.context_id);
      });
    }
    if (parentId) {
      this.loadParentMedia(parentId);
    }
    if (contextId) {
      this.loadParentMedia(contextId);
    }

    this.computer = this.uiTools.getClientType().isComputer;
  }

  handleFileDeleted($e: any) {
    this.deleteImage.emit($e);
  }

  openCamera() {
    this.cameraOpen = !this.cameraOpen;
    this.selectImg = false;
  }

  uploadFile() {
    this.selectImg = false;
    this.cameraOpen = false;
  }

  selectFiles() {
    this.selectImg = !this.selectImg;
    this.cameraOpen = false;
  }

  // At the drag drop area
  // (drop)="onDropFile($event)"
  onDropFile(event: any) {
    event.preventDefault();
    if (event.dataTransfer.files.length && event.dataTransfer.files.length > 0) {
      this.uploadFiles(event.dataTransfer.files);
    }
  }

  // At the drag drop area
  // (dragover)="onDragOverFile($event)"
  onDragOverFile(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  // At the file input element
  // (change)="selectFile($event)"
  onFileSelect(event: any) {
    this.uploadFiles(event.target.files);
  }

  uploadFiles(files: File[]) {
    this.uploadService.uploadFiles(files, this.fileType, this.parentObject).then(res => {
        if (!this.multipleFiles) {
          this.files[0] = res[0];
          this.updateImage.emit(res[0]);
        } else {
          this.files.push(...res);
        }
        this.uploadComplete.emit(true);

        this.snackBarSignal.set({
          text: files.length > 1 ? this.translate.instant('TRANS__IMAGE_SELECTOR__PREPARING_CONVERT_COUNT', {count: files.length}) : this.translate.instant('TRANS__IMAGE_SELECTOR__PREPARING_CONVERT'),
          type: 'information'
        });
      },
      err => {
        console.error(err);
        this.uploadComplete.emit(false);
      }
    );
  }

  private loadParentMedia(parentId: string) {
    this.loadNewParentMedia(parentId).then(media => {
      this.mediaHelper.addImageUrls(media).then(() => {
        this.parentMedia = this.parentMedia.concat(media);
      });
    });
  }

  private loadNewParentMedia(parentId: string): Promise<Array<MediaItem>> {
    return new Promise(resolve => {
      this.cms.getArtifactImages({artifact_id: parentId}).then(
        images => {
          const newImages: Array<ImageItem> = images.images;
          const res: Array<MediaItem> = [];
          newImages.forEach(newImage => {
            let exists = false;
            this.parentMedia.forEach(existingImg => {
                if (newImage.artifact_id === existingImg.artifact_id) {
                  exists = true;
                }
              }
            );
            if (!exists) {
              res.push(newImage);
            }
          });
          resolve(res);

        },
        function (status) {
          console.warn('Failed with code: ' + status);
        }
      );
    });
  }

  private getArtifactParams(item: MediaItem): GetArtifactParams {
    const itemType = item.object_type;
    const getParams = new GetArtifactParams();
    switch (itemType) {
      case 'ImageItem':
        const imageItem = <ImageItem>item;
        getParams.artifact_id = imageItem.image_id;
        break;
      case 'VideoItem':
        const videoItem = <VideoItem>item;
        getParams.artifact_id = videoItem.video_id;
        break;
      case 'AttachmentItem':
        const attachmentItem = <AttachmentItem>item;
        getParams.artifact_id = attachmentItem.attachment_id;
        break;
      default:
        console.warn('No handling of item type ' + itemType);
    }
    return getParams;
  }

  async onSelectedItem(item: MediaItem) {
    let alreadyAdded = false;
    const getParams = this.getArtifactParams(item);
    if (getParams) {
      const object = await this.cms.getArtifact(getParams);
      this.files.forEach(file => {
        if (file.artifact_id === object.artifact_id) {
          alreadyAdded = true;
        }
      });
      if (!alreadyAdded) {
        let objectCopy: any;
        objectCopy = await this.cms.copyArtifact({
          artifact_id: object.artifact_id,
          object_type: object.object_type
        } as CopyObjectParams);
        const storedRes = await this.cms.saveArtifact({artifact: objectCopy});
        objectCopy.artifact_id = storedRes.artifact_id;
        this.files.push(objectCopy);
      }
    }
    this.selectImg = false;
  }

}
