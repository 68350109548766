import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FieldParameters} from '../../../core/definitions/field-parameters';
import {Reference} from '../../../core/definitions/reference';
import {OptionsDialogService} from '../../options-dialog.service';

@Component({
  selector: 'app-hierarchic-create-option-menu',
  templateUrl: './hierarchic-create-option-menu.component.html',
  styleUrls: ['./hierarchic-create-option-menu.component.scss']
})
export class HierarchicCreateOptionMenuComponent {

  @Input() fieldParameters: FieldParameters;
  @Input() reference: Reference;
  @Input() query: any;
  @Input() isArray: boolean;
  @Input() isSingleItemArray: boolean;
  @Input() currentOpenNode: any;

  @Output() createOptionEvent = new EventEmitter<object>();
  @Output() createOptionDialogOpen = new EventEmitter<object>();

  closeResult: string;
  openModal = false;

  constructor(private optionsDialogService: OptionsDialogService) { }
  createOptionWithKey(event) {
    if (event.key === 'Enter') { // enter and space
      // event.preventDefault();
      event.target.focus();
      this.createOption();
    }
  }

  createOption() {
    this.openModal = true;
    this.fieldParameters.sectionsContainer.isSecondDialog = this.fieldParameters.sectionsContainer.isDialog;
    this.fieldParameters.sectionsContainer.isDialog = true;
    const parentId = this.currentOpenNode?.artifact_id;
    this.createOptionDialogOpen.emit({});
    this.optionsDialogService.createOption(this.fieldParameters, null, parentId).then(option => {
      this.onOptionsSelected([option]);
      if (this.isArray && !this.isSingleItemArray) {
        this.setQueryValue('');
        this.setInputToFocus();
      }
    }, (reason) => {
      if (reason) {
        this.closeResult = `Dismissed ${reason}`;
      }
      this.openModal = false;
    });
  }

  createOptionBlur() {
    if (!this.openModal && !this.reference?.search_kulturnav) {
      this.toggleShowOptions(false);
    }
  }

  searchCultureHubBlur() {
    if (!this.openModal) {
      this.toggleShowOptions(false);
    }
  }

  searchCultureHub() {
    this.openModal = true;
    this.optionsDialogService.searchCultureHub(
      this.fieldParameters.field,
      this.isArray && !this.isSingleItemArray,
      this.query?.value).then(options => {
      this.onOptionsSelected(options);
    }, (reason) => {
      this.closeResult = `Dismissed ${reason}`;
      this.openModal = false;
    });
  }

  searchCultureHubWithKey(event) {
    if (event.key === 'Enter') { // enter and space
      // event.preventDefault();
      event.target.focus();
      this.searchCultureHub();
    }
  }

  private onOptionsSelected(options) {
    this.createOptionEvent.emit({type: 'optionSelected', data: options});
  }

  private toggleShowOptions(toggle) {
    this.createOptionEvent.emit({type: 'toggleShowOptions', data: toggle});
  }

  private setQueryValue(value) {
    this.createOptionEvent.emit({type: 'setQueryValue', data: value});
  }

  private setInputToFocus() {
    this.createOptionEvent.emit({type: 'setInputToFocus'});
  }
}
