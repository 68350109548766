import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {AccessTokenService} from '../core/access-token.service';
import {v4 as uuid} from 'uuid';

export interface TipsArticle {
  title: string;
  description: string;
}

@Injectable({
  providedIn: 'root'
})
export class HelpdeskApiService {

  constructor(
    private http: HttpClient,
    private accessTokenService: AccessTokenService,
  ) { }
  private baseURL = `${environment.ekulturAPIGatewayURL}/helpdesk-api`;

  getTipsAndTricksArticles(lang?: string): Observable<{
    items: TipsArticle[]
  }> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.accessTokenService.getToken(),
      'Id-Token': this.accessTokenService.getIdToken(),
      'Correlation-Id': uuid()
    });
    const params = {
      language: lang,
      appId: environment.ekulturPrimusAppID,
    }
    return this.http.get<{
      items: TipsArticle[]
    }>(`${this.baseURL}/solution-articles/`, {
      headers,
      params,
    });
  }
}
