import {Component, inject, signal} from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDivider} from '@angular/material/divider';
import {MatBadge} from '@angular/material/badge';
import {MatCard, MatCardContent} from '@angular/material/card';
import {MatTooltip} from '@angular/material/tooltip';
import {Router, RouterLink} from '@angular/router';
import {ListItem, SearchSuggestion, WelcomeScreenUiComponent} from './ui/welcome-screen-ui.component';
import {WelcomeService} from './welcome.service';
import {PrimusBackendInstanceService} from '../core/primus-backend-instance.service';
import {RecentRegistration} from './ui/recent-registrations-list/recent-registrations-list.component';
import {UserCacheService} from '../core/user-cache.service';
import {TranslateModule} from '@ngx-translate/core';
import {OperationDef} from '../core/definitions/operation-def';
import {iconToMaterialSymbol} from '../shared/material-icons';
import {MatDialog} from '@angular/material/dialog';
import {CreateObjectDialogComponent} from './create-object-dialog/create-object-dialog.component';
import {FeatureFlagsService} from '../core/feature-flags.service';
import {TipsAndTricksWidgetComponent} from './tips-and-tricks-widget/tips-and-tricks-widget.component';
import {FeatureFlagsWidgetComponent} from './feature-flags-widget/feature-flags-widget.component';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';

@Component({
  selector: 'app-welcome-screen',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatDivider,
    MatBadge,
    MatCard,
    MatCardContent,
    MatTooltip,
    RouterLink,
    WelcomeScreenUiComponent,
    TranslateModule,
    TipsAndTricksWidgetComponent,
    FeatureFlagsWidgetComponent,
    MatMenu,
    MatMenuTrigger,
  ],
  templateUrl: './welcome-screen.component.html',
  styleUrl: './welcome-screen.component.scss'
})
export class WelcomeScreenComponent {

  constructor(
    private router: Router,
    private welcomeService: WelcomeService,
    private userCacheService: UserCacheService,
    private features: FeatureFlagsService,
  ) {
    this.welcomeService.init();
  }

  readonly dialog = inject(MatDialog);


  savedSearches = this.welcomeService.savedSearches.asReadonly()
  loadingSavedSearches = this.welcomeService.loadingSavedSearches.asReadonly()
  objectList = this.welcomeService.objectList.asReadonly()
  loadingObjectList = this.welcomeService.loadingObjectList.asReadonly()
  categories = this.welcomeService.categories.asReadonly()
  loadingCategories = this.welcomeService.loadingCategories.asReadonly()
  canUseAdvancedSearch = this.welcomeService.canUseAdvancedSearch
  loadingRecentRegistrations = this.welcomeService.loadingRecentRegistrations.asReadonly()
  recentRegistrations = this.welcomeService.recentRegistrations.asReadonly()

  onlyMyOwnRecentRegistrations = signal(false);

  createNewOperations = this.welcomeService.createNewOperations.asReadonly()

  museum = PrimusBackendInstanceService.getInstanceDetails();

  onListItemClick(listItem: ListItem) {
    const params: any = {
      clear: true,
    };
    if ('path' in listItem && typeof listItem.path === 'string') {
      params.path = listItem.path;
    } else if ('focus' in listItem && 'focusId' in listItem) {
      params.cur_focus_id = listItem.focusId;
    } else {
      console.warn('Could not navigate: missing path in listItem.', listItem);
      return;
    }
    params.search_result_view = listItem.view;
    this.router.navigate(['/search'], {
      queryParams: params,
    })
  }

  onAdvancedSearchClick() {
    this.router.navigate(['/search'], {
      queryParams: {
        clear: true,
        advanced_search: true
      },
    })
  }

  onSearchSubmit($event: { query: string }) {
    this.router.navigate(['/search'], {
      queryParams: {
        clear: true,
        query: $event.query
      },
    })
  }

  onEditFocusesClick() {
    this.router.navigate(['/search'], {
      queryParams: {
        editSearchFocus: true
      },
    });
  }

  onSearchSuggestionClick(suggestion: SearchSuggestion) {
    this.router.navigate(['/search'], {
      queryParams: {
        path: 'home/artifacts',
        searchSuggestionId: suggestion.id,
      }
    });
  }

  onRecentRegistrationClick(recentRegistration: RecentRegistration) {
    if (this.features.getFeatureFlags().experimental.useNewObjectPage) {
      this.router.navigate(['/artifact', recentRegistration.artifact_id], {
        queryParams: {
          artifactId: recentRegistration.artifact_id
        }
      })
    } else {
      this.router.navigate(['/artifact'], {
        queryParams: {
          artifactId: recentRegistration.artifact_id
        }
      })
    }
  }

  onOnlyMyOwnRegistrationsChange(onlyMyOwn: boolean) {
    this.onlyMyOwnRecentRegistrations.set(onlyMyOwn);

    if (onlyMyOwn) {
      this.userCacheService.getUserId().then(userId => {
        this.welcomeService.loadRecentRegistrations(userId);
      });
    } else {
      this.welcomeService.loadRecentRegistrations();
    }
  }

  onCreateNewOperationClick(operation: OperationDef) {
    const { objectType } = operation.operation_steps?.[0]?.change_state?.[0]?.state_params || {};
    if (objectType) {
      this.router.navigate(['/new'], {
        queryParams: {
          objectType,
        }
      })
    }
  }

  getOperationSymbol(operation: OperationDef) {
    return iconToMaterialSymbol(operation.menu_icon);
  }

  onCreateNewClick() {
    this.dialog.open(CreateObjectDialogComponent, {
      width: '90dvw',
      maxWidth: '800px',
    });
  }
}
