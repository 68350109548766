import {Injectable} from '@angular/core';
import {AConst} from '../core/a-const.enum';
import {ContentInfo} from '../core/definitions/content-info';
import {SearchExecutorService} from '../object-search/search-executor.service';
import {PrimusRouteService} from '../core/primus-route.service';
import {UserCacheService} from '../core/user-cache.service';
import {ObjectContentListSourceArrayService} from './object-content-list-source-array.service';
import {ObjectContentListService} from './object-content-list.service';
import {
  ContentMenu,
  ContentMenuObjectSourceList,
  ContentMenuSearchView
} from '../core/definitions/object-content-tab/content-menus';
import {ContentListContainer} from '../core/definitions/object-content-tab/content-list-container';

@Injectable({
  providedIn: 'root'
})
export class ContentMenusService {

  constructor(private primusRoute: PrimusRouteService,
              private searchExecutorService: SearchExecutorService,
              private userCacheService: UserCacheService,
              private objectContentListService: ObjectContentListService,
              private objectContentListSourceArrayService: ObjectContentListSourceArrayService) {
  }

  async getMenus(contentInfo: ContentInfo): Promise<ContentMenu[]> {
    const contentMenus = await this.userCacheService.getContentMenus();
    const menus = contentMenus[contentInfo.artifact.object_type] || [];
    if (!menus.length) {
      console.warn('No content menus defined for object type ' + contentInfo.artifact.object_type);
    } else {
      menus[0].isContentView = true;
    }
    return menus;
  }

  async setContentMenus(menus: ContentMenu[], contentInfo: ContentInfo, $stateParams: any) {
    contentInfo.contentListContainers = {};
    await this.loopSetContentMenus(menus, contentInfo, $stateParams);
    this.setRecursiveMenuCount(contentInfo.menus);
    this.setActiveTopMenu(menus, contentInfo);
  }

  async setVerticalContentMenus(menus: ContentMenu[], contentInfo: ContentInfo) {
    const stateParams = this.primusRoute.params;
    contentInfo.contentListContainers = {};
    await this.loopSetContentMenus(menus, contentInfo, stateParams);
    this.setRecursiveMenuCount(contentInfo.menus);
    this.setActiveVerticalMenu(menus, contentInfo);
  }

  setActiveTopMenu(menus: Array<ContentMenu>, contentInfo: ContentInfo) {
    if (contentInfo.curListName) {
      menus.forEach(menu => {
        menu.open = false;
        if (menu.sub_menus) {
          menu.sub_menus.forEach(sm => {
              sm.open = false;
              if (sm[AConst.CONTENT_LIST] ===
                contentInfo.curListName) {
                menu.open = true;
                sm.open = true;
                contentInfo.selectedMainMenu = menu;
              }
            }
          );
        } else {
          if (menu.content_list === contentInfo.curListName) {
            menu.open = true;
            contentInfo.selectedMainMenu = menu;
          }
        }
      });
    }
  }

  setActiveVerticalMenu(menus: Array<ContentMenu>, contentInfo: ContentInfo) {
    if (contentInfo.curListName) {
      menus.forEach(menu => {
        menu.open = false;
        if (menu.content_list === contentInfo.curListName) {
          menu.open = true;
          contentInfo.selectedMainMenu = menu;
        }
      });
    }
  }

  getMenuFromListName(listName: string, contentInfo: ContentInfo) {
    const res = this.loopGetMenuFromListName(contentInfo.menus, listName);
    if (!res) {
      console.warn('Unable to retrieve menu from list name ' + listName);
    }
    return res;
  }

  async runListSearch(listName: string, contentInfo: ContentInfo): Promise<void> {
    const sc = contentInfo.contentListContainers[listName].searchContainer;
    if (sc) {
      sc.noFullSearch = contentInfo.curListName !== listName;
      const contentArray = contentInfo.artifact[listName];
      if (contentArray === undefined || contentArray.length > 0) {
        const res = await this.searchExecutorService.runSearch(sc);
        if (res) {
          this.setMenuSearchResult(res.search_count, listName, contentInfo);
        } else {
          console.error('Something went wrong!');
        }
      } else {
        this.setMenuSearchResult(0, listName, contentInfo);
      }
    } else {
      this.setMenuSearchResult(0, listName, contentInfo);
    }
  }

  private async loopSetContentMenus(menus: Array<ContentMenu>, contentInfo: ContentInfo, $stateParams: any) {
    for (let t = 0; t < menus.length; t++) {
      const menu = menus[t];
      await this.setContentMenu(menu, contentInfo, $stateParams);
      if (menu.sub_menus) {
        await this.loopSetContentMenus(menu.sub_menus, contentInfo, $stateParams);
      }
    }
  }

  private async setContentMenu(menu: ContentMenu, contentInfo: ContentInfo, $stateParams: any): Promise<void> {
    const listName = menu.content_list;
    switch (menu.content_menu_type) {
      case AConst.CONTENT_MENU_SEARCH_VIEW:
        const contentListContainer = new ContentListContainer();
        const contentListSearchContainer = await this.objectContentListService.getListSearchContainer(
          <ContentMenuSearchView>menu, contentInfo, $stateParams);
        contentListContainer.searchContainer = contentListSearchContainer;
        contentInfo.contentListContainers[listName] = contentListContainer;
        const listArray = contentInfo.artifact[listName];
        if (listArray !== undefined) {
          menu.count = contentInfo.artifact[listName].length;
        } else if (menu.superobject_type_id) {
          const listObjects = this.objectContentListService.getMenuObjectsFromArray(contentInfo.artifact, menu.superobject_type_id);
          menu.count = listObjects.length;
        } else if (contentInfo.curListName !== listName) {
          // this.setMenuSearchResult(0, listName, contentInfo);
        }
        this.objectContentListService.setListOperationsContainer(<ContentMenuSearchView>menu, contentInfo, listName);
        if (contentInfo.curListName === listName) {
          await this.runListSearch(listName, contentInfo);
        }
        break;
      case AConst.CONTENT_MENU_OBJECT_SOURCE_LIST:
        const listMenu = this.getMenuFromListName(listName, contentInfo);
        const sourceListMenu = <ContentMenuObjectSourceList>menu;
        const contentListSource = await this.objectContentListSourceArrayService.createContentListSource(
          sourceListMenu, contentInfo.artifact, contentInfo.artifact);
        contentInfo.sources = contentInfo.sources || {};
        contentInfo.sources[listName] = contentListSource;
        const sourceArray = this.objectContentListSourceArrayService.getSourceItems(contentInfo.artifact, contentListSource);
        if (listMenu) {
          listMenu.count = sourceArray ? sourceArray.length : 0;
        }
        if (contentInfo.curListName === listName) {
          contentInfo.setContentListSource(contentInfo.sources[listName]);
        }
        break;
    }
  }

  private loopGetMenuFromListName(menus: Array<ContentMenu>, listName: string): ContentMenu {
    let res: ContentMenu;
    for (let t = 0; t < menus.length; t++) {
      const menu = menus[t];
      if (menu.content_list === listName) {
        res = menu;
        break;
      }
      if (menu.sub_menus) {
        res = this.loopGetMenuFromListName(menu.sub_menus, listName);
        if (res) {
          break;
        }
      }
    }
    return res;
  }

  private setMenuSearchResult(count: number, listName: string, contentInfo: ContentInfo) {
    const menu = this.getMenuFromListName(listName, contentInfo);
    if (menu) {
      menu.count = count;
      this.setRecursiveMenuCount(contentInfo.menus);
    }
    if (contentInfo.curListName === listName) {
      contentInfo.curListContainer = contentInfo.contentListContainers[listName];
    }
  }

  private getRecursiveMenuCount(menu: ContentMenu) {
    if (menu.sub_menus) {
      menu.count = 0;
      menu.sub_menus.forEach(sm => {
        menu.count += this.getRecursiveMenuCount(sm);
      });
    }
    return menu.count;
  }

  private setRecursiveMenuCount(menus: ContentMenu[]) {
    menus.forEach(menu => {
      menu.count = this.getRecursiveMenuCount(menu);
    });
  }


}
