import {
  AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, signal, ViewChild
} from '@angular/core';
import {SearchContainer} from '../../../core/definitions/search-container';
import {AsyncPipe, JsonPipe} from '@angular/common';
import {MatChip, MatChipGrid, MatChipListbox, MatChipRemove, MatChipRow, MatChipSet} from '@angular/material/chips';
import {MatIcon} from '@angular/material/icon';
import {SharedModule} from '../../../shared/shared.module';
import {SearchFilterService} from '../../search-filter.service';
import {MatButton, MatIconButton} from '@angular/material/button';
import {FilterChip, searchContainerToChipList} from './searchContainerToChipList';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {SearchViewCategoryMenuService} from "../../search-view-category-menu.service";
import {SearchExecutorService} from '../../search-executor.service';

function chipEquals(a: FilterChip, b: FilterChip) {
  return a.key === b.key;
}

@Component({
  selector: 'app-search-filter-chiplist',
  standalone: true,
  imports: [JsonPipe, MatChipRow, MatChipGrid, MatChipListbox, MatChip, MatChipRemove, MatIcon, MatChipSet, AsyncPipe, SharedModule, MatButton, MatIconButton],
  templateUrl: './search-filter-chiplist.component.html',
  styleUrl: './search-filter-chiplist.component.scss'
})
export class SearchFilterChiplistComponent implements OnDestroy, AfterViewInit {
  @Input() searchContainer!: SearchContainer;
  @Output() filterRemoved = new EventEmitter<void>();

  @ViewChild('scrollContainer') scrollContainer: ElementRef<HTMLDivElement>;

  chips = signal<FilterChip[]>([])
  private subscriptions: Subscription[] = [];

  constructor(private searchFilterService: SearchFilterService,
              private searchExecutorService: SearchExecutorService,
              private categoryMenuService: SearchViewCategoryMenuService) {
  }

  private searchExecutorChanged$ = new Subject<void>();

  ngAfterViewInit() {

    this.searchExecutorService.subscribeToSearchResult(this.searchContainer, () => {
      this.searchExecutorChanged$.next();
    })

    this.updateChips();
    this.subscriptions.push(this.searchExecutorChanged$.pipe(debounceTime(100),).subscribe(() => {
      this.updateChips();
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  async onRemoveChipClick(chip: FilterChip) {
    await this.removeChip(chip);
    this.filterRemoved.emit();
  }

  async onClearAllClick() {
    await Promise.all(this.chips().map((chip) => this.removeChip(chip)))
    this.filterRemoved.emit();
  }

  private updateChips() {
    const oldChips = this.chips();
    if (this.searchContainer.currentPathView?.search_view?.menus) {
      this.categoryMenuService.getCategoryMenus(this.searchContainer.currentPathView.search_view.menus).then(categoryMenus => {
        const newChips = searchContainerToChipList(this.searchContainer, categoryMenus);
        const addedChips = newChips.filter((chip) => !oldChips.some((oldChip) => chipEquals(chip, oldChip)));
        const removedChips = oldChips.filter((chip) => !newChips.some((newChip) => chipEquals(chip, newChip)));
        this.chips.set(oldChips
          .filter(
            (chip) => !removedChips.some((removedChip) => chipEquals(chip, removedChip))
          ).concat(addedChips)
        );

        setTimeout(() => {
          if (addedChips.length && this.scrollContainer) {
            this.scrollContainer.nativeElement.scrollTo({
              left: this.scrollContainer.nativeElement.scrollWidth, behavior: 'smooth'
            })
          }
        })
      })
    }

  }

  private async removeChip(chip: FilterChip) {
    if (chip.type === "searchMenu") {
      await this.searchFilterService.checkMenuFilter(chip.menu, this.searchContainer, true);
    } else if (chip.type === "checkFilter") {
      await this.searchFilterService.checkCheckFilter(chip.filter, this.searchContainer, false, true);
    } else if (chip.type === "rangeFilter") {
      await this.searchFilterService.checkRangeFilter(chip.filterGroup, chip.filter, this.searchContainer);
    }
  }
}
