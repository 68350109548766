<div class="container">

  <mat-nav-list>

    <mat-tree [dataSource]="items" [treeControl]="menuTreeControl">

      <mat-tree-node *matTreeNodeDef="let menuItem" class="menu-item-node">
        @if (menuItem.disabled) {
          <a class="menu-item" disabled mat-list-item routerLinkActive="active">
            <div class="flex items-center">
              @if (menuItem.icon) {
                <mat-icon class="material-symbols-outlined mr-4">{{ menuItem.icon }}</mat-icon>
              }
              <div class="flex overflow-hidden gap-1">
                <span class="ellipse-text">
                  {{ menuItem.label() }}
                </span>
                @if (menuItem.count) {
                  <span class="menu-item-count">({{ menuItem.count }})</span>
                }
              </div>
            </div>
          </a>
        } @else {
          <a class="menu-item" mat-list-item [routerLink]="menuItem.link" routerLinkActive="active"
             [routerLinkActiveOptions]="{exact: true}" #activeLink="routerLinkActive" queryParamsHandling="merge">
            <div class="flex items-center">
              @if (menuItem.icon) {
                <mat-icon class="material-symbols-outlined mr-4">{{ menuItem.icon }}</mat-icon>
              }
              <div class="flex overflow-hidden gap-1">
                <span class="ellipse-text">
                  {{ menuItem.label() }}
                </span>
                @if (menuItem.count) {
                  <span class="menu-item-count">({{ menuItem.count }})</span>
                }
              </div>
            </div>
          </a>
        }
      </mat-tree-node>

      <mat-nested-tree-node *matTreeNodeDef="let menuItem; when: hasChild" class="menu-item-node">
        <button mat-list-item matTreeNodeToggle class="menu-item flex" [disabled]="menuItem.disabled">
          <div matListItemTitle class="flex items-center">
            @if (menuItem.icon) {
              <mat-icon class="material-symbols-outlined mr-4">{{ menuItem.icon }}</mat-icon>
            }
            <div class="flex overflow-hidden gap-1">
              <span class="ellipse-text">
                  {{ menuItem.label() }}
                </span>
              @if (menuItem.count) {
                <span class="menu-item-count">({{ menuItem.count }})</span>
              }
            </div>
            <mat-icon matSuffix class="material-symbols-outlined ml-auto">
              @if (menuTreeControl.isExpanded(menuItem)) {
                expand_less
              } @else {
                expand_more
              }
            </mat-icon>
          </div>
        </button>
        @if (menuTreeControl.isExpanded(menuItem)) {
          <div role="group" class="font-weight-normal">
            <ng-container matTreeNodeOutlet></ng-container>
          </div>
        }
      </mat-nested-tree-node>

    </mat-tree>

  </mat-nav-list>

</div>
