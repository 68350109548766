<div class="dams-item" (click)="toggleItemSelected($event)" [ngClass]="{'dams-item--selected' : item.selected}">
  <div class="dams-item__checkbox-container" [ngClass]="{'dams-item__checkbox-container--selected' : item.selected}">
    <mat-checkbox [(ngModel)]="item.selected" (click)="toggleItemSelected($event)">
    </mat-checkbox>
  </div>

  @if(item.$$hasImage) {
    <img
      [alt]=""
      class="dams-item__image"
      [src]="image?.medium || ''"
      (error)="brokenImage()"
    />
  }
  @else {
    <div class="dams-item__placeholder">
      <mat-icon class="dams-item__placeholder-icon">
        {{DamsService.getIconFromType(item.type)}}
      </mat-icon>
    </div>
  }


  <div class="dams-item__content">
    <mat-icon class="dams-item__icon">
      {{DamsService.getIconFromType(item.type)}}
    </mat-icon>

    <div class="dams-item__text">
      <span class="dams-item__title">{{item.title}}</span>
      <span class="dams-item__description">{{item.created_at}}</span>
      <span class="dams-item__description">{{item.created_by}}</span>
    </div>
  </div>
</div>

