import {Injectable, isDevMode, signal} from '@angular/core';

/** The time it takes to consider a user as "idle". */
const TIMEOUT = 1000 * 60 * 60;

/**
 * Detects user inactivity.
 */
@Injectable({
  providedIn: 'root'
})
export class IdleService {

  private _idle = signal(false);
  get idle() {
    return this._idle.asReadonly();
  }

  constructor() {
    this.userActivity();
    const events: (keyof DocumentEventMap)[] = [
      'keydown',
      'click',
      'scroll',
    ]
    events.forEach(event => {
      document.addEventListener(event, () => this.userActivity());
    });
  }

  private userActivityTimeout: NodeJS.Timeout | undefined;
  private userActivity() {
    this._idle.set(false);
    clearTimeout(this.userActivityTimeout);
    this.userActivityTimeout = setTimeout(() => {
      if (isDevMode()) {
        console.debug('[IdleService]: Setting idle');
      }
      this._idle.set(true);
    }, TIMEOUT);
  }

}
