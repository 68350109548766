<div class="suggestion-list suggestion-list--padded">
  <div class="suggestion-list__header">
    <span class="suggestion-list__header__label">
      {{ 'TRANS__FILTER_MENU__SEARCH_SUGGESTION' | translate }}
    </span>
  </div>

  <div class="suggestion-list suggestion-list__list">
    <mat-action-list class="suggestion-list__unpadded-list">
      @for (suggestion of searchSuggestions; track suggestion) {
        <button mat-list-item (click)="setAdvancedSearch(suggestion.advancedSearchParams)" [matTooltip]="suggestion.label" matTooltipPosition="after">
          <div class="suggestion-list__list__button">
            <mat-icon class="suggestion-list__list__button__icon">saved_search</mat-icon>

            <span class="suggestion-list__list__button__text">
              {{ suggestion.label }}
            </span>

            <mat-icon class="suggestion-list__list__button__icon">chevron_right</mat-icon>
          </div>
        </button>
      }
    </mat-action-list>
  </div>
</div>
