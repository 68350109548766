import { Component } from '@angular/core';
import {UploadService} from "../../../core/upload.service";
import {SuperObjectModel} from "../../../core/definitions/super-object-model";
import {MediaItem} from "../../../core/definitions/media-item";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-admin-service-upload-test',
  standalone: true,
  imports: [
    FormsModule
  ],
  templateUrl: './admin-service-upload-test.component.html',
  styleUrl: './admin-service-upload-test.component.scss'
})
export class AdminServiceUploadTestComponent {

  // Storing files in a File array
  files?: File[];
  isMultiple = false;

  // Injecting upload service
  constructor(private uploadService: UploadService) { }

  // Function to use the service to upload files
  uploadFiles(filesElement: HTMLInputElement) {

    // Check whether the files array is not undefined
    if (this.files) {
      const parentObject = new SuperObjectModel();
      this.uploadService.uploadFilesNew(this.files, 'image', parentObject)
        .then((mediaObjects: MediaItem[]) => {
          console.log(mediaObjects);

          // Resetting the input file tag
          filesElement.value = '';
        });
    } else {
      alert('Please select files to upload!');
    }
  }
  changeFiles(event: any) {

    // On file change set it to files array
    this.files = event.target.files;
  }
}
