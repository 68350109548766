import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SearchContainer} from "../../../../core/definitions/search-container";
import {Facet, RangeGroup} from "../../../../core/definitions/search-objects";
import {TranslateModule} from "@ngx-translate/core";
import {SearchFilterService} from "../../../search-filter.service";
import {SearchExecutorService} from "../../../search-executor.service";
import {CommonsService} from "../../../../core/commons.service";
import {MatFormField} from "@angular/material/form-field";
import {MatOption, MatSelect} from "@angular/material/select";
import {NgClass} from "@angular/common";
import {MatInput} from "@angular/material/input";
import {DateToolsService} from "../../../../core/date-tools.service";
import {FormsModule} from "@angular/forms";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-filter-range',
  standalone: true,
  imports: [
    TranslateModule,
    MatFormField,
    MatSelect,
    MatOption,
    NgClass,
    MatInput,
    FormsModule,
    MatIcon
  ],
  templateUrl: './filter-range.component.html',
  styleUrl: './filter-range.component.scss'
})
export class FilterRangeComponent implements OnInit, OnDestroy {
  @Input() filterRange: RangeGroup;
  @Input() searchContainer: SearchContainer;

  endLocalDate;
  startLocalDate;

  constructor(
    private commons: CommonsService,
    public dateTools: DateToolsService,
    private searchExecutorService: SearchExecutorService,
    private searchFilterService: SearchFilterService
  ) {}

  ngOnInit() {
    if (this.searchContainer) {
      this.searchExecutorService.subscribeToSearchResult(this.searchContainer, this.searchResultCallbackFacetRange);
    }
  }

  ngOnDestroy() {
    this.searchExecutorService.unSubscribeToSearchResult(this.searchContainer, this.searchResultCallbackFacetRange);
  }

  checkRange(event) {
    let range = null
    if (event !== '') {
      range = this.filterRange.facet_ranges[event];
    }
    else {
      let index = this.selectedOption;

      if (index !== -1) {
        this.searchFilterService.checkRangeFilter(this.filterRange, this.filterRange.facet_ranges[index], this.searchContainer).catch();
      }
      return;
    }

    if (range.total_count > 0) {
      this.searchFilterService.checkRangeFilter(this.filterRange, range, this.searchContainer).catch();
    }
    else {
      if (range.checked && range.field_type === 'solr_date') {
        range.start = range.$$origStart;
        range.end = range.$$origEnd;

        this.searchFilterService.checkRangeFilter(this.filterRange, range, this.searchContainer).catch();
        this.searchFilterService.setRangeFilter(this.filterRange, range, this.searchContainer).catch();
      }
    }
  }

  noRangeSelected(): boolean {
    for (const range of this.filterRange.facet_ranges) {
      if (range.hasOwnProperty('checked')) {
        if (range.checked) {
          return false;
        }
      }
    }

    return true;
  }

  onInputChange(event: any, propname: string, range: Facet): void {
    const dateRes = this.dateTools.localStringDateToSolrDate(event.target.value, propname);
    const valid = this.checkValidDate(dateRes, propname, range);

    if (dateRes.valid && valid) {
      range[propname] = dateRes.solrDate;
    }
  }

  setRange(event: KeyboardEvent, range: Facet) {
    if(event.key === 'Enter') {
      this.searchFilterService.setRangeFilter(this.filterRange, range, this.searchContainer).catch();
    }
  }

  get selectedOption(): number | string {
    let index = this.filterRange.facet_ranges.findIndex(o => o.checked);

    return index !== -1 ? index : '';
  }

  private checkValidDate(dateRes: any, propname: string, range: Facet): boolean {
    if (propname === 'start') {
      return dateRes.solrDate <= range.end;
    }

    if (propname === 'end') {
      return dateRes.solrDate >= range.start;
    }
  }

  localDate(range: Facet, prop: string): string {
    if (prop === 'start') {
      if (!this.startLocalDate) {
        this.startLocalDate = this.dateTools.solrDateToLocalStringDate(range[prop]);
      }

      return this.startLocalDate;
    }

    if (prop === 'end') {
      if (!this.endLocalDate) {
        this.endLocalDate = this.dateTools.solrDateToLocalStringDate(range[prop]);
      }

      return this.endLocalDate
    }
  }

  private searchResultCallbackFacetRange = () => {
    this.searchFilterService.setCheckedRangeFacets(this.searchContainer);
    this.searchFilterService.setOldRangeFilters(this.searchContainer);
    this.setGroupRangesOrdered();
  }

  private setGroupRangesOrdered(): void {
    for (const group of this.searchContainer.filtersFacets.facetRangeGroups) {
      group.facet_ranges = this.commons.sortArray(group.facet_ranges, 'order');
    }
  }

  protected readonly getSelection = getSelection;
}
