import {AfterViewInit, Component, Input, Signal, ViewChild} from '@angular/core';
import {MatSidenav, MatSidenavContainer, MatSidenavModule} from '@angular/material/sidenav';
import {MatButton, MatFabButton, MatIconButton, MatMiniFabButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {toSignal} from '@angular/core/rxjs-interop';
import {map} from 'rxjs/operators';
import {CurrentObjectContext} from '../current-object.context';
import {ResizableDirective} from '../../resizable.directive';

@Component({
  selector: 'app-object-page-layout',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatFabButton,
    MatButton,
    MatIcon,
    TranslateModule,
    MatIconButton,
    ResizableDirective,
    MatMiniFabButton,
  ],
  templateUrl: './object-page-layout.component.html',
  styleUrl: './object-page-layout.component.scss'
})
export class ObjectPageLayoutComponent implements AfterViewInit {

  @Input() disableNavigation: boolean = false;
  @Input() defaultImageDrawerWidth?: string;

  @ViewChild('rightDrawer') rightDrawer: MatSidenav;
  @ViewChild('navigationDrawer') navigationDrawer: MatSidenav;
  @ViewChild('matSidenavContainer') matSidenavContainer: MatSidenavContainer;

  isSmallScreen: Signal<boolean>
  constructor(
    private breakpointObserver: BreakpointObserver,
    private currentObjectContext: CurrentObjectContext,
  ) {
    this.isSmallScreen = toSignal(this.breakpointObserver.observe('(max-width: 1023px)').pipe(map(result => result.matches)));
  }

  ngAfterViewInit(): void {
    this.matSidenavContainer?.updateContentMargins();
    this.rightDrawer?.toggle(!this.isSmallScreen(), 'program');
  }

  get isEditing() {
    return this.currentObjectContext.isEditing;
  }


  openRightDrawer() {
    this.rightDrawer?.open('program')
  }

  toggleNavigationDrawer() {
    this.navigationDrawer?.toggle(undefined, 'program')
  }

  setRightDrawerWidth(width: string) {
    // @ts-ignore
    this.rightDrawer?._elementRef?.nativeElement?.style.setProperty('width', width);
    this.matSidenavContainer?.updateContentMargins();
  }

}
