@if (nameItems && nameItems.length > 0) {
    @for (nameItem of nameItems; track producerIndex; let producerIndex = $index) {
      <div class="import-name">
        <div class="import-name__content">
          <div class="import-name__name import-name__name--grow import-name__name--bold">
            {{nameItem.dams_title}}
            <span class="import-name__name">
              {{ 'TRANS__DAMS_DIALOG__IS_DAMS_PRODUCER' | translate }}
            </span>
          </div>

          <div class="import-name__buttons">
            @if (nameItem.kulturnav_id) {
              <span class="import-name__name">
                {{ 'TRANS__DAMS_DIALOG__IS_KNAV_AUTHORITY' | translate }}
              </span>
            }
            @else if (nameItem.dams_id_exists) {
              <span class="import-name__name">
                {{ 'TRANS__DAMS_DIALOG__ALREADY_IMPORTED' | translate }}
              </span>
            }
            @else {
              @if (nameItem.suggestions.length === 0) {
                <span class="import-name__name">
                  {{ 'TRANS__DAMS_DIALOG__NO_NAMES_FOUND' | translate }}
                </span>
              }
              @else {
                <span class="import-name__name">
                  {{ 'TRANS__DAMS_DIALOG__SELECT_NAME' | translate }}
                </span>
              }
              <mat-radio-group class="import-name__radio-group" (change)="selectionChanged($event, nameItem)">
                @for (suggestion of nameItem.suggestions; track $index) {
                  <mat-radio-button [value]="suggestion">
                    <span class="import-name__name">
                      {{suggestion.name}}
                    </span>
                  </mat-radio-button>
                }
                <mat-radio-button value="create">
                  <span class="import-name__name">
                    {{ 'TRANS__DAMS_DIALOG__CREATE_NAME' | translate }} {{nameItem.dams_title}}
                  </span>
                </mat-radio-button>
              </mat-radio-group>
            }
          </div>
        </div>
      </div>
    }
}
