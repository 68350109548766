import {AfterViewInit, Component, ElementRef, OnDestroy, signal, ViewChild} from '@angular/core';
import {CurrentObjectContext} from '../current-object.context';
import {OverviewFieldsModule} from '../../overview-fields/overview-fields.module';
import {ObjectViewModule} from '../../object-view/object-view.module';
import {M3GlobalKplStylesComponent} from '../../m3-global-kpl-styles/m3-global-kpl-styles.component';
import {OperationsModule} from '../../operations/operations.module';
import {MatCard} from '@angular/material/card';
import {ObjectOverviewFieldsV2Component} from '../ui/object-overview-fields-v2/object-overview-fields-v2.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {JsonPipe, NgClass} from '@angular/common';
import {Section} from '../../core/definitions/sections-container';
import {MatButton} from '@angular/material/button';
import {MatListItem, MatNavList} from '@angular/material/list';

@Component({
  selector: 'app-object-page-v2-overview',
  standalone: true,
  imports: [OverviewFieldsModule, ObjectViewModule, M3GlobalKplStylesComponent, OperationsModule, MatCard, ObjectOverviewFieldsV2Component, TranslateModule, MatProgressSpinner, JsonPipe, MatButton, MatNavList, MatListItem, NgClass],
  templateUrl: './object-page-v2-overview.component.html',
  styleUrl: './object-page-v2-overview.component.scss'
})
export class ObjectPageV2OverviewComponent implements AfterViewInit, OnDestroy {

  constructor(
    private currentObjectContext: CurrentObjectContext,
    private elementRef: ElementRef
  ) { }

  private _hiddenSectionNavigator = signal(true);
  get hiddenSectionNavigator() {
    return this._hiddenSectionNavigator.asReadonly();
  }

  @ViewChild('scrollOffsetAnchor') scrollOffsetAnchor: ElementRef;

  ngAfterViewInit() {
    const scrollContainer = this.elementRef.nativeElement.closest('mat-sidenav-content');
    const observer = new IntersectionObserver(([entry]) => {
      this._hiddenSectionNavigator.set(entry.isIntersecting);
    }, {
      root: scrollContainer,
      rootMargin: '250px 0px 0px 0px',
    });

    observer.observe(this.scrollOffsetAnchor.nativeElement);

    this.cleanup = () => observer.disconnect();
  }

  private cleanup = () => {};
  ngOnDestroy() {
    this.cleanup();
  }

  get sectionsContainer() {
    return this.currentObjectContext.sectionsContainer
  }

  get isEditing() {
    return this.currentObjectContext.isEditing
  }

  get contentInfo() {
    return this.currentObjectContext.contentInfo
  }

  get operationContainer() {
    return this.currentObjectContext.operationContainer;
  }

  get operationStep() {
    return this.currentObjectContext.operationContainer?.currentOperation?.$$currentStep;
  }

  get loading() {
    return this.currentObjectContext.loadingArtifact;
  }

  scrollToSection(section: Section) {
    const el = document.getElementById(`section-${section.name}`);
    const scrollContainer = el?.closest('mat-sidenav-content');

    if (el && scrollContainer) {
      const behavior = window.matchMedia('(prefers-reduced-motion: reduce)').matches ? 'auto' : 'smooth';
      scrollContainer.scrollTo({ top: el.offsetTop - 50, behavior });
    }
  }
}
