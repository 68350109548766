<div class="filter-group">
  @if(filterGroup) {
    @for(filter of filterGroup.visibleFilters; track filter) {
      <div class="filter-group__item">
        <mat-checkbox
          class="filter-group__item__checkbox"
          (click)="checkFilter(filter)"
          [(ngModel)]="filter.checked"
        >
          @if (filter.title !== '' && filter.title !== null && filter.title !== undefined) {
            <span class="filter-group__item__label--body-medium-grow">
              {{filter.title | translate}}
            </span>
          }
          @else {
            <span class="filter-group__item__label--body-medium-grow">
              {{filter.noTransTitle}}
            </span>
          }

          @if (filter.count) {
            <span class="filter-group__item__label--label-small filter-group__item__label--subtle">
              {{filter.count}}
            </span>
          }
        </mat-checkbox>
      </div>
    }
  }
  @if (subCategories) {
    @for(subCategory of subCategories(); track $index) {
      <div class="filter-group__item">
        <mat-checkbox
          class="filter-group__item__checkbox"
          (click)="checkSubCategory(subCategory)"
        >
          <span class="filter-group__item__label--body-medium-grow">
            {{ subCategory.label() | translate}}
          </span>

          @if (subCategory.count) {
            <span class="filter-group__item__label--label-small filter-group__item__label--subtle">
              {{subCategory.count}}
            </span>
          }
        </mat-checkbox>
      </div>
    }
  }
</div>
