import { Component, Input } from '@angular/core';
import {SearchContainer} from "../../../core/definitions/search-container";
import {AdvancedSearchParams, SearchSuggestion} from "../../../core/definitions/advanced-search-params";
import {AdvancedSearchToolsService} from "../../../core/advanced-search-tools.service";
import {ConfirmDialogComponent, ConfirmDialogData} from "../../../object-edit/confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {MatActionList, MatList, MatListItem} from "@angular/material/list";
import {MatIcon} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-filter-menu-search-suggestion-list',
  standalone: true,
  imports: [
    MatList,
    MatListItem,
    MatActionList,
    MatIcon,
    TranslateModule,
    MatTooltip
  ],
  templateUrl: './filter-menu-search-suggestion-list.component.html',
  styleUrl: './filter-menu-search-suggestion-list.component.scss'
})
export class FilterMenuSearchSuggestionListComponent {
  @Input() searchContainer: SearchContainer;

  constructor(
    private advancedSearchTools: AdvancedSearchToolsService,
    private modal: MatDialog,
  ) {}

  setAdvancedSearch(suggestion: AdvancedSearchParams) {
    if (!this.advancedSearchTools.hasNoRulesSet(this.searchContainer)) {
      const modalRef = this.modal.open(ConfirmDialogComponent, {
        panelClass: 'confirm-dialog',
        data: {
          modalCancel: 'TRANS__ADVANCED_SEARCH__MODAL__CANCEL',
          modalConfirm: 'TRANS__ADVANCED_SEARCH__MODAL__CONFIRM',
          modalContent: 'TRANS__ADVANCED_SEARCH__MODAL__CONTENT',
          modalTitle: 'TRANS__ADVANCED_SEARCH__MODAL__TITLE',
        } as ConfirmDialogData
      });

      modalRef.afterClosed().subscribe((result) => {
        if (result) {
          this.searchContainer.advancedSearchParams = {...suggestion};
        }
      });
    }
    else {
      this.searchContainer.advancedSearchParams = {...suggestion};
    }
  }

  protected readonly searchSuggestions: SearchSuggestion[] = AdvancedSearchToolsService.searchSuggestions;
}
