<mat-list class="search-menu-sort common-search-menu {{viewName}}">

  <button *ngFor="let s of sortOrderMenus; index as $index"
          mat-menu-item
          [ngClass]="{'active':searchContainer.getOrder() === s[AConst.ORDER]}">
    <mat-radio-button
      [checked]="searchContainer.getOrder() === s[AConst.ORDER]"
      [value]="s[AConst.ORDER]"
      (click)="changeSortOrder(s)">
      <span class="common-search-menu__radio-text" [translate]="s.description"></span>
    </mat-radio-button>
  </button>

</mat-list>
