import {Component, HostListener, inject, Input} from '@angular/core';
import {TipsArticle} from '../helpdesk-api.service';
import {DIALOG_DATA} from '@angular/cdk/dialog';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {animate, style, transition, trigger} from '@angular/animations';

const animationTime = 200;
@Component({
  selector: 'app-fresh-article-preview',
  standalone: true,
  imports: [],
  templateUrl: './fresh-article-preview.component.html',
  styleUrl: './fresh-article-preview.component.scss',
  animations: [
    trigger('fadeInOut', [
      transition('void => *', [style({ opacity: '0' }), animate(animationTime)]),
      transition('* => void', [animate(animationTime, style({ opacity: '0' }))]),
      transition('* => *', [
        style({ opacity: '0' }),
        animate(animationTime, style({ opacity: '1' })),
      ]),
    ])
  ]
})
export class FreshArticlePreviewComponent {

  @Input() article: TipsArticle;

  private dialog = inject(MatDialog);

  @HostListener('click', ['$event'])
  handleImageClick($event: MouseEvent) {
    if ($event.target instanceof HTMLImageElement) {
      $event.stopPropagation();
      this.dialog.open(TipsAndTricksImageViewerComponent, {
        panelClass: 'hibernate-wakeup-overlay-tooltip',
        width: 'auto',
        height: 'auto',
        data: {
          imageUrl: $event.target.src,
        }
      })
    }
  }
}


@Component({
  selector: 'app-tips-and-tricks-image-viewer',
  standalone: true,
  template: `
    <div #imageViewer class="image-viewer">
      <img #image [src]="modalData.imageUrl">
    </div>
  `,
})
export class TipsAndTricksImageViewerComponent {

  modalData = inject(DIALOG_DATA);
  modalRef = inject(MatDialogRef);

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.modalRef.close();
    }
  }

  @HostListener('document:click', ['$event'])
  handleClick() {
    this.modalRef.close();
  }
}
