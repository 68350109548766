import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {ConceptListItem} from '../../../core/definitions/concepts';
import {ConceptField} from '../../admin-concept-lists/concept-field';
import {ConceptsContainer} from '../../../core/definitions/concepts-container';
import {ConceptService} from '../../../core/concept.service';
import {ConceptDialogService, OpenConceptParams} from '../../admin-concept-lists/concept-dialog.service';
import {DateToolsService} from '../../../core/date-tools.service';
import {FeatureFlagsService} from '../../../core/feature-flags.service';

@Component({
  selector: 'app-admin-list-item-special-value',
  templateUrl: './admin-list-item-special-value.component.html',
  styleUrls: ['./admin-list-item-special-value.component.scss']
})
export class AdminListItemSpecialValueComponent implements OnChanges {

  @Input() value: any;
  @Input() concept: ConceptListItem;
  @Input() conceptField: ConceptField;
  @Input() conceptsContainer: ConceptsContainer;
  @Input() index;
  @Output() conceptOpened = new EventEmitter<any>();
  @Output() updateConceptList = new EventEmitter<void>();

  constructor(private conceptService: ConceptService,
              private readonly dateTools: DateToolsService,
              private featureFlags: FeatureFlagsService,
              private conceptDialogService: ConceptDialogService) {
  }

  get useNewObjectPage() {
    return this.featureFlags.getFeatureFlags()?.experimental?.useNewObjectPage;
  }

  ngOnChanges(): void {
    if (this.conceptField.type === 'datetime') {
      this.value = this.dateTools.isoDateToString(<string>this.value, 'datetime');
    }
  }

  get parentId() {
    const parentConcept = this.conceptService.getParentConcept(this.conceptsContainer, 1);
    return parentConcept ? parentConcept.artifact_id : undefined;
  }

  openConcept(conceptId, goBack: boolean) {
    this.conceptService.clearSelected(this.conceptsContainer);
    if (!goBack) {
      this.conceptsContainer.parentConcepts.push(this.concept);
    } else {
      this.conceptsContainer.parentConcepts.pop();
    }
    this.conceptOpened.emit({conceptId: conceptId, goBack: goBack, pageIndex: !goBack ? this.conceptsContainer.pageIndex : null});
  }

  openParent() {
    if (!this.parentId || this.concept.parent_id === this.parentId) {
      this.openConcept(this.parentId, true);
    } else {
      let goOnMate = true;
      do {
        const parentConcept = this.conceptsContainer.parentConcepts[this.conceptsContainer.parentConcepts.length - 1];
        if (parentConcept.artifact_id !== this.concept.artifact_id) {
          this.conceptsContainer.parentConcepts.pop();
        } else {
          goOnMate = false;
        }
      } while (goOnMate);
      this.openConcept(undefined, true);
    }
  }

  select(event) {
    this.conceptService.selectConcept(this.concept, this.conceptsContainer, event.shiftKey);
  }

  async editTerm() {
    const params: OpenConceptParams = {
      conceptsContainer: this.conceptsContainer,
      concept: null,
      concept_id: this.concept.artifact_id,
      index: this.index,
      callback: (stored: boolean) => {
        if (stored) {
          this.updateConceptList.emit();
        }
      }
    };
    this.conceptDialogService.openConcept(params);
  }

  async addChildConcept() {
    const childConcept = await this.conceptService.createChildConcept(this.conceptsContainer, this.concept);
    this.conceptDialogService.openConcept({
      conceptsContainer: this.conceptsContainer,
      concept: childConcept,
      concept_id: null,
      index: -1,
      callback: (stored: boolean) => {
        if (stored) {
          this.openConcept(this.concept.artifact_id, false);
        }
      }
    });
    this.concept.open = true;
  }

}
