<app-widget-container>
  <div class="container">
    <div class="article-container" #articleContainer>

      @if (loading()) {
        <mat-spinner diameter="30"></mat-spinner>
      } @else if(currentTip()) {
        <app-fresh-article-preview
          [article]="currentTip()"
          ></app-fresh-article-preview>
      }
    </div>
    <div class="footer">
      <div class="buttons">
        <button [disabled]="articleLength() < 2" (click)="onPreviousClick()" mat-stroked-button>
          {{ 'TRANS__HIBERNATE_WAKEUP_OVERLAY__PREVIOUS_TIP_LABEL' | translate }}
        </button>
        <button [disabled]="articleLength() < 2" (click)="onNextClick()" mat-stroked-button>
          {{ 'TRANS__HIBERNATE_WAKEUP_OVERLAY__NEXT_TIP_LABEL' | translate }}
        </button>
      </div>

      <div class="current-tip">
        @if (articleLength()) {
          <span class="tips-heading">{{ 'TRANS__HIBERNATION__TIPS_HEADING_WITH_COUNT' | translate:{ index: currentIndex() + 1, totalCount: articleLength() } }}</span>
        } @else {
          <span class="tips-heading">{{ 'TRANS__HIBERNATION__TIPS_HEADING' | translate }}</span>
        }
      </div>
    </div>
  </div>
</app-widget-container>
