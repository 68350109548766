@for (mediaElement of mediaType.mediaElements; track mediaElement; let i = $index) {
  <div class="thumbnail-stack"
       (click)="setMedia(i)"
       [attr.data-index]="i">
    <div class="thumbnail-stack__img-container"
         [ngClass]="{'selected border-primary': i === mediaType.currentMediaIndex &&
       mediaType.mediaType === mediaContainer.currentMediaType.mediaType}">
      @if (mediaElement.mediaObject.object_type.toLowerCase() === 'model3d') {
        <i class="icon-object placeholder-icon"></i>
      } @else {
        @if (!invalidSrc[i]) {
          <img id='thumb-{{i}}'
               [alt]="mediaElement.mediaObject.artifact_name"
               [src]="mediaElement.mediaObject.$$thumbUrl"
               (error)="setInvalidSrc(i)">
        } @else {
          <i class="icon-image-attention"></i>
        }
      }
      @if (mediaElement.mediaObject.object_type.toLowerCase() !== 'model3d') {
        <div class="thumbnail-stack__media-type">
          <i class="icon-{{mediaElement.mediaObject.object_type.toLowerCase()}}"></i>
        </div>
      }
    </div>
  </div>
}
