// object types copied from backend project.
const objectTypeToMaterialSymbolName = {
  '': 'content_paste_search',
  AdmEventContext: 'manage_history',
  agent: 'person',
  AnalysisEvent: 'deployed_code',
  Annotation: 'format_shapes',
  architecture: 'home_work',
  artwork: 'wallpaper',
  Attachment: 'description',
  AttachmentItem: 'description',
  Audio: 'library_music',
  AudioItem: 'library_music',
  building: 'house',
  BuildingConditionAssessmentEvent: 'monitor_heart',
  BuildingDeviation: 'apps_outage',
  BuildingTreatmentEvent: 'task_alt',
  concept: 'account_tree',
  ConceptChild: 'account_tree',
  ConceptGrandChild: 'account_tree',
  ConceptParent: 'account_tree',
  ConditionAssessmentEvent: 'monitor_heart',
  ConstructionUseRestrictions: 'front_hand',
  Damage: 'destruction',
  design: 'watch',
  Doc: 'description',
  Docx: 'description',
  EnvironmentalConditionEvent: 'globe',
  folder: 'folder_open',
  GenericEvent: 'manage_history',
  HistoryEvent: 'history',
  Image: 'photo_library',
  ImageItem: 'photo_library',
  InsuranceEvent: 'local_police',
  Location: 'pin_drop',
  Model3D: 'deployed_code',
  Model3DItem: 'deployed_code',
  named_object: 'deployed_code',
  NavalVessel: 'directions_boat',
  ObjectChildItem: 'family_history',
  ObjectParentItem: 'account_tree',
  ObjectRelationshipItem: 'route',
  ObjectValuationEvent: 'paid',
  package: 'inventory_2',
  Pdf: 'picture_as_pdf',
  photograph: 'image',
  photograph_copy: 'photo_prints',
  place: 'location_pin',
  PlacementEvent: 'shelves',
  plant: 'potted_plant',
  PlantCareEvent: 'volunteer_activism',
  PlantConditionAssessmentEvent: 'monitor_heart',
  PlantFloweringTimeEvent: 'local_florist',
  PlantMaturationTimeEvent: 'update',
  Ppt: 'co_present',
  Pptx: 'co_present',
  PublishingEvent: 'published_with_changes',
  PurchaseEvent: 'payments',
  RecommendationEvent: 'star',
  recording: 'voicemail',
  recurring_task: 'checklist',
  RecurringTaskContextItem: 'published_with_changes',
  ReviewResultEvent: 'list_alt',
  Rights: 'copyright',
  SalvagePriorityEvent: 'format_list_numbered',
  Settings: 'settings',
  SpectrumProcedureAcquisition: 'conversion_path',
  SpectrumProcedureAudit: 'conversion_path',
  SpectrumProcedureCollectionReview: 'conversion_path',
  SpectrumProcedureCommonProceduralUnits: 'conversion_path',
  SpectrumProcedureConditionsCheck: 'conversion_path',
  SpectrumProcedureConservation: 'conversion_path',
  SpectrumProcedureDamage: 'conversion_path',
  SpectrumProcedureDisposal: 'conversion_path',
  SpectrumProcedureEntry: 'conversion_path',
  SpectrumProcedureExhibition: 'conversion_path',
  SpectrumProcedureExit: 'conversion_path',
  SpectrumProcedureFieldCollection: 'conversion_path',
  SpectrumProcedureIndemnity: 'conversion_path',
  SpectrumProcedureInsurance: 'conversion_path',
  SpectrumProcedureLoanIn: 'conversion_path',
  SpectrumProcedureLoanOut: 'conversion_path',
  SpectrumProcedureMovement: 'conversion_path',
  SpectrumProcedureUse: 'conversion_path',
  SpectrumProcedureValuation: 'conversion_path',
  task: 'task',
  TaskContextItem: 'task',
  template_model_architecture: 'home_work',
  template_model_artwork: 'wallpaper',
  template_model_building: 'house',
  template_model_design: 'watch',
  template_model_NavalVessel: 'directions_boat',
  template_model_photograph: 'image',
  template_model_photograph_copy: 'photo_prints',
  template_model_thing: 'deployed_code',
  TemplateGroup: 'border_clear',
  TextBlock: 'description',
  thing: 'deployed_code',
  TreatmentEvent: 'imagesearch_roller',
  UseMeasurementEvent: 'pie_chart',
  user: 'person',
  UserContributionEvent: 'record_voice_over',
  Video: 'smart_display',
  VideoItem: 'smart_display',
  Xls: 'grid_on',
  Xlsx: 'grid_on',
};

export function objectTypeToMatSymbol(objectType: string): string | undefined {
  return objectTypeToMaterialSymbolName[objectType];
}

const symbolMigrationLookup = {
  "icon-archaeology-context": "category",
  "icon-archaeology-finds": "beach_access",
  "icon-archaeology-samples": "colorize",
  "icon-artwork": "wallpaper",
  "icon-building": "house",
  "icon-design": "watch",
  "icon-plant": "potted_plant",
  "icon-image": "image",
  "icon-object": "deployed_code",
  "icon-office": "home_work",
  "icon-photo-ex": "photo_prints",
  "icon-record": "voicemail",
  "icon-accession": "add_box",
  "icon-accession-small": "add_box",
  "icon-accession2": "library_add",
  "icon-accession3": "library_add",
  "icon-acquisition": "handshake",
  "icon-archaeology-investigations": "brush",
  "icon-adm-event": "manage_history",
  "icon-analyze": "deployed_code",
  "icon-annotate": "format_shapes",
  "icon-care": "volunteer_activism",
  "icon-comment": "chat",
  "icon-completed-task": "task_alt",
  "icon-condition-assessment": "monitor_heart",
  "icon-flowering-time": "local_florist",
  "icon-damage": "broken_image",
  "icon-damage2": "destruction",
  "icon-deviation": "apps_outage",
  "icon-entry-procedure": "login",
  "icon-exit-procedure": "logout",
  "icon-exhibition": "wall_art",
  "icon-heartQuestion": "digital_wellbeing",
  "icon-insurance": "local_police",
  "icon-loan": "real_estate_agent",
  "icon-package": "inventory_2",
  "icon-procedure": "checklist",
  "icon-procedure2": "conversion_path",
  "icon-publishing": "published_with_changes",
  "icon-purchase": "payments",
  "icon-ripening-time": "update",
  "icon-salvage_priority": "format_list_numbered",
  "icon-step-in-to": "login",
  "icon-task": "task",
  "icon-treatment": "imagesearch_roller",
  "icon-user": "person",
  "icon-use-measurement": "pie_chart",
  "icon-history": "history",
  "icon-audit": "content_paste_search",
  "icon-use-restriction": "front_hand",
  "icon-environmental-condition": "globe",
  "icon-review-result": "list_alt",
  "icon-object-valuation": "paid",
  "icon-user-contribution": "record_voice_over",
  "icon-naval-vessel": "directions_boat",
  "icon-audio": "library_music",
  "icon-audio2": "volume_up",
  "icon-attachment": "attachment",
  "icon-attachment2": "attach_file",
  "icon-file": "description",
  "icon-file-excel": "grid_on",
  "icon-file-list": "description",
  "icon-file-pdf": "picture_as_pdf",
  "icon-file-powerpoint": "co_present",
  "icon-file-word": "description",
  "icon-images": "photo_library",
  "icon-active-relation": "route",
  "icon-archive-close": "cancel",
  "icon-archive-open": "check_circle",
  "icon-arrow-down2": "arrow_downward_alt",
  "icon-arrow-up2": "arrow_upward_alt",
  "icon-bell": "notifications",
  "icon-box": "inbox",
  "icon-calendar": "calendar_month",
  "icon-camera": "photo_camera",
  "icon-child-of": "family_history",
  "icon-circle": "circle",
  "icon-close": "close",
  "icon-copy": "content_copy",
  "icon-copyright": "copyright",
  "icon-create-new": "library_add",
  "icon-download": "download",
  "icon-edit-alternative": "edit",
  "icon-expand": "open_in_full",
  "icon-filter": "filter_alt",
  "icon-filter2": "filter_list",
  "icon-folder-open": "folder_open",
  "icon-hamburger": "menu",
  "icon-help": "help",
  "icon-home": "home",
  "icon-hour-glass": "hourglass",
  "icon-information": "info",
  "icon-image-attention": "warning",
  "icon-link": "link",
  "icon-light-bulb": "lightbulb",
  "icon-list-view": "list",
  "icon-place": "location_pin",
  "icon-location": "pin_drop",
  "icon-placement": "shelves",
  "icon-placement2": "shelves",
  "icon-playlist-edit": "playlist_add",
  "icon-playlist-remove": "playlist_remove",
  "icon-minus": "remove",
  "icon-minus-full-circle": "remove_circle",
  "icon-more": "more_horiz",
  "icon-mother-to": "account_tree",
  "icon-overview": "visibility",
  "icon-passive-relation": "route",
  "icon-paste": "content_paste",
  "icon-pin": "keep",
  "icon-play": "play_circle",
  "icon-play2": "play_arrow",
  "icon-plus-circle": "add_circle",
  "icon-plus": "add",
  "icon-plus-big": "add",
  "icon-print": "print",
  "icon-report": "summarize",
  "icon-row-sort": "sort",
  "icon-search": "search",
  "icon-select": "check",
  "icon-select1": "arrow_selector_tool",
  "icon-settings": "settings",
  "icon-spinner": "cached",
  "icon-sort": "sort",
  "icon-sort-asc": "swap_vert",
  "icon-sort-desc": "swap_vert",
  "icon-square": "square",
  "icon-star": "star",
  "icon-tag": "sell",
  "icon-template": "border_clear",
  "icon-trash": "delete",
  "icon-unlink": "link_off",
  "icon-upload": "upload",
  "icon-users": "group",
  "icon-video": "smart_display",
  "icon-warning": "warning",
};

export function iconToMaterialSymbol(icon: string): string | undefined {
  return symbolMigrationLookup[icon];
}
