import {Component, OnInit} from '@angular/core';
import {CmsApiService} from "../../../core/cms-api.service";
import {FormControl} from "@angular/forms";
import {LoggerService} from '../../../core/logger.service';

interface ApiService {
  path: string;
  method: string;
  content_type: string;
  title: string;
  parameters: ApiParameter[];
  skip_api_path: boolean;
  stream: boolean;
}

interface ApiParameter {
  name: string;
  param_type: string;
  title: string;
  formControl: FormControl;
}

@Component({
  selector: 'app-admin-service-api',
  templateUrl: './admin-service-api.component.html',
  styleUrls: ['./admin-service-api.component.scss']
})
export class AdminServiceApiComponent implements OnInit {
  serviceRegister = {
    end_points: []
  };
  selectedServicePath;
  selectedService: ApiService;
  executeResult;
  errorMessage = '';

  constructor(
    private logger: LoggerService,
    private cms: CmsApiService) {
  }

  ngOnInit(): void {
    this.cms.getServiceRegister().then(res => {
      this.serviceRegister = res;
    });
  }

  setService() {
    this.selectedService = this.serviceRegister.end_points.find(endPoint => endPoint.path === this.selectedServicePath);
    for (const parameter of this.selectedService.parameters || []) {
      if (!parameter.formControl) {
        parameter.formControl = new FormControl<any>('');
      }
    }
  }

  async executeService() {
    let path = this.selectedService.path;
    let outParams = {};
    let addPrefix = '/';
    this.errorMessage = '';
    for (const parameter of this.selectedService.parameters || []) {
      const value = parameter.formControl.value;
      if (!value) {
        continue
      }
      switch (parameter.param_type) {
        case 'add_to_path':
          path += addPrefix + value;
          addPrefix = ','
          break;
        case 'string':
        case 'boolean':
          outParams[parameter.name] = value;
          break;
        case 'number':
          outParams[parameter.name] = Number(value);
          break
        case 'list':
          outParams[parameter.name] = value.split(',');
          break;
        case 'object':
          try {
            outParams[parameter.name] = JSON.parse(value)
          } catch (e) {
            this.errorMessage = e.message;
            return;
          }
          break;
        default:
          this.logger.warn(`Unknown type ${parameter.param_type}`)
      }
    }
    try {
      if (!this.selectedService.stream) {
        this.executeResult = await this.cms.executeService(
          path, outParams, this.selectedService.method, this.selectedService.content_type,
          this.selectedService.skip_api_path, false);
      } else {
        this.executeResult = '';
        this.cms.executeService(path, outParams, this.selectedService.method, this.selectedService.content_type,
          this.selectedService.skip_api_path, true).then(reader => {
          const readChunk = () => {
            reader.read().then(({done, value}) => {
              if (done) return;
              const chunk = value.toString();
              this.executeResult += chunk;
              readChunk();
            });
          };
          readChunk();
        });
      }
    } catch (e) {
      this.logger.error(`Exception occurred executing ${path}: ` + e.message);
      this.errorMessage = e.message;
    }
  }

}
