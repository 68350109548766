import {Component, Input} from '@angular/core';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {MatProgressSpinner} from '@angular/material/progress-spinner';

@Component({
  selector: 'app-object-page-heading',
  standalone: true,
  imports: [
    MatIcon,
    MatIconButton,
    MatMenu,
    MatProgressSpinner,
    MatMenuTrigger
  ],
  templateUrl: './object-page-heading.component.html',
  styleUrl: './object-page-heading.component.scss'
})
export class ObjectPageHeadingComponent {
  @Input() title: string;
  @Input() image?: string;
  @Input() loading: boolean;
  @Input() placeholderIcon?: string;
  @Input() hideInfoPopover: boolean = false;
}
