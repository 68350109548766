@if (searchContext) {
  <div class="pagination">
    @if (backClick.observed) {
      <button class="navigate" mat-icon-button (click)="onBackClick()" [disabled]="!canNavigateBack">
        <mat-icon class="material-symbols-outlined">chevron_left</mat-icon>
      </button>
    }
    <div class="page-info">
      @if (searchContext.findResultIndex(artifactId) >= 0) {
        {{ 'TRANS__OBJECT_PAGE_V2__HEADER__SEARCH_CONTEXT_RESULTS' | translate: { x: searchContext.findResultIndex(artifactId) + 1, y: searchContext.resultSize } }}
      } @else {
        {{ 'TRANS__OBJECT_PAGE_V2__HEADER__SEARCH_CONTEXT_RESULTS__HITS_COUNT' | translate: { y: searchContext.resultSize } }}
      }
    </div>
    @if (forwardClick.observed) {
      <button class="navigate" mat-icon-button (click)="onForwardClick()" [disabled]="!canNavigateForward">
        <mat-icon class="material-symbols-outlined">chevron_right</mat-icon>
      </button>
    }
    <button mat-button (click)="onCloseClick()">
      {{ 'TRANS__OBJECT_PAGE_V2__HEADER__CLOSE' | translate }}
      <mat-icon matButtonIcon iconPositionEnd class="material-symbols-outlined">close</mat-icon>
    </button>
  </div>
} @else {
  <div class="flex w-full justify-end">
    <button mat-button (click)="onCloseClick()">
      {{ 'TRANS__OBJECT_PAGE_V2__HEADER__CLOSE' | translate }}
      <mat-icon matButtonIcon iconPositionEnd class="material-symbols-outlined">close</mat-icon>
    </button>
  </div>
}
