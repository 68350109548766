import { Injectable } from '@angular/core';
import {CmsApiService} from "../core/cms-api.service";
import {SearchViewMenu} from "../core/definitions/search-objects";

@Injectable({
  providedIn: 'root'
})
export class SearchViewCategoryMenuService {

  private categoryMenus: {[name: string]: SearchViewMenu[]} = null;

  constructor(private cms: CmsApiService) {
  }

  async getCategoryMenus(name: string): Promise<SearchViewMenu[]> {
    if (!this.categoryMenus) {
      this.categoryMenus = await this.cms.getSearchViewCategoryMenus();
    }
    return this.categoryMenus[name] || [];
  }
}
