import {Component, effect, EventEmitter, Input, Output, Signal, WritableSignal} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {MatRipple} from "@angular/material/core";
import {MatMenu, MatMenuTrigger} from "@angular/material/menu";
import {SearchContainer} from "../../../core/definitions/search-container";
import {TranslateModule} from "@ngx-translate/core";
import {SearchHandlerService} from "../../search-handler.service";
import {NgClass} from "@angular/common";
import { FilterMenuCategorySelectorDropdownComponent } from './filter-menu-category-selector-dropdown/filter-menu-category-selector-dropdown.component';
import {SearchViewMenu} from "../../../core/definitions/search-objects";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

export interface CategoryMenuItem {
  children?: CategoryMenuItem[];
  count?: number;
  disabled?: boolean;
  icon?: string;
  label: Signal<string>;
  path: string;
  type: 'child' | 'header';
  value?: string;
  menu?: SearchViewMenu;
}

@Component({
  selector: 'app-filter-menu-category-selector',
  standalone: true,
  imports: [
    MatIcon,
    MatRipple,
    MatMenu,
    MatMenuTrigger,
    TranslateModule,
    NgClass,
    FilterMenuCategorySelectorDropdownComponent,
    MatProgressSpinner
  ],
  templateUrl: './filter-menu-category-selector.component.html',
  styleUrl: './filter-menu-category-selector.component.scss'
})
export class FilterMenuCategorySelectorComponent {
  @Input() categories: WritableSignal<CategoryMenuItem[]>;
  @Input() searchContainer: SearchContainer;
  @Input() trigger: WritableSignal<boolean>;

  @Output() triggerLoading = new EventEmitter();

  currentTriggerValue: boolean = true;
  loading: boolean = false;
  selectedCategory: CategoryMenuItem;

  constructor(
    private readonly searchHandlerService: SearchHandlerService
  ) {
    effect(() => {
      if (this.currentTriggerValue !== this.trigger()) {
        if (!this.selectedCategory) {
          this.selectedCategory = this.categories()[0];
        }
        if (this.searchContainer.currentPathView.path_name !== this.selectedCategory?.path) {
          this.updateSelectedCategory();
        }

        this.currentTriggerValue = this.trigger();

        if (this.loading) {
          this.loading = false;
        }
      }
    });
  }

  clearSelectedCategory(event: MouseEvent) {
    event.stopPropagation();
    if (this.searchContainer.focus.curFocusId) {
      this.searchHandlerService.goPathView(this.searchContainer.focus.curFocus.focus.homePath, this.searchContainer, true).then();
    }
    else {
      this.selectedCategory = this.categories()[0];
      this.searchHandlerService.goPathView(this.categories()[0].path, this.searchContainer, true).then();
    }

    this.loading = true;
    this.triggerLoading.emit();
  }

  categorySelected(selectedCategory: CategoryMenuItem) {
    this.selectedCategory = selectedCategory;
    this.triggerLoading.emit();
    this.loading = true;
  }

  private updateSelectedCategory() {
    for (const menu of this.categories()) {
      if (menu.path === this.searchContainer.currentPathView.path_name) {
        this.selectedCategory = menu;
        break;
      }

      if (menu.children?.length > 0) {
        for (const subMenu of menu.children) {
          if (subMenu.path === this.searchContainer.currentPathView.path_name) {
            this.selectedCategory = subMenu;
            break;
          }
        }
      }
    }
  }
}

