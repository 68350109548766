import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
  ViewChild
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-auto-expandable-overflow-container',
  standalone: true,
  imports: [MatButtonModule],
  template: `
    <div class="container">
      <div class="content-wrapper">
        <div #content class="content" id="content" [class.with-gradient]="isOverflowing && !isFullyExpanded">
          <ng-content></ng-content>
        </div>
      </div>
      @if (isOverflowing) {
        <div class="p-2 flex">
          <button
            mat-button
            (click)="toggleExpand()"
            [attr.aria-expanded]="isExpanded"
            aria-controls="content">
            {{ isExpanded ? showLessText : showMoreText }}
          </button>
        </div>
      }
    </div>
  `,
  styleUrls: ['./auto-expandable-overflow-container.component.scss']
})
export class AutoExpandableOverflowContainerComponent implements AfterViewInit, OnDestroy {
  @Input() maxHeight: number = 200;
  @Input() showMoreText: string;
  @Input() showLessText: string;
  @ViewChild('content') content!: ElementRef;

  isOverflowing: boolean = false;
  isExpanded: boolean = false;
  // difference between isExpanded and isFullyExpanded:
  // isExpanded toggles before transition, isFullyExpanded after transition
  isFullyExpanded: boolean = false;
  private resizeObserver!: ResizeObserver;
  private destroy$ = new Subject<void>();
  private debounceTimeMs: number = 200;
  private resizeSubject = new Subject<void>();
  private resizeSubscription: Subscription | undefined;

  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
  ) {
    this.resizeSubscription = this.resizeSubject.pipe(
      debounceTime(this.debounceTimeMs),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.checkOverflow();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.checkOverflow();
      this.initializeResizeObserver();
    });
  }

  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    this.destroy$.next();
    this.destroy$.complete();
    this.resizeSubscription?.unsubscribe();
  }

  private initializeResizeObserver(): void {
    const ResizeObs = (window as any).ResizeObserver;
    this.resizeObserver = new ResizeObs(() => {
      this.resizeSubject.next();
    });

    if (this.content && this.content.nativeElement) {
      this.resizeObserver.observe(this.content.nativeElement);
    }
  }

  private checkOverflow(): void {
    const contentEl = this.content.nativeElement as HTMLElement;

    this.renderer.setStyle(contentEl, 'height', 'auto');
    this.renderer.setStyle(contentEl, 'overflow', 'visible');

    const contentHeight = contentEl.getBoundingClientRect().height;
    const shouldOverflow = contentHeight > this.maxHeight;

    if (shouldOverflow !== this.isOverflowing) {
      this.isOverflowing = shouldOverflow;
    }

    if (this.isOverflowing && !this.isExpanded) {
      this.applyCollapsedStyle();
    } else if (!this.isOverflowing) {
      this.renderer.setStyle(contentEl, 'height', 'auto');
      this.renderer.setStyle(contentEl, 'overflow', 'visible');
    }
    this.cdr.detectChanges();
  }

  private applyCollapsedStyle(): void {
    const contentEl = this.content.nativeElement as HTMLElement;
    this.renderer.setStyle(contentEl, 'height', `${this.maxHeight}px`);
    this.renderer.setStyle(contentEl, 'overflow', 'hidden');
  }

  toggleExpand(): void {
    const contentEl = this.content.nativeElement as HTMLElement;

    if (this.isExpanded) {
      // Collapse
      this.isExpanded = false;
      this.isFullyExpanded = false;

      // transition between 'auto' and a fixed value is not possible, so we first set the height to a fixed value before collapsing
      const fullHeight = contentEl.scrollHeight;
      this.renderer.setStyle(contentEl, 'height', `${fullHeight}px`);
      setTimeout(() => {
        // needs to be after a "tick", otherwise the height set above will be ignored
        this.renderer.setStyle(contentEl, 'height', `${this.maxHeight}px`);
        this.renderer.setStyle(contentEl, 'overflow', 'hidden');
      })
    } else {
      // Expand
      // same procedure as above, only in reverse, and wait for transition to end
      this.isExpanded = true;
      const fullHeight = contentEl.scrollHeight;
      this.renderer.setStyle(contentEl, 'height', `${fullHeight}px`);

      const transitionEnd = () => {
        if (this.isExpanded) {
          this.isFullyExpanded = true;
          this.renderer.setStyle(contentEl, 'overflow', 'visible');
          this.renderer.setStyle(contentEl, 'height', 'auto');
        }
        contentEl.removeEventListener('transitionend', transitionEnd);
      };
      contentEl.addEventListener('transitionend', transitionEnd);
    }
  }
}
