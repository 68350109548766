<div class="search-menu-select-all"
     [ngClass]="{'active': searchContainer.toggleActionMenu &&
     searchContainer.searchResult && searchContainer.searchResult.search_count < 1000,
     'hide-select-all': searchContainer.currentPathView.search_view.hide_select_all}">
  <mat-checkbox
    *ngIf="!searchContainer.currentPathView.search_view.hide_select_all"
    [(ngModel)]="searchContainer.selections.allSelected"
    (ngModelChange)="selectAll()"
    [checked]="searchContainer.selections.allSelected"
    matTooltip="{{'TRANS__SEARCH__SELECT_ALL__HELP_TEXT' | translate}}"
    [matTooltipDisabled]="searchContainer.searchResult && searchContainer.searchResult.search_count > 1000"
    [disabled]="(searchContainer.searchResult && searchContainer.searchResult.search_count > 1000)"
    [indeterminate]="indeterminate()">
  </mat-checkbox>
</div>
