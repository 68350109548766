import {Injectable} from '@angular/core';
import {SearchParameters, SearchParametersForOverview} from "./definitions/search-parameters";
import {CmsApiService} from "./cms-api.service";
import {SearchResult} from "./definitions/search-result";
import {LoggerService} from "./logger.service";

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private cms: CmsApiService,
              private logger: LoggerService) {
  }

  async search(searchParams: SearchParameters) {
    searchParams['q.op'] = searchParams['q.op'] || 'AND';
    return this.cms.search(searchParams);
  }

  async searchWithOverview(params: SearchParametersForOverview): Promise<SearchResult> {
    params['q.op'] = params['q.op'] || 'AND';
    return await this.cms.searchWithOverview(params);
  }

  async searchDbWithOverview(params: SearchParametersForOverview): Promise<any> {
    let res: SearchResult = null;
    const queueRes = await this.cms.searchDbWithOverview(params);
    if (queueRes.status === 'queued') {
      res = await this.checkIfGettingCachedSearchData(queueRes);
    } else {
      throw new Error('Unknown status: ' + queueRes.status);
    }
    return res;
  }

  async getDbSearchCount(params: SearchParametersForOverview): Promise<any> {
    let res: SearchResult = null;
    const queueRes = await this.cms.getDbSearchCount(params);
    if (queueRes.status === 'queued') {
      res = await this.checkIfGettingCachedSearchData(queueRes);
    } else {
      throw new Error('Unknown status: ' + queueRes.status);
    }
    return res;
  }

  private async checkIfGettingCachedSearchData(data: any): Promise<SearchResult> {
    const correlationId = data['correlation_id'];
    if (!correlationId) {
      this.logger.debug('Correlation Id not found: ' + correlationId);
      return Promise.reject('Correlation Id not found');
    }
    this.logger.debug('Request timeout with correlationId: ' + correlationId);
    const cachedResult = await this.getCachedSearchDatatWithRetry(correlationId, 0, 500);
    if (cachedResult) {
      this.logger.debug('Got cached search result with correlationId: ' + correlationId);
      await this.cms.deleteCachedSearchData(correlationId);
      this.logger.debug('Deleted cached search result with correlationId: ' + correlationId);
    }
    return cachedResult;
  }

  private async getCachedSearchDatatWithRetry(correlationId: string, retries = 0, waitNext = 500): Promise<SearchResult> {
    let tooManyRetries = false;
    try {
      const res = await this.cms.getCachedSearchData(correlationId);
      if (res && !res['error']) {
        this.logger.debug('Got cached search result');
        return res;
      } else if (retries < 30) {
        this.logger.info(
          `Retrying getting cached search result for id ${correlationId}, wait for ${waitNext} ms`);
        await new Promise(resolve => setTimeout(resolve, waitNext));
        return this.getCachedSearchDatatWithRetry(correlationId, retries + 1, waitNext + 500);
      } else {
        tooManyRetries = true;
      }
    } catch (error) {
      console.error('Error getting cached overview result:', error);
      throw error;
    }
    if (tooManyRetries) {
      throw new Error('Too many retries getting cached overview result');
    }
  }
}
