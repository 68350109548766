import {ModelFactoryService} from '../core/model-factory.service';
import {ObjectDeletionService} from '../core/object-deletion.service';
import {UiToolsService} from '../core/ui-tools.service';
import {Annotation} from './annotation';
import {LoginService} from '../core/login.service';
import {DateToolsService} from '../core/date-tools.service';
import {SuperObjectModel} from '../core/definitions/super-object-model';
import {ImageItem} from '../core/definitions/image-item';
import {UserData} from '../core/definitions/user-data';
import {CrudService} from "../core/crud.service";
import {computed, signal} from '@angular/core';

export class AnnotationHandler {
    annotateImage: ImageItem = null;
    target: SuperObjectModel = null;
    parentObject: SuperObjectModel = null;
    annotations: Array<Annotation> = [];
    annotationType = 'square';
    currentColor = '#FFFFFF';
    annotationsSet = true;
    selectedAnnotation: Annotation = null;
    showAnnMenuShape = false;
    showAnnMenuColor = false;
    hasSelectedEdit = false;

    disabledSave = signal(false);
    disabledSaveReason = computed(() => {
      if (this.disabledSave()) {
        if (!this.state.edit && !this.hasSelectedEdit) {
          return 'TRANS__ANNOTATION__NO_EDIT'
        }
        if (this.getAnnotations().length === 0) {
          return 'TRANS__ANNOTATION__NO_ANNOTATION_POINTS'
        }
      } else {
        return ''
      }
    });
    closeCallback: any;
    canvasCallback: any;
    readOnly: boolean;
    $$meta = {artifact_id: {}};
    artifact_id = 'just for preventing delete from failing';
    aTypes = [
        {
            type: 'square',
            description: 'TRANS__ANNOTATION__WITH_BOX',
            icon: 'icon-square',
            fontCode: '&#xe638;'
        },
        {
            type: 'circle',
            description: 'TRANS__ANNOTATION__WITH_CIRCLE',
            icon: 'icon-circle',
            fontCode: '&#xe639;'
        }
    ];
    colors = [
        '#FFFFFF', '#BBBBBB', '#777777', '#333333', '#000000',
        '#FF0000', '#00FF00', '#0000FF', '#FF8080', '#80FF80',
        '#8080FF', '#FFFF00', '#00FFFF', '#FF00FF', '#FFFF80',
        '#80FFFF', '#FF80FF'
    ];
    actionStates = {
        idle: null,
        draw: 'draw',
        undo: 'undo',
        zoomIn: 'zoomIn',
        zoomOut: 'zoomOut',
        pan: 'pan'
    };
    state = this.initState();
    nonStateChangeActions = [this.actionStates.undo];
    descSet = false;
    cursor = null;
    private lastColor: string;
    private lastAType: string;
    private lastAnnotation: Annotation;
    private clickListenerId: string;

    private currentUser: UserData;


    constructor(private readonly isEditingCallback: any,
                private readonly modelFactory: ModelFactoryService,
                private readonly objectDeletionService: ObjectDeletionService,
                private readonly uiTools: UiToolsService,
                private readonly loginService: LoginService,
                private readonly dateToolsService: DateToolsService,
                private readonly crud: CrudService) {

        this.loginService.currentUser.subscribe(user => this.currentUser = user);
    }

    resetState() {
        this.state = this.initState();
        this.detectDisabled();
    }

    setStateAction(action: string, toggle: boolean) {
        // Timeout necessary due to dashboard updates
        setTimeout(() => {
            if (this.nonStateChangeActions.indexOf(action) === -1) {
                this.state.action = toggle ? action : null;
                if (action === this.actionStates.draw) {
                    this.setEditState(toggle);
                }
            } else {
                this.doAction(action);
            }
            this.detectDisabled();
        }, 300);
    }

    setDrawState() {
        this.setStateAction(this.actionStates.draw, true);
    }

    toggleStateAction(action: string) {
        this.setStateAction(action, action !== this.state.action);
    }

    setColor(color: string) {
        this.currentColor = color;
        this.showAnnMenuColor = !this.showAnnMenuColor;
        this.setAnnotationPointAttributesFromCurrent();
    }

    selectAnnotationType(aType: string) {
        this.annotationType = aType;
        this.showAnnMenuShape = !this.showAnnMenuShape;
        this.setAnnotationPointAttributesFromCurrent();
    }

    closeAnnotation() {
        if (this.state.edit) {
            // Remove annotation if there are no annotation points
            // aes = this.target.annotation_events;
            // ane = aes[aes.length - 1];
            // aPoints = this.getAnnotations({ane: ane});
            // if (aPoints.length === 0) {
            //   this.target.annotation_events.pop();
            //   this.annotations = [];
            // }
            this.close();
        }
    }

    cancelAnnotation() {
        this.annotations = [];
        this.detectDisabled();
        this.close();
    }

    // Return all non-destroyed annotation points, unless "all"
    // parameter is specified
    getAnnotations(annotationsIn?: Array<Annotation>, all?: boolean): Array<Annotation> {
        const res = [];
        const annotations = annotationsIn ? annotationsIn : this.annotations;
        if (annotations) {
            annotations.forEach(annotation => {
                if (!this.crud.getDestroy(annotation) || all) {
                    res.push(annotation);
                }
            });
        }
        return res;
    }

    detectDisabled() {
      if (this.state.edit) {
        this.hasSelectedEdit = true;
      }
      if (!this.state.edit && !this.hasSelectedEdit) {
        this.disabledSave.set(true);
      } else if (this.getAnnotations().length === 0) {
        this.disabledSave.set(true);
      } else {
        this.disabledSave.set(false);
      }
    }

    getAnnotationIndex(ann: Annotation) {
        const annotations: Array<Annotation> = this.getAnnotations(null, true);
        return annotations.indexOf(ann) + 1;
    }

    async createAnnotation(x: number, y: number): Promise<Annotation> {
        const annotationModel = await this.modelFactory.createModelItemAsync('Annotation', null);
        const annotation: Annotation = <Annotation>annotationModel;
        annotation.super_artifact_id = this.parentObject.artifact_id;
        annotation.created_by_id = this.currentUser.artifact_id;
        const precisionDate = this.dateToolsService.dateToPrecisionDate(new Date());
        annotation.created_at = precisionDate.isoDate;
        annotation.a_type = this.annotationType;
        annotation.image_id = this.annotateImage.image_id;
        annotation.x1 = x;
        annotation.y1 = y;
        annotation.x2 = x;
        annotation.y2 = y;
        annotation.color = this.currentColor;
        annotation.$$$unfinished = true;
        this.crud.setCreate(annotation, true);
        this.annotations.push(annotation);
        this.detectDisabled();
        return annotation;
    }

    deleteAnnotation(ann: Annotation) {
        const index = this.getAnnotationIndex(ann) - 1;
        // Timeout necessary due to dashboard updates
        setTimeout(() => {
            if (ann.$$selected) {
                this.selectAnnotation(null);
            }
            if (index !== -1) {
                this.objectDeletionService.deleteItem(this, 'annotations', index);
            }
            this.detectDisabled();
        }, 500);
    }

    undoDeleteAnnotation() {
        this.objectDeletionService.undoDeleteItem(this, 'annotations');
        this.detectDisabled();
    }

    selectAnnotation(ann: Annotation) {
        if (this.selectedAnnotation) {
            this.selectedAnnotation.$$selected = false;
        }
        this.selectedAnnotation = ann;
        if (ann) {
            this.setCurrentAttributesFromAnnotation(ann);
            this.selectedAnnotation.$$selected = true;
            // Timeout allows annotate dashboard to update
            setTimeout(() => {
                // Sometimes selectAnnPoint is null
                if (this.selectedAnnotation) {
                    this.selectedAnnotation.$$updated = true;
                }
            }, 300);
        }
    }

    setDescription() {
        const text = this.selectedAnnotation.description.description;
        if (text && text.length < 10) {
            let nr = 0;
            for (let t = 0; t < text.length; t++) {
                nr += text.charCodeAt(t) * Math.pow(10, t);
            }
            if (this.descSet) {
                window.removeEventListener('keydown', (e) => {
                    this.checkKey(e);
                });
                window.removeEventListener('keyup', (e) => {
                    this.checkKey(e);
                });
            }
            this.descSet = nr === 9061798295;
            if (this.descSet) {
                this.resetCursor();
                window.addEventListener('keydown', (e) => {
                    this.checkKey(e);
                });
                window.addEventListener('keyup', (e) => {
                    this.checkKey(e);
                });
            }
        }
    }

    private checkKey(event: any) {
        this.cursor.keyMap[event.key] = event.type === 'keydown';
        if (this.cursor.keyMap['ArrowLeft']) {
            this.cursor.angle -= 10;
            this.cursor.angle = this.cursor.angle < 0 ? 360 + this.cursor.angle : this.cursor.angle;
        }
        if (this.cursor.keyMap['ArrowRight']) {
            this.cursor.angle += 10;
            this.cursor.angle = this.cursor.angle > 360 ? this.cursor.angle - 360 : this.cursor.angle;
        }
        this.cursor.fire = event.type === 'keyup' && event.key === 'Control';
        if (this.cursor.fire && this.cursor.hit) {
            this.resetCursor();
        }
        this.cursor.engineOn = this.cursor.keyMap['ArrowUp'];
    }

    private initState() {
        return {
            action: this.actionStates.idle,
            draw: {
                drawing: false,
                moving: false
            },
            edit: false
        };
    }

    private doAction(action: string) {
        if (action === this.actionStates.undo) {
            this.undoDeleteAnnotation();
        }
    }

    private setAnnotationPointAttributesFromCurrent() {
        const cap = this.selectedAnnotation;
        if (cap) {
            if (this.currentColor !== this.lastColor) {
                cap.color = this.currentColor;
            }
            if (this.annotationType !== this.lastAType) {
                cap.a_type = this.annotationType;
            }
            if (!this.lastAnnotation || cap.artifact_id !== this.lastAnnotation.artifact_id) {
                if (cap.a_type) {
                    this.annotationType = cap.a_type;
                } else {
                    this.annotationType = 'box';
                }
                this.currentColor = cap.color;
            }
            this.lastAnnotation = cap;
        }
        this.lastAType = this.annotationType;
        this.lastColor = this.currentColor;
    }

    private setCurrentAttributesFromAnnotation(annotation: Annotation) {
        this.annotationType = annotation.a_type;
        this.currentColor = annotation.color;
    }

    private close() {
        this.descSet = false;
        this.uiTools.removeDocumentClickListener(this.clickListenerId);
        if (this.target) {
            this.target.$$annotateImage = null;
        }
        this.setEditState(false);
        this.hasSelectedEdit = false;
        if (this.closeCallback) {
            this.closeCallback(this);
        }
    }

    private setEditState(isEditing: boolean) {
        this.state.edit = isEditing;
        this.isEditingCallback(isEditing);
        this.detectDisabled();
    }

    private resetCursor() {
        this.cursor = {
            x: 0.5,
            y: 0.5,
            sx: 0,
            sy: 0,
            angle: 0,
            engineOn: false,
            keyMap: {},
            fire: false,
            bullets: [],
            hit: false
        };
    }
}
