import {Component, Input, signal, WritableSignal} from '@angular/core';

import {SearchContainer} from "../../../core/definitions/search-container";
import {AdvancedSearchField} from "../../../core/definitions/advanced-search-field";
import {AdvancedSearchParams} from "../../../core/definitions/advanced-search-params";
import {TranslateService} from "@ngx-translate/core";
import {AdvancedSearchToolsService} from "../../../core/advanced-search-tools.service";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent, ConfirmDialogData} from "../../../object-edit/confirm-dialog/confirm-dialog.component";

@Component({
  selector: 'app-search-query-advanced-search',
  templateUrl: './search-query-advanced-search.component.html',
  styleUrl: './search-query-advanced-search.component.scss'
})
export class SearchQueryAdvancedSearchComponent {
  @Input() searchContainer: SearchContainer;

  fieldQuery: WritableSignal<string> = signal<string>('');
  fieldQueryDebouncer = null;
  scrollTop = 0;
  selectedFields: AdvancedSearchField[] = [];
  showDropdown: boolean = false;

  constructor(
    public translateService: TranslateService,
    private modal: MatDialog,
    private advancedSearchTools: AdvancedSearchToolsService
  ) {
    document.getElementById('searchMainContent').addEventListener('scroll', () => {
      this.scrollTop = document.getElementById('searchMainContent').scrollTop;
    });
  }

  clearSelected(field: AdvancedSearchField) {
    this.searchContainer.advancedSearchParams.superobject_type_ids = this.searchContainer.advancedSearchParams.superobject_type_ids.filter(f => f !== field.superobject_type_id);
    this.selectedFields = this.selectedFields.filter(f => f !== field);
  }

  filterDropdownContent(query: string) {
    if (this.fieldQueryDebouncer !== null) {
      clearTimeout(this.fieldQueryDebouncer);
    }

    this.fieldQueryDebouncer = setTimeout(() => {
      this.fieldQuery.set(query);
    }, 200)
  }

  getHeaderText() {
    if (this.searchContainer.currentPathView.path_name === 'home') {
      return this.translateService.instant('TRANS__QUERY_MENU__ADVANCED_SEARCH');
    }
    else {
      return `${this.translateService.instant('TRANS__QUERY_MENU__ADVANCED_SEARCH')} ${this.translateService.instant('TRANS__SEPARATOR__IN')} ${this.translateService.instant(this.searchContainer.currentPathView.search_view.title)}`;
    }
  }

  scrollToLocation() {
    const scrollableContainer = document.getElementById('searchMainContent');
    if (scrollableContainer.scrollTop === 0) {
      const targetScrollElement = document.getElementById('search_result_count');
      const rect = targetScrollElement.getBoundingClientRect();
      scrollableContainer.scroll({top: rect.y + 100, behavior: 'smooth'});
    }
    else {
      scrollableContainer.scroll({top: 0, behavior: 'smooth'});
    }
  }

  setAdvancedSearch(suggestion) {
    if (!this.advancedSearchTools.hasNoRulesSet(this.searchContainer)) {
      const modalRef = this.modal.open(ConfirmDialogComponent, {
        panelClass: 'confirm-dialog',
        data: {
          modalCancel: 'TRANS__ADVANCED_SEARCH__MODAL__CANCEL',
          modalConfirm: 'TRANS__ADVANCED_SEARCH__MODAL__CONFIRM',
          modalContent: 'TRANS__ADVANCED_SEARCH__MODAL__CONTENT',
          modalTitle: 'TRANS__ADVANCED_SEARCH__MODAL__TITLE',
        } as ConfirmDialogData
      });

      modalRef.afterClosed().subscribe((result) => {
        if (result) {
          this.searchContainer.advancedSearchParams = {...suggestion};
        }
      });
    }
    else {
      this.searchContainer.advancedSearchParams = {...suggestion};
    }
  }

  setShowDropdown($event: FocusEvent, show: boolean): void {
    if (show) {
      this.showDropdown = show;
    }
    else {
      $event.stopPropagation();
      $event.preventDefault();
      setTimeout(() => {
        if (!document.activeElement.closest('app-advanced-search-query-tree-view')) {
          this.showDropdown = show;
        }
      }, 1)
    }
  }

  selectField(node: AdvancedSearchField) {
    if (!this.searchContainer.advancedSearchParams) {
      this.searchContainer.advancedSearchParams = new AdvancedSearchParams();
      this.searchContainer.advancedSearchParams.superobject_type_ids = [];
    }

    if (node.superobject_type_id) {
      this.searchContainer.advancedSearchParams.superobject_type_ids.push(node.superobject_type_id);
    }

    this.selectedFields.push(node);
  }

  protected readonly searchSuggestions = AdvancedSearchToolsService.searchSuggestions;
  protected readonly localStorage = localStorage;
}
