import {booleanAttribute, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatIcon} from '@angular/material/icon';
import {NgClass, NgStyle, NgTemplateOutlet} from '@angular/common';
import {MatButton, MatIconButton} from '@angular/material/button';
import {CdkConnectedOverlay, CdkOverlayOrigin} from '@angular/cdk/overlay';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {MatCard, MatCardContent, MatCardFooter} from '@angular/material/card';
import {FeatureFlagsService} from '../core/feature-flags.service';

@Component({
  selector: 'app-floating-feature-toggle-switch',
  standalone: true,
  imports: [
    MatIcon,
    NgStyle,
    MatIconButton,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    MatButton,
    MatSlideToggle,
    MatCard,
    MatCardContent,
    MatCardFooter,
    NgClass,
    NgTemplateOutlet
  ],
  templateUrl: './floating-feature-toggle-switch.component.html',
  styleUrl: './floating-feature-toggle-switch.component.scss'
})
export class FloatingFeatureToggleSwitchComponent {
  @Input() feature: string;
  @Input() featureLabel: string;
  @Input() top?: string;
  @Input() right?: string;
  @Input() bottom?: string;
  @Input() left?: string;
  @Input() triggerLabel?: string;

  @Input({transform: booleanAttribute})
  get fixed(): boolean {
    return this._fixed;
  }
  set fixed(value: any) {
    this._fixed = value;
  }
  private _fixed = false;

  @Output() afterToggle = new EventEmitter<boolean>();

  constructor(
    private featureFlagsService: FeatureFlagsService,
  ) {
  }

  panelOpen = false;
  get position() {
    return this.fixed ? 'fixed' : 'absolute';
  }

  get featureEnabled() {
    return this.featureFlagsService.getFeatureOverride(this.feature);
  }

  onToggle() {
    if (this.featureEnabled) {
      this.featureFlagsService.disableFeatureOverride(this.feature);
    } else {
      this.featureFlagsService.enableFeatureOverride(this.feature);
    }
    this.afterToggle.emit(this.featureEnabled);
  }
}
