import {Component, Input} from '@angular/core';
import {DamsNameImportItem} from "../../../core/definitions/dams-import-data";
import {FormsModule} from "@angular/forms";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatIcon} from "@angular/material/icon";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {MatRadioButton, MatRadioChange, MatRadioGroup} from "@angular/material/radio";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-operation-view-dams-import-name',
  standalone: true,
  imports: [
    FormsModule,
    MatCheckbox,
    MatIcon,
    MatIconButton,
    MatButton,
    MatSlideToggle,
    MatRadioGroup,
    MatRadioButton,
    TranslateModule
  ],
  templateUrl: './operation-view-dams-import-name.component.html',
  styleUrl: './operation-view-dams-import-name.component.scss'
})
export class OperationViewDamsImportNameComponent {
  @Input() nameItems: DamsNameImportItem[] = [];

  selectionChanged(event: MatRadioChange, item: DamsNameImportItem) {
    if (event.value === 'create') {
      item.create_new = true;
    }
    else {
      item.create_new = false;
      item.selected_suggestion = event.value;
    }
  }
}
