<div class="outer-container">
  <div class="loading-container">
    <div class="inner">
      <div class="logo">
        <app-animated-primus-logo></app-animated-primus-logo>
      </div>
      <mat-spinner diameter="24"/>
      <p class="spinner-alternative" translate="TRANS__SEARCH__LOADING_INFO"></p>
      @if (currentStep()) {
        <p [@fadeInOut]="currentStep()">{{ (currentStep() | translate) || 'Loading...' }}</p>
      }

    </div>
  </div>
</div>
