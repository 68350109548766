import {Component, computed, Input, OnChanges, OnDestroy, signal} from '@angular/core';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {animate, style, transition, trigger} from '@angular/animations';
import {TranslateModule} from '@ngx-translate/core';
import {AnimatedPrimusLogoComponent} from './animated-primus-logo/animated-primus-logo.component';

const animationTime = 300;
const messageLingerTime = 5000;

@Component({
  selector: 'app-splash-screen',
  standalone: true,
  imports: [
    MatProgressSpinner,
    TranslateModule,
    AnimatedPrimusLogoComponent
  ],
  templateUrl: './splash-screen.component.html',
  styleUrl: './splash-screen.component.scss',
  animations: [
    trigger('fadeInOut', [
      transition('void => *', [style({ opacity: '0' }), animate(animationTime)]),
      transition('* => void', [animate(animationTime, style({ opacity: '0' }))]),
      transition('* => *', [
        style({ opacity: '0' }),
        animate(animationTime, style({ opacity: '1' })),
      ]),
  ])]
})
export class SplashScreenComponent implements OnChanges, OnDestroy {
  @Input() currentSteps: string[] = [];
  // workaround for making the @Input reactive. Set in ngOnChanges.
  currentStepsSignal = signal<string[]>([])

  loopTimer: any | null = null
  currentStepIndex = signal(0)
  currentStep = computed(() => this.currentStepsSignal()[this.currentStepIndex()])

  ngOnChanges(): void {
    this.currentStepsSignal.set(this.currentSteps ?? [])
    clearTimeout(this.loopTimer);
    this.currentStepIndex.set(0);
    this.loopTimer = setTimeout(() => this.incrementStepIndex(), messageLingerTime)
  }

  ngOnDestroy(): void {
    clearTimeout(this.loopTimer);
  }

  private incrementStepIndex() {
    let newIndex = this.currentStepIndex() + 1
    if (newIndex >= this.currentSteps?.length) {
      // last message, stay here forever
      return
    }

    this.currentStepIndex.set(newIndex)
    this.loopTimer = setTimeout(() => this.incrementStepIndex(), messageLingerTime)
  }
}
