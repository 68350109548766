import {Component, ElementRef, Input, OnChanges, OnDestroy, ViewChild} from '@angular/core';
import {FieldValue} from '../../core/definitions/object-view';
import {UiToolsService} from '../../core/ui-tools.service';

@Component({
  selector: 'app-object-view-field-value',
  templateUrl: './object-view-field-value.component.html',
  styleUrls: ['./object-view-field-value.component.scss']
})
export class ObjectViewFieldValueComponent implements OnChanges {

  @ViewChild('textAreaElement') textAreaElement: ElementRef;
  @Input() fieldValue: FieldValue;
  @Input() edit: boolean;
  @Input() fieldValueClass: string;

  url = false;
  urlObject;
  imageObject;
  ILLEGAL_REF_ID_PREFIXES = ['ct_'];

  constructor() {
  }

  ngOnChanges() {
    if (this.fieldValue.ref_id) {
      for (const checkPrefix of this.ILLEGAL_REF_ID_PREFIXES) {
        if (this.fieldValue.ref_id.indexOf(checkPrefix) === 0) {
          return;
        }
      }
      if (this.fieldValue.ref_id.indexOf('IMG-') === 0) {
        this.imageObject = {
          thumbnail_id: this.fieldValue.ref_id,
          object_type: this.fieldValue.ref_object_type
        };
      } else if (this.fieldValue.ref_id === 'url') {
        this.url = true;
      } else {
        this.urlObject = {
          artifact_id: this.fieldValue.ref_id,
          object_type: this.fieldValue.ref_object_type,
          meta_type: this.fieldValue.ref_meta_type
        };
      }
    }
  }
}
