import {
  Component,
  effect,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Signal,
  SimpleChanges,
  WritableSignal
} from '@angular/core';
import {MatListItem, MatNavList} from "@angular/material/list";
import {
  MatNestedTreeNode,
  MatTree,
  MatTreeNode,
  MatTreeNodeDef,
  MatTreeNodeOutlet,
  MatTreeNodeToggle
} from "@angular/material/tree";
import { NestedTreeControl } from '@angular/cdk/tree';
import {MatIcon} from "@angular/material/icon";
import {SearchContainer} from "../../../../core/definitions/search-container";
import {CategoryMenuItem} from "../filter-menu-category-selector.component";
import {SearchHandlerService} from "../../../search-handler.service";

@Component({
  selector: 'app-filter-menu-category-selector-dropdown',
  standalone: true,
  imports: [
    MatNestedTreeNode,
    MatTree,
    MatTreeNode,
    MatTreeNodeDef,
    MatTreeNodeOutlet,
    MatTreeNodeToggle,
    MatListItem,
    MatNavList,
    MatIcon
  ],
  templateUrl: './filter-menu-category-selector-dropdown.component.html',
  styleUrl: './filter-menu-category-selector-dropdown.component.scss',
})
export class FilterMenuCategorySelectorDropdownComponent {
  @Input() categories: WritableSignal<CategoryMenuItem[]>;
  @Input() loading: WritableSignal<boolean>;
  @Input() searchContainer: SearchContainer;
  @Input() selected: CategoryMenuItem;

  @Output() selectedCategory = new EventEmitter<CategoryMenuItem>();

  currentLoadingState: boolean = true;
  menuTreeControl = new NestedTreeControl<CategoryMenuItem>((menuItem) => menuItem.children);

  constructor(
    private searchHandlerService: SearchHandlerService
  ) {
    effect(() => {
      if (this.currentLoadingState !== this.loading()) {
        this.setExpandedNodes(this.selected);
        this.currentLoadingState = this.loading();
      }
    });
  }

  categorySelected(menuItem: CategoryMenuItem) {
    this.selectedCategory.emit(menuItem);
    this.searchHandlerService.goPathView(menuItem.path, this.searchContainer).then();
  }

  hasChild(_: number, menuItem: CategoryMenuItem) {
    return !!menuItem.children?.length;
  }

  private setExpandedNodes(selected: CategoryMenuItem) {
    if (selected) {
      for (const category of this.categories()) {
        if (this.hasChild(0, category)) {
          for (const child of category.children) {
            if (child.path === selected.path) {
              this.menuTreeControl.expand(category);
            }
          }
        }
      }
    }
  }
}
