import {Component, Input, OnChanges, Signal, SimpleChanges} from '@angular/core';
import {MatListItem, MatListItemIcon, MatListItemTitle, MatNavList} from '@angular/material/list';
import {MatLine} from '@angular/material/core';
import {MatAccordion, MatExpansionPanel, MatExpansionPanelHeader} from '@angular/material/expansion';
import {
  MatNestedTreeNode,
  MatTree,
  MatTreeNode,
  MatTreeNodeDef,
  MatTreeNodeOutlet,
  MatTreeNodeToggle
} from '@angular/material/tree';
import {NestedTreeControl} from '@angular/cdk/tree';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {MatIcon} from '@angular/material/icon';
import {MatSuffix} from '@angular/material/form-field';

export interface NavMenuItem {
  type: "link" | "section";
  icon?: string;
  label: Signal<string>;
  link?: string;
  children?: NavMenuItem[];
  disabled?: boolean;
  count?: number;
}

@Component({
  selector: 'app-object-page-navigation',
  standalone: true,
  imports: [
    MatNavList,
    MatListItem,
    MatLine,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatListItemTitle,
    MatAccordion,
    MatTree,
    MatTreeNode,
    MatTreeNodeDef,
    MatNestedTreeNode,
    MatTreeNodeOutlet,
    MatTreeNodeToggle,
    RouterLink,
    MatIcon,
    MatSuffix,
    RouterLinkActive,
    MatListItemIcon,
  ],
  templateUrl: './object-page-navigation.component.html',
  styleUrl: './object-page-navigation.component.scss'
})
export class ObjectPageNavigationComponent implements OnChanges {

  @Input() items: NavMenuItem[] = [];
  @Input() initialSelectedItem?: string;

  menuTreeControl = new NestedTreeControl<NavMenuItem>((menuItem) => menuItem.children)

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.initialSelectedItem || changes.items) {
      this.expandParentOfActiveRoute();
    }
  }

  hasChild = (_: number, menuItem: NavMenuItem) => !!menuItem.children?.length;

  private expandParentOfActiveRoute() {
    // If a child route is active, we should expand the parent
    for (const item of this.items) {
      if (item.children?.length) {
        const selectedItem = item.children.find(c => c.link === this.initialSelectedItem)
        if (selectedItem) {
          this.menuTreeControl.expand(item);
          break;
        }
      }
    }
  }
}
