import { Component } from '@angular/core';

@Component({
  selector: 'app-widget-container',
  standalone: true,
  imports: [],
  templateUrl: './widget-container.component.html',
  styleUrl: './widget-container.component.scss'
})
export class WidgetContainerComponent {

}
