<div class="filter-range">
  <ul class="filter-range__list">
    @for(range of filterRange.facet_ranges; track $index) {
      <li (click)="checkRange($index)" class="filter-range__list__item" [ngClass]="{ 'filter-range__list__item--selected' : !!range.checked }" >
        <span class="filter-range__list__item__label--body-medium-grow">
          {{ range.title | translate }}
        </span>
        @if (range.total_count && !range.editable) {
          <span class="filter-range__list__item__label--label-small filter-range__list__item__label--subtle">
            {{ range.total_count }}
          </span>
        }
        @if (!!range.checked) {
          <mat-icon class="filter-range__list__item__icon">
            clear
          </mat-icon>
        }
      </li>
      @if (!!range.checked && range.editable) {
        <div class="filter-range__list__item__container">
          <div class="filter-range__list__item__container__field">
            <mat-form-field appearance="outline" class="filter-range__list__item__container__field__form">
              <input
                matInput
                class="filter-range__list__item__container__field__input"
                [placeholder]="'TRANS__SEARCH_MENU__DATE_FROM' | translate"
                type="text"
                (input)="onInputChange($event, 'start', range)"
                (keydown)="setRange($event, range)"
                [value]="localDate(range, 'start')"
              />
            </mat-form-field>

            <mat-form-field appearance="outline" class="filter-range__list__item__container__field__form">
              <input
                matInput
                class="filter-range__list__item__container__field__input"
                [placeholder]="'TRANS__SEARCH_MENU__DATE_TO' | translate"
                type="text"
                (input)="onInputChange($event, 'end', range)"
                (keydown)="setRange($event, range)"
                [value]="localDate(range, 'end')"
              />
            </mat-form-field>
          </div>

          <div class="filter-range__list__item__container__field">
            <span class="filter-range__list__item__label--label-small-grow">
              {{ 'TRANS__SEARCH_MENU__DATE_FORMAT' | translate }}
            </span>
            <span class="filter-range__list__item__label--label-small-grow">
              {{ 'TRANS__SEARCH_MENU__DATE_FORMAT' | translate }}
            </span>
          </div>
        </div>
      }
    }
  </ul>
</div>
