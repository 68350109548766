import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
  OperationViewDialogComponent,
  OperationViewDialogData
} from './operation-view-dialog/operation-view-dialog.component';
import {OperationContainer} from '../core/definitions/operation-container';
import {AfterCloseOperation} from './operation.service';
import {OperationViewDamsDialogComponent} from "./operation-view-dams-dialog/operation-view-dams-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class OperationDialogService {

  constructor(private modalService: MatDialog) {

  }

  async openOperationDialog(operationContainer: OperationContainer): Promise<AfterCloseOperation> {
    if (operationContainer.currentOperation.operation_type_id === '75573156-a300-489f-97e6-f0d43def91ed') {
      return new Promise<any>(resolve => {
        const modalRef = this.modalService.open(OperationViewDamsDialogComponent, {
          disableClose: true,
          panelClass: 'edit-dialog',
          data: {
            operationContainer: operationContainer
          } as OperationViewDialogData
        });
        modalRef.afterClosed().subscribe((data) => {
          resolve(data);
        });
      });
    }
    else {
      return new Promise<any>(resolve => {
        const modalRef = this.modalService.open(OperationViewDialogComponent, {
          disableClose: true,
          panelClass: 'edit-dialog',
          data: {
            operationContainer: operationContainer
          } as OperationViewDialogData
        });
        modalRef.afterClosed().subscribe((data) => {
          resolve(data);
        });
      });
    }
  }

}
