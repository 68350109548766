import { Component } from '@angular/core';
import {MatIcon} from '@angular/material/icon';

@Component({
  selector: 'app-resize-handle',
  standalone: true,
  imports: [
    MatIcon
  ],
  templateUrl: './resize-handle.component.html',
  styleUrl: './resize-handle.component.scss'
})
export class ResizeHandleComponent {

}
