import {inject, Injectable, isDevMode, signal} from '@angular/core';
import {PrimusBackendInstanceService, PrimusInstanceDetails} from '../core/primus-backend-instance.service';
import {TranslateService} from '@ngx-translate/core';
import {from, groupBy, mergeMap, of, toArray} from 'rxjs';
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';
import {toObservable, toSignal} from '@angular/core/rxjs-interop';
import {HelpdeskApiService, TipsArticle} from './helpdesk-api.service';

/**
 * If any CMS API call takes longer than this, perform a health check
 */
export const TIMEOUT_TO_CHECK_HEALTH_AFTER_SLOW_CMS_API_MS = 600;
/**
 * When performing a health check, the system is considered hibernating
 * if it takes longer than this time to respond.
 */
const TIMEOUT_WHEN_SYSTEM_IS_CONSIDERED_HIBERNATING_MS = 2000;
const TIMEOUT_RETRY_HEALTHCHECK_AFTER_MS = 5000;

const USER_TIPS_TRANSLATION_PREFIX = 'TRANS__HIBERNATION__USER_TIPS__'

export interface HealthCheckResult {
  check_time_end?: string,
  check_time_start?: string,
  db?: {
    error?: string,
    ping?: string
  },
  memory?: number,
  resident?: number,
  solr?: {
    responseHeader?: {
      QTime?: number,
      params?: {
        df?: string,
        distrib?: string,
        echoParams?: string,
        preferLocalShards?: string,
        q?: string,
        rid?: string,
        rows?: string
      },
      status?: number,
      zkConnected?: boolean
    },
    status?: string
  },
  stack_size?: number
}
/**
 * Service for waking the system up from hibernation.
 */
@Injectable({
  providedIn: 'root'
})
export class HibernationService {

  private _isSleeping = signal(false);
  get isSleeping() {
    return this._isSleeping.asReadonly();
  }

  private _healthStatus = signal<HealthCheckResult | null>(null);
  get healthStatus() {
    return this._healthStatus.asReadonly();
  }

  private pollIntervalId: NodeJS.Timeout | null = null;

  private translation = inject(TranslateService);
  private helpdeskApiService = inject(HelpdeskApiService);

  private getLanguageCode(preferredLang?: string) {
    if (['no', 'sv', 'en'].includes(preferredLang)) {
      return preferredLang;
    }
    return 'no';
  }

  private usingFallback = signal(false);
  private selectedLanguage = signal(this.getLanguageCode(this.translation.currentLang ?? this.translation.getBrowserLang()));

  private _loadingUserTips = signal(true);
  get loadingUserTips() {
    return this._loadingUserTips.asReadonly();
  }

  get userTips() {
    // return toSignal(this.userTipsFromTranslations$);
    return toSignal(this.userTipsFromFresh$)
  }

  private selectedLanguageObservable$ = toObservable(this.selectedLanguage);

  private userTipsFromFresh$ = this.selectedLanguageObservable$.pipe(
    switchMap(lang => this.helpdeskApiService.getTipsAndTricksArticles(lang)),
    map(response => response.items),
    catchError((error) => {
      console.error('Failed to fetch user tips from helpdesk-api:', error);
      return of([]);
    }),
  ).pipe(
    tap((tips) => {
      if (!tips?.length) {
        if (!this.usingFallback()) {
          // seems better to fall back to showing _something_ in a different preferred language than nothing.
          this.usingFallback.set(true);

          // will trigger the whole pipeline again
          this.selectedLanguage.set('no');
        }
      } else {
        this._loadingUserTips.set(false);
      }
    })
  )

  // NOTE: https://kulturit.atlassian.net/browse/PRIM-5455
  // user tips should be fetched from Fresh eventually, and not translations-service.
  private userTipsFromTranslations$ = from(this.selectedLanguage()).pipe(
    map(this.getLanguageCode),
    tap(() => this._loadingUserTips.set(true)),
    // use selected language to get all translations
    switchMap(preferredLang => this.translation.getTranslation(preferredLang)),
    // filter out only user tips translations, and emit one at a time so they can be grouped
    switchMap(translations => from(Object.entries(translations).filter(([key, value]) => key.startsWith(USER_TIPS_TRANSLATION_PREFIX)))),
    // translation format is TRANS__HIBERNATION__USER_TIPS__<ID>_<TITLE|DESCRIPTION>
    groupBy(
      ([key, _]) => {
        const match = key.match(/USER_TIPS__(\d+)/);
        return match ? match[1] : '0';
      }
    ),
    // mergeMap because groupBy returns an observable of observables
    mergeMap(group => group.pipe(toArray())),
    // map the grouped translations to a model if we have all ingredients
    map((group) => {
      const title = group.find(([key, _]) => key.endsWith('TITLE'))?.[1] as string | undefined;
      const description = group.find(([key, _]) => key.endsWith('DESCRIPTION'))?.[1] as string | undefined;
      if (!title || !description) {
        return null;
      }
      return {
        title,
        description
      } as TipsArticle;
    }),
    filter(article => !!article),
    toArray(),
  ).pipe(
    tap((tips) => {
      if (!tips?.length) {
        if (!this.usingFallback()) {
          // seems better to fall back to showing _something_ in a different preferred language than nothing.
          this.usingFallback.set(true);
          // will trigger the whole pipeline again
          this.selectedLanguage.set('no');
        }
      } else {
        this._loadingUserTips.set(false);
      }
    }),
  )

  constructor() {
    this.translation.onLangChange.subscribe(event => {
      this.selectedLanguage.set(this.getLanguageCode(event.lang));
    });
  }

  private inflightHealthCheck: Promise<any> | null = null;
  private lastHealthCheckTime: number = 0;

  /**
   * Performs a health check against the selected API, triggering wakeup it if it is hibernating.
   * Promise will wait with resolving until the server is awake, and the connection to database and Solr is OK.
   * To view the last checked healthstatus, use the signal `healthStatus` instead.
   */
  async healthCheck() {
    if (this.inflightHealthCheck) {
      debugLog('[hibernation]: health check already in progress, returning existing promise');
      return this.inflightHealthCheck;
    }

    if (Date.now() - this.lastHealthCheckTime < 10_000) {
      debugLog('[hibernation]: health check already done recently, skipping');
      return Promise.resolve();
    }

    debugLog('[hibernation]: starting health check');
    this.inflightHealthCheck = new Promise(async (resolve, reject) => {
      try {
        const result = await this.healthCheckInternal();
        this.lastHealthCheckTime = Date.now();
        debugLog('[hibernation]: health check done');
        resolve(result);
      } catch (e) {
        console.error('[hibernation]: health check failed:', e);
        reject(e);
      } finally {
        debugLog('[hibernation]: health check done, clearing inflight promise');
        this.inflightHealthCheck = null;
      }
    });

    return this.inflightHealthCheck;
  }

  private async healthCheckInternal() {
    let server: PrimusInstanceDetails;
    try {
      server = PrimusBackendInstanceService.getInstanceDetails();
      if (!server) {
        throw new Error('No server instance found');
      }
    } catch (e) {
      debugLog('[hibernation] No server instance found, skipping health check');
      return;
    }

    this._isSleeping.set(false);
    let hibernateTimer: NodeJS.Timeout;
    try {
      hibernateTimer = setTimeout(() => {
        debugLog(`[hibernation] timeout during health check, server ${server.api} is hibernating`);
        this._isSleeping.set(true);
        this._healthStatus.set(null);
      }, TIMEOUT_WHEN_SYSTEM_IS_CONSIDERED_HIBERNATING_MS);

      const data = await this.fetchHealthStatus();

      if (data?.db?.error || data?.solr?.status !== 'OK') {
        debugLog(`[hibernation] server ${server.api} is hibernating`);
        this._isSleeping.set(true);

        // Poll until everything is OK
        const polledResponse = await new Promise((resolve, reject) => {
          this.pollIntervalId = setInterval(async () => {
            try {
              const data = await this.fetchHealthStatus();
              if (!data?.db?.error && data?.solr?.status === 'OK') {
                clearInterval(this.pollIntervalId);
                resolve(data);
              }
            } catch (e) {
              if (e.message === 'NO_SERVER_INSTANCE_SELECTED') {
                clearInterval(this.pollIntervalId);
                reject(e);
              }
              console.error('[hibernation] Health check failed:', e);
            }
          }, 5000);
        });

        this._isSleeping.set(false);
        return polledResponse;

      } else {
        clearTimeout(hibernateTimer);
        this._isSleeping.set(false);
        return data;
      }
    } catch (e) {
      if (e.message === 'NO_SERVER_INSTANCE_SELECTED') {
        this._isSleeping.set(false);
        return;
      }
      console.error('[hibernation] Health check failed:', e);
    } finally {
      clearTimeout(hibernateTimer);
    }
  }

  private async fetchHealthStatus() {
    return new Promise<HealthCheckResult>(async (resolve, reject) => {
      while (true) {
        try {
          const server: PrimusInstanceDetails = PrimusBackendInstanceService.getInstanceDetails();
          if (!server) {
            this._isSleeping.set(false);
            return reject(new Error('NO_SERVER_INSTANCE_SELECTED'));
          }
          const ping = await fetch(server.api + '/healthcheck/check_health/all', { method: 'GET' });
          if (!ping.ok) {
            if (ping.status === 503) {
              console.warn(`[hibernation] server ${server.api} is unavailable, retrying in ${TIMEOUT_RETRY_HEALTHCHECK_AFTER_MS} ms `);
              await new Promise((waitResolve) => setTimeout(waitResolve, TIMEOUT_RETRY_HEALTHCHECK_AFTER_MS));
              continue;
            } else {
              reject(new Error(`Failed to fetch health status, status code: ${ping.status}`));
              break;
            }
          }
          const healthResponse: HealthCheckResult = await ping.json();
          this._healthStatus.set(healthResponse);
          return resolve(healthResponse);
        } catch (e) {
          console.warn('[hibernation] Error during healthcheck:', e);
          console.info(`[hibernation] trying again in ${TIMEOUT_RETRY_HEALTHCHECK_AFTER_MS} ms`)
          await new Promise((waitResolve) => setTimeout(waitResolve, TIMEOUT_RETRY_HEALTHCHECK_AFTER_MS));
        }
      }
    });
  }
}

async function debugLog(msg: string) {
  if (isDevMode()) {
    console.debug(msg);
  }
}
