<div>
  <ul>

    @for (field of fields; track $index) {
      <li>
        <div class="field-title">{{field.title}}</div>
        <div class="field-value">
          @if (field.items?.length) {

          <app-overview-field
            [overviewField]="field"
            [viewName]="'object-overview'"
            [useMaterialSymbols]="true"
          ></app-overview-field>
          } @else {
            <span class="missing-value mat-body-medium">{{ 'TRANS__OBJECT_OVERVIEW_FIELDS_V2__MISSING_VALUE' | translate }}</span>
          }
        </div>
      </li>
    }
  </ul>
</div>
