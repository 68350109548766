import {
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  Input, OnChanges,
  OnInit,
  Output, SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import {ResizeHandleComponent} from './resize-handle/resize-handle.component';

@Directive({
  selector: '[appResizable]',
  standalone: true
})
export class ResizableDirective implements OnInit, OnChanges {

  @Input() appResizableEnabled: boolean = true;
  @Output() appResizableAfterResized = new EventEmitter<void>();

  constructor(
    private el: ElementRef,
    private viewContainerRef: ViewContainerRef,
  ) { }

  private componentRef: ComponentRef<ResizeHandleComponent>

  ngOnInit() {
    this.componentRef = this.viewContainerRef.createComponent(ResizeHandleComponent);
    this.el.nativeElement.appendChild(this.componentRef.location.nativeElement);
    if (this.appResizableEnabled) {
      this.enableResizing();
    } else {
      this.disableResizing();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.appResizableEnabled) {
      if (this.appResizableEnabled) {
        this.enableResizing();
      } else {
        this.disableResizing();
      }
    }
  }

  private enableResizing() {
    const el = this.componentRef?.location?.nativeElement;
    if (!el) {
      return;
    }
    el.addEventListener('mousedown', this.handleMouseDown);
    el.addEventListener('touchstart', this.handleMouseDown);
    el.classList.remove('disabled')
  }

  private disableResizing() {
    const el = this.componentRef?.location?.nativeElement;
    if (!el) {
      return;
    }
    el.removeEventListener('mousedown', this.handleMouseDown);
    el.removeEventListener('touchstart', this.handleMouseDown);
    el.classList.add('disabled')
  }

  private previousTouch: Touch | null = null;
  handleDrag = (e: MouseEvent) => {

    let movementX = e.movementX;
    if (e instanceof TouchEvent) {
      const touch = e.touches[0];
      if (this.previousTouch) {
        movementX = touch.pageX - this.previousTouch.pageX;
      }
      this.previousTouch = touch;
    }

    // negate the movement as we are scaling from right to left
    const delta = -movementX;
    this.el.nativeElement.style.width = `${this.el.nativeElement.offsetWidth + delta}px`;
  }

  handleMouseUp = () => {
    document.removeEventListener('mousemove', this.handleDrag);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('touchmove', this.handleDrag)
    document.removeEventListener('touchend', this.handleMouseUp)
    this.previousTouch = null;
    this.appResizableAfterResized.emit();
  }

  handleMouseDown = () => {
    document.addEventListener('mousemove', this.handleDrag);
    document.addEventListener('mouseup', this.handleMouseUp);
    document.addEventListener('touchmove', this.handleDrag);
    document.addEventListener('touchend', this.handleMouseUp);
  }
}
