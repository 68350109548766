<div class="operation-toolbar {{stylingClass}}">

  <div class="operation-toolbar__content"
       [ngClass]="{'errorInputFrame errorNoBorder':isInvalid,
       'moreThanOneButton': splitActions.length > 1}">

    <div class="  button-group">

      <div *ngIf="splitActions.length > 1"
           class="split-button-container"><!--split-button on small-screens-->
        <div class="split-button"
             [matTooltip]="getTooltipText(currentButton) | translate"
             [matTooltipDisabled]="!isDisabled(currentButton)">
          <button mat-flat-button
                  class="split-button__action"
                  color="primary"
                  (click)="runAction(currentButton)"
                  [disabled]="isDisabled(currentButton)">
<!--            || operationContainer && operationContainer.rootObject.$$annotateImage-->
            <span class="bold" [translate]="currentButton.button_text"></span></button>
          <button mat-flat-button
                  color="primary"
                  class="split-button__menu"
                  [matMenuTriggerFor]="splitOperationMenu"
                  [disabled]="isDisabled(currentButton)">
            <mat-icon style="margin: auto;">expand_more</mat-icon></button>
        </div>
      </div>
      <mat-menu #splitOperationMenu="matMenu">
        <button mat-menu-item
                *ngFor="let action of splitActions; index as $index"
                (click)="setCurrentButton($index)"
                [disabled]="action.button_text === currentButton.button_text"
                [ngClass]="{'active': action.button_text === currentButton.button_text}">
          <span [translate]="action.button_text"></span></button>
      </mat-menu>

      <div *ngFor="let action of toolbarActions"
           class="operation-toolbar__buttons-container"
           [matTooltip]="getTooltipText(action) | translate"
           [matTooltipDisabled]="!isDisabled(action)">
        <button mat-flat-button
                color="primary"
                class="operation-toolbar__save-button operation-toolbar__button"
                *ngIf="action.action_type !== 'cancelRegister'"
                (click)="runAction(action)"
                [disabled]="isDisabled(action)">
          <span class="bold" [translate]="action.button_text"></span></button>
        <button mat-button
                *ngIf="action.action_type === 'cancelRegister'"
                (click)="runAction(action)"
                class="operation-toolbar__button">
          <span class="bold" [translate]="action.button_text"></span></button>
      </div>

    </div>

    <div class="operation-toolbar__column-error medium-6 small-4 large-6 columns">
      <span class="operation-toolbar__error-container" *ngIf="isInvalid">
        <span class="bold error-text-medium-up"
              translate="TRANS__SAVE_TOOLBAR__EXIST"></span>
        <span class="bold">&nbsp;{{errorCount}}&nbsp;</span>
        <span class="bold" translate="TRANS__SAVE_TOOLBAR__ERROR"></span>&nbsp;
        <span class="bold error-text-medium-up"
              translate="TRANS__SAVE_TOOLBAR__IN_FORM"></span>&nbsp;
        <span class="error-help-text" translate="TRANS__SAVE_TOOLBAR__HELP_TEXT"></span>
        <span class="vertical-line-error"></span>
        <a mat-button color="warn" (click)="nextError()" class="error-link">
          <span translate="TRANS__SAVE_TOOLBAR__SHOW"></span>
          <span>&nbsp;</span>
          <span class="error-link-text" translate="TRANS__SAVE_TOOLBAR__NEXT_ERROR"></span></a>
      </span>
    </div>

  </div>
</div>
