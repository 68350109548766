<div class="search-focus-menu">
  <app-search-focus-menu-panel
    *ngIf="showFocusPanel"
    (closeFocusPanel)="toggleFocusPanel()"
    [fsi]="fsi"
    [searchContainer]="searchContainer"></app-search-focus-menu-panel>

  <div class="search-focus-menu__text-container">
    <mat-icon [class.text-blue]="searchContainer.focus.curFocusId">visibility</mat-icon>
    @if (!searchContainer.focus.curFocusId) {
      <span *ngIf="!searchContainer.focus.curFocusId"
            translate="TRANS__FOCUS__INFO_NO_FOCUS_SELECTED"
            class="search-focus-menu__no-focus-selected-text"></span>
    }
    @if (searchContainer.focus.curFocusId && searchContainer.focus.curFocus.focus.name) {
      <div class="search-focus-menu__text-container">
        <span class="search-focus-menu__text">
          {{searchContainer.focus.curFocus.focus.name}}
        </span>
        <a class="color-white text-blue-hover" (click)="deselectFocus()">
          <mat-icon>close</mat-icon>
        </a>
      </div>

    }
  </div>

  <div class="search-focus-menu__split-button">
    <button class="search-focus-menu__set-focus-button"
            (click)="toggleFocusPanel()"
            [ngClass]="{'active': showFocusPanel,
             'bg-blue': !showFocusPanel && searchContainer.focus.curFocusId && searchContainer.focus.curFocus.focus.stored && hasChanges()}"
    >
      {{'TRANS__FOCUS__BUTTON_FOCUS' | translate}}
      <mat-icon>expand_more</mat-icon>
    </button>

    <mat-menu #leaveFocus="matMenu"
              class="leave-focus-menu removePaddingInMatMenu"
              (closed)="focusButtonDropdown = false;"
              xPosition="before">
      <button (click)="deselectFocus()"
              mat-button
              class="text-blue-hover"
              [disabled]="!searchContainer.focus.curFocusId"
      >{{'TRANS__FOCUS__BUTTON_LEAVE_FOCUS' | translate}}</button>
    </mat-menu>
  </div>

</div>
