<div class="edit-item">
  <div class="edit-item__header">
        <span class="edit-item__fields-title">
          {{ item.title }}
        </span>
    <button mat-icon-button (click)="removed.emit()">
      <mat-icon>
        remove_circle_outline
      </mat-icon>
    </button>
  </div>

  <div class="edit-item__content">
    <div class="edit-item__fields">
      <span class="edit-item__fields-label-title">
        {{ 'TRANS__DAMS_TYPE__' + item.type.toUpperCase() | translate }}
      </span>

      @if (item.operationData?.producers?.length) {
        <app-operation-view-dams-import-name
          [nameItems]="item.operationData.producers"
        />
      }

      <div class="edit-item__field">
        <mat-form-field class="edit-item__form-field">
          <mat-label>
            Beskrivelse
          </mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="item.operationData.description"
          />
        </mat-form-field>

        <button mat-icon-button>
          <mat-icon>
            help_circle_outline
          </mat-icon>
        </button>
      </div>

      @if (item.operationData?.rights?.length) {
        <span class="edit-item__fields-label-title">
          {{ 'TRANS__DAMS_DIALOG__RIGHTS' | translate }}
        </span>

        @for (right of item.operationData?.rights; track rightIndex; let rightIndex = $index) {
          <div class="edit-item__right">
            <span class="edit-item__right-title">
              {{ 'TRANS__DAMS__RIGHT_TYPE__' + right.rights_main_type.toUpperCase() | translate }}
            </span>

            <div class="edit-item__field">
              <mat-form-field class="edit-item__form-field">
                <mat-label>
                  Rettighetstype
                </mat-label>
                <input
                  disabled
                  matInput
                  type="text"
                  [value]="right.dams_title"
                />
              </mat-form-field>

              <button mat-icon-button>
                <mat-icon>
                  help_circle_outline
                </mat-icon>
              </button>
            </div>

            <div class="edit-item__field">
              <mat-form-field class="edit-item__form-field">
                <mat-label>
                  Datering fra
                </mat-label>
                <input
                  disabled
                  matInput
                  type="text"
                  [value]="right.rights_from_date || ''"
                />
              </mat-form-field>

              <mat-form-field class="edit-item__form-field">
                <mat-label>
                  Datering til
                </mat-label>
                <input
                  disabled
                  matInput
                  type="text"
                  [value]="right.rights_to_date || ''"
                />
              </mat-form-field>

              <button mat-icon-button>
                <mat-icon>
                  help_circle_outline
                </mat-icon>
              </button>
            </div>

            @if (right.rights_originator_names?.length || right.rights_responsible_names?.length) {
              <span class="edit-item__fields-label-title">
                {{ 'TRANS__DAMS_DIALOG__NAMES' | translate }}
              </span>
            }

            @if (right.rights_responsible_names?.length) {
              <div class="edit-item__right">
                <app-operation-view-dams-import-name
                  [nameItems]="right.rights_responsible_names"
                />
              </div>
            }

            @if (right.rights_originator_names?.length) {
              <div class="edit-item__right">
                <app-operation-view-dams-import-name
                  [nameItems]="right.rights_originator_names"
                />
              </div>
            }
          </div>
        }
      }
    </div>

    <div class="edit-item__image-name">
      @if (!item.$$hasImage) {
        <div class="edit-item__placeholder">
          <mat-icon class="edit-item__placeholder-icon">
            {{ DamsService.getIconFromType(item.type) }}
          </mat-icon>
        </div>
      } @else {
        <img
          [alt]="item.title"
          class="edit-item__image"
          [src]="image?.medium"
        />
      }
    </div>
  </div>
</div>
