import {MetaField} from './meta-field';
import {ObjectStatus} from './object-status';
import {KeepObject} from './keep-object';
import {Section} from './sections-container';

export class BaseModel {
  $$dmsStatus: string;
  $$meta: { [name: string]: MetaField };
  $$deletable: boolean;
  $$activeDropdown: boolean;
  $$createdDate: string;
  $$justAdded: boolean;
  $$keep: KeepObject;
  $$icon: string;
  $$index: number;
  $$imageUrl: string;
  $$thumbUrl: string;
  $$largeImageUrl: string;
  $$originalImageUrl: string;
  $$maxImageUrl: string;
  $$parentId: string;
  $$parentObjectType: string;
  $$routerLink: any[];
  $$queryParams: any;
  $$open: boolean;
  $$disabled = false;
  $$annotateImage: boolean;
  $$id: string;
  $$singleSelect: boolean;
  $$canAnnotate: boolean;
  $$thumbnailUrl: string;
  $$uploading = false;
  $$object: BaseModel;

  create: boolean;
  destroy: boolean;
  update: boolean;
  model_title: string; // old pre-pydantic 2.x attribute, delete when all backend versions are >= 10.0.234
  object_model_title: string; // new pydantic 2.x attribute
  artifact_id: string;
  artifact_id_value: string;
  artifact_name: string;
  authority_id: string;
  created_at: string;
  created_by_id: string;
  code: string;
  context_id: string;
  context_id_value: string;
  filters: any[];
  has_authority: boolean;
  is_root_model: boolean;
  media_publish_flag: boolean;
  medium_image_url: string;
  meta_type: string;
  object_type: string;
  order_number: number;
  updated_at: string;
  updated_by_id_value: string;
  status: ObjectStatus;
  superobject_type_id: string;
  template_sections: Section[];
  tasks: BaseModel[];
  thumbnail_id: string;
  thumbnail_url: string;
  value: any;

  constructor(objectId?: string) {
    this.artifact_id = objectId;
  }
}
