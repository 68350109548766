import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {CmsApiService} from "../../../core/cms-api.service";
import {PrimusRouterService} from "../../../core/primus-router.service";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {GetArtifactsParams} from "../../../core/definitions/get-artifacts-params";
import {Field, ReportUserGenerated} from "../../../core/ModelStore/models/ReportUserGenerated";
import {ModelStore} from "../../../core/ModelStore/ModelStore";
import {
  GetArtifactViewAndDataForReportParams
} from "../../../core/definitions/get-artifact-params";
import {ObjectViewAndData} from "../../../core/definitions/object-view";

@Component({
  selector: 'report-preview',
  templateUrl: './report-preview.component.html',
  styleUrls: ['./report-preview.component.scss']
})
export class ReportPreviewComponent implements OnInit, OnChanges {
  bilderapportMedOversiktsfelterUuid  = 'ct_203-122c2a45-fe7a-4a2c-814a-fd5dc6917250';
  katalograpportMedOversiktsfelterUuid = 'ct_203-3772e49f-05f7-41c9-8bc1-b143663c4e7a';
  rapportlisteMedBildeUuid   = 'ct_203-7c44c83b-9329-45bf-90ee-0fc5e35e8ea8';
  bilderapportId = 'ct_203-7b5035be-a3e1-4712-9de5-e5beb7279680';
  prosedyrerapportUuid = 'ct_203-722c41c1-ff23-4901-af8e-39ab51aaba39';
  hendelsesrapportUuid = 'ct_203-33444d86-e4af-497e-a5de-ce1ff9c66e98';

  private paramMapSubscription: Subscription;
  reportId: string;
  @Input() limit: number;
  artifactsFromReport: ObjectViewAndData[];
  @Input() reportTemplate: ReportUserGenerated;
  @Input() viewOptionsCheckboxValues: {key: string, label: string, selected: boolean}[];
  @Input() preview: boolean = false;
  extractedArtifacts: any;

  constructor(private readonly cms: CmsApiService,
              private primusRouter: PrimusRouterService,
              private route: ActivatedRoute,
              private modelStore: ModelStore,
              private cdRef: ChangeDetectorRef) {

  }

  async ngOnInit(): Promise<void> {
    console.log('ROUTE: ', this.primusRouter.currentState());

    let reportViewTypes = this.modelStore.findAllModels('report_view_type');
    await reportViewTypes.loading;

    this.paramMapSubscription = this.route.paramMap.subscribe(params => {
      this.reportId = params.get('report_id');
    });

    if (!this.reportTemplate) {
      this.reportTemplate = this.modelStore.findModel('report_user_generated', this.reportId);
      await this.reportTemplate.loading;
    }

    if (this.reportId) {
      await this.reportTemplate.loading;

      let reportViewType = this.modelStore.findModel('report_view_type', this.reportTemplate.reportViewTypeId);
      await reportViewType.loading;

      console.log('REPORT VIEW TYPE', reportViewType);
      console.log('REPORT TEMPLATE: ', this.reportTemplate);
      let params: GetArtifactsParams = new GetArtifactsParams();
      params.artifact_ids = this.reportTemplate.getArtifactIds();
      params.get_source = true;

      let artifactPrams: GetArtifactViewAndDataForReportParams = new GetArtifactViewAndDataForReportParams();
      artifactPrams.report_id = this.reportId;
      artifactPrams.get_original_artifact = true;
      if (this.limit) {
        artifactPrams.limit = this.limit;
      }
      this.artifactsFromReport = await this.cms.gerArtifactViewAndDataForReport(artifactPrams);
      console.log('ARTIFACTS: ', this.artifactsFromReport);

      this.extractedArtifacts = this.extractFieldsForReportTemplate(this.reportTemplate, this.artifactsFromReport);
      console.log('EXTRACTED ARTIFACTS: ', this.extractedArtifacts);

      console.log('report preview: ', this.viewOptionsCheckboxValues);
      if (!this.viewOptionsCheckboxValues) {
        this.viewOptionsCheckboxValues = [
          {key: 'vis_ledetekster', label: 'Vis ledetekster', selected: false},
          {key: 'med_helbilde', label: 'Med helbilde', selected: false},
          {key: 'høyrejuster_bilder', label: 'Høyrejuster bilder', selected: false},
          {key: 'ledetekst_og_verdi_på_en_linje', label: 'Ledetekst og verdi på én linje', selected: false},
          {key: 'med_frimerkebilder', label: 'Med frimerkebilder', selected: false},
          {key: 'vise_fotograf_for_bilder', label: 'Vise fotograf for bilder', selected: false},
          {key: 'vis_plasseringsdetaljer', label: 'Vis plasseringsdetaljer', selected: false},
          {key: 'vis_søkekriterium', label: 'Vis søkekriterium', selected: false},
          {key: 'vis_sidetall', label: 'Vis sidetall', selected: false}
        ]
      }
      if (this.reportTemplate.reportViewOptions) {
        this.viewOptionsCheckboxValues.forEach(option => {
          option.selected = this.reportTemplate.reportViewOptions.split(',').includes(option.key);
        });
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reportTemplate) {
      const previousFieldSections = changes.reportTemplate?.previousValue?.fieldSections;
      const currentFieldSections = changes.reportTemplate?.currentValue?.fieldSections;

      const previousIsSelectedValues = previousFieldSections ? previousFieldSections.map(section => section.isSelected).join(', ') : '';
      const currentIsSelectedValues = currentFieldSections ? currentFieldSections.map(section => section.isSelected).join(', ') : '';

      console.log('PREVIOUS IS SELECTED VALUES: ', previousIsSelectedValues);
      console.log('CURRENT IS SELECTED VALUES: ', currentIsSelectedValues);

      if (this.extractedArtifacts) {
        this.extractedArtifacts = this.extractFieldsForReportTemplate(this.reportTemplate, this.artifactsFromReport);
      }

      this.cdRef.detectChanges();

    }
  }

  /**
   * This function is rather ugly, but it is used to extract the fields from the artifacts that are needed for the report
   * The fields are extracted based on the sections that are selected in the report template, as well as the
   * fields that are selected in the sections.
   *
   * @param reportTemplate
   * @param artifacts
   */
  extractFieldsForReportTemplate(reportTemplate: ReportUserGenerated, artifacts: ObjectViewAndData[]): any {
    let objectFields: {}[] = [];

    artifacts.forEach(objectView => {
      let objectForReport = {
        artifactId: objectView.artifact.artifact_id,
        artifactName: objectView.artifact.artifact_name,
        sections: []
      };

      // Add thumbnail image to objectForReport
      //TODO: Check if there is a thumbnail image, and use that image ID instead of the first image in the array
      if (objectView.artifact.images || objectView.artifact.images.length > 0) {
        objectForReport['thumbnailImage'] = objectView.artifact.images[0];
        if (objectView.artifact.images && objectView.artifact.images.length > 0 && objectView.artifact.images[0].image_id) {
          objectForReport['thumbnailImage']['thumbnail_id'] = objectView.artifact.images[0].image_id;
        }
      }

      if (reportTemplate.reportViewTypeId === this.bilderapportMedOversiktsfelterUuid) {

      } else if (reportTemplate.reportViewTypeId === this.bilderapportId) {
        if (reportTemplate.fieldSections) {
          reportTemplate.fieldSections.forEach(section => {
            // If section is selected, add the section from view_data to objectForReport
            let foundSection = objectView.view_data.sections.find(sectionView => sectionView.name === section.name);
            if (foundSection && foundSection.name === 'motif') {
              objectForReport['sections'].push(foundSection);
              console.log('-------- ', section.name);
              console.log(section.fields);
              let reportFields = section.fields
                .filter(field => field.name === 'motif_description')
                .map(field => {
                  return this.formatFields(foundSection, field);
                });
              if (reportFields.length > 0) { // Only add sections that have fields with values
                objectForReport.sections.push({
                  id: section.name,
                  title: section.title,
                  fields: reportFields
                });
              }
            }
          });
        }
      } else {
        if (reportTemplate.fieldSections) {
          reportTemplate.fieldSections.forEach(section => {
            // If section is selected, add the section from view_data to objectForReport
            let foundSection = objectView.view_data.sections.find(sectionView => sectionView.name === section.name);
            if (section.isSelected && foundSection) {
              let reportFields = section.fields
                .filter(field => field.isSelected)
                .map(field => {
                  return this.formatFields(foundSection, field);
                })
                .filter(field => field !== null); // Remove any null fields

              if (reportFields.length > 0) { // Only add sections that have fields with values
                objectForReport.sections.push({
                  id: section.name,
                  title: section.title,
                  fields: reportFields
                });
              }
            }
          });
        }
      }




      objectFields.push(objectForReport);
    });

    return objectFields;
  }

  private formatFields(foundSection, field: Field) {
    // Find the corresponding sectionField in foundSection.section_fields
    let sectionField = foundSection.section_fields.find(
      sectionField => sectionField.field_name.endsWith("." + field.name) || sectionField.field_name === field.name
    );

    if (sectionField) {
      // Map the field to a key-value pair for the UI to use
      let reportField = {key: sectionField.title, fieldName: sectionField.field_name, value: []};

      if (sectionField.items) {
        sectionField.items.forEach(item => {
          let sectionFieldItem = {};

          sectionFieldItem['values'] = item.field_values?.values?.map(value => value.value).join(" ") || "";
          sectionFieldItem['labels'] = item.field_values?.title_values?.map(value => value.value).join(" ") || "";
          if (item.header_values && item.header_values.level === 1) {
            sectionFieldItem['headersLevel1'] = item.header_values?.values?.map(value => value.value).join(" ") || "";
            reportField['hasHeader1Or2'] = true;
          } else if (item.header_values && item.header_values.level === 2) {
            sectionFieldItem['headersLevel2'] = item.header_values?.values?.map(value => value.value).join(" ") || "";
            reportField['hasHeader1Or2'] = true;
          }

          reportField.value.push(sectionFieldItem);
        });
      }

      // Only return the reportField if it has a value
      return reportField.value.length > 0 ? reportField : null;
    } else {
      return null; // Field not found, return null
    }
  }
}
