<div class="edit-report-template-container">
  <div class="edit-report-template-container-left">
    <h1>{{'TRANS__REPORTS_V2_CUSTOMIZE_REPORT' | translate}}</h1>

    @if (!this.generatedReport) {
      <div>{{'TRANS__REPORTS_V2_LOADING_YOUR_REPORTS' | translate}}...</div>
    } @else {

      @if (this.generatedReport.reportStatus === 'generated') {
        <div class="edit-report-header-area">
          <h1>{{'TRANS__REPORTS_V2_NO_SELECTED_OBJECT' | translate}}</h1>
          <div>
            {{ 'TRANS__REPORTS_V2_PDF_GENERATED_NO_CHANGES_MESSAGE' | translate }}.
          </div>
        </div>
      } @else {
        <div class="edit-report-header-area">
          <h1>{{'TRANS__REPORTS_V2_SAVED_LAYOUTS' | translate}}</h1>
          <div class="edit-report-input-field-three-button">
            <mat-form-field class="edit-report-input-field">
              <mat-label>{{ 'TRANS__REPORTS_V2_CHOOSE_SAVED_LAYOUTS' | translate}}</mat-label>
              <mat-select [(ngModel)]="selectedReportUserTemplateId" (selectionChange)="onUserTemplateSelected($event)">
                @for (userTemplate of reportUserTemplates; track userTemplate.name) {
                  <mat-option [value]="userTemplate.id">{{ userTemplate.name }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
            <button class="mat-button edit-report-form-button edit-report-form-button-primary"
                    [matTooltip]="'TRANS__REPORTS_V2_SAVE_AS_NEW_SAVED_LAYOUTS' | translate"
                    (click)="doShowCreateNewReportSettingsArea()">
              <mat-icon>add</mat-icon>
            </button>
            <button class="mat-button edit-report-form-button edit-report-form-button-primary"
                    [matTooltip]="'TRANS__REPORTS_V2_SAVE_LAYOUTS' | translate"
                    [disabled]="!selectedReportUserTemplateId"
                    (click)="persistSelectedUserTemplate()">
              <mat-icon [ngClass]="{'edit-report-disabled-icon': !selectedReportUserTemplateId}">save</mat-icon>
            </button>
            <button class="mat-button edit-report-form-button edit-report-form-button-end"
                    [matTooltip]="'TRANS__REPORTS_V2_DELETE_LAYOUTS' | translate"
                    [disabled]="!selectedReportUserTemplateId"
                    (click)="doShowDeleteReportSettingsArea()">
              <mat-icon [ngClass]="{'edit-report-disabled-icon': !selectedReportUserTemplateId}">delete</mat-icon>
            </button>
          </div>

          @if (showCreateNewReportSettingsArea) {
            <div class="edit-report-edit-title-overlay"></div>
            <div class="edit-report-edit-title-area">
              <h1>{{'TRANS__REPORTS_V2_STORED_LAYOUT_NEW_TEXT' | translate}}.</h1>
              <h1>{{'TRANS__REPORTS_V2_STORED_LAYOUT_TITLE_HEADER' | translate}}</h1>
              <input class="form-field" type="text" name="title" placeholder="{{'TRANS__REPORTS_V2_STORED_LAYOUT_GIVE_NAME' | translate}}."
                     [(ngModel)]="newReportSettings.name"/>

              <div class="text-right">
                <button mat-button (click)="doCancelPersistingNewReportSettings()">{{'TRANS__REPORTS_V2_CANCEL' | translate}}</button>
                <button mat-button color="accent" (click)="doPersistNewReportSettings()">{{ 'TRANS__REPORTS_V2_SAVE' | translate}}</button>
              </div>
            </div>
          }

          @if (showDeleteReportSettingsArea) {
            <div class="edit-report-edit-title-overlay"></div>
            <div class="edit-report-edit-title-area">
              <h1>{{'TRANS__REPORTS_V2_DELETE_LAYOUTS' | translate}}</h1>
              <h1>{{'TRANS__REPORTS_V2_SAVE_SAVED_LAYOUT_QUESTION' | translate}}?</h1>
              <div class="text-right" style="margin-top: 20px;">
                <button mat-button (click)="showDeleteReportSettingsArea = false;">{{ 'TRANS__REPORTS_V2_CANCEL' | translate}}</button>
                <button mat-button color="accent" (click)="doDeleteReportSettings()">{{'TRANS__REPORTS_V2_DELETE' | translate}}</button>
              </div>
            </div>
          }


          <h1>{{'TRANS__REPORTS_V2_REPORT_LAYOUT' | translate}}</h1>
          <div>
            <mat-form-field>
              <mat-label>{{'TRANS__REPORTS_V2_CHOOSE_REPORT_LAYOUT' | translate}}</mat-label>
              <mat-select [(ngModel)]="this.selectedReportViewType" (selectionChange)="reportViewTypeChanged()">
                @for (reportView of this.reportViewTypes; track reportView) {
                  <mat-option [value]="reportView">{{ reportView.name }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>

          <h1>{{'TRANS__REPORTS_V2_VIEW_CHOICE' | translate}}
            @if (!showViewOptions) {
              <span class="edit-report-header-area-button accent float-right"
                    (click)="doShowViewOptions()">{{'TRANS__REPORTS_V2_SHOW' | translate}} {{'TRANS__REPORTS_V2_OPTIONS' | translate}}</span>
            } @else {
              <span class="edit-report-header-area-button accent float-right" (click)="doHideViewOptions()">{{'TRANS__REPORTS_V2_HIDE' | translate}} {{'TRANS__REPORTS_V2_OPTIONS' | translate}}</span>
            }
          </h1>

          @if (showViewOptions) {
            <div class="edit-report-two-column-area">
              @for (viewChoice of this.viewOptionsCheckboxValues; track viewChoice) {
                <label><input type="checkbox" [checked]="viewChoice.selected" (change)="toggleViewChoice(viewChoice)"
                              [attr.disabled]="!this.selectedReportViewType || this.selectedReportViewType.viewChoices.indexOf(viewChoice.key) == -1 ? true : null"/> {{ viewChoice.label | translate }}</label>
              }
            </div>
          }

          <h1>{{'TRANS__REPORTS_V2_FIELDS_HEADER' | translate}}
            <span class="float-right">
              <span class="edit-report-header-area-button accent"
                    (click)="markAllTopLevelFields(true)">{{'TRANS__REPORTS_V2_SELECT_ALL' | translate}}</span>
            <span class="edit-report-header-area-button"
                  (click)="markAllTopLevelFields(false)">{{'TRANS__REPORTS_V2_DESELECT_ALL' | translate}}</span>
            <span class="edit-report-header-area-button float-right">{{'TRANS__REPORTS_V2_SEARCH_IN_LIST' | translate}}</span>
            </span>
          </h1>
        </div>
      }

      @if (this.generatedReport.reportStatus !== 'generated' && this.generatedReport.fieldSections) {
        <div class="report-collapsible-list"
             [dragula]="dragulaId"
             [(dragulaModel)]='generatedReport.fieldSections'>

          @for (field of generatedReport.fieldSections; track field) {
            <div class="report-collapsible-list-item">
              <div class="report-collapsible-list-item-header">
                <mat-slide-toggle [(ngModel)]="field.isSelected"
                                  (click)="toggleTopLevelField(field)"></mat-slide-toggle>
                <span class="report-collapsible-list-item-label"
                      (click)="toggleFieldExpanded(field)">{{ field.title | translate }}
                  ({{ field.fields.length }})</span>
                <span class="report-collapsible-list-item-actions">
                  @if (field.isExpanded) {
                    <mat-icon (click)="toggleFieldExpanded(field)">keyboard_arrow_down</mat-icon>
                  } @else {
                    <mat-icon (click)="toggleFieldExpanded(field)">keyboard_arrow_up</mat-icon>
                  }
                  <mat-icon class="handle">drag_indicator</mat-icon>
                </span>
              </div>

              @if (field.isExpanded) {
                @for (child of field.fields; track child) {
                  <div class="report-collapsible-list-child-item">
                    <div class="report-collapsible-list-child-item-header">
                      <mat-slide-toggle [(ngModel)]="child.isSelected"
                                        (click)="toggleSubField(field)"></mat-slide-toggle>
                      <span>{{ child.title ? (child.title | translate) : (child.admin_title | translate) }}</span>
                      <span class="report-collapsible-list-item-actions">
                      <!--mat-icon>drag_indicator</mat-icon-->
                    </span>
                    </div>
                  </div>
                }
              }
            </div>
          }
        </div>
      }
    }
  </div>
  <div class="edit-report-template-container-right">
    @if (this.generatedReport) {
    <div class="edit-report-preview-area">
        <report-preview [reportTemplate]="generatedReport" [viewOptionsCheckboxValues]="viewOptionsCheckboxValues"
                        preview="true" limit="10"></report-preview>
      @if (downloadPdfStarted()) {
        <div class="generation-in-progress-notification">{{'TRANS__REPORTS_V2_FETCH_REPORT' | translate}}...</div>
      }
      @if (reportGenerationInProgress()) {
        <div class="generation-in-progress-notification">{{'TRANS__REPORTS_V2_GENERATING_REPORT_MSG' | translate}}</div>
      }
      <div class="edit-report-button-area">
        @if (this.generatedReport.reportStatus === 'new') {
          <button [disabled]="reportGenerationInProgress()" mat-button color="accent" (click)="persistUserReport()">{{'TRANS__REPORTS_V2_SAVE_CHANGES' | translate}}</button>
        } @else {
          &nbsp;
        }

        <div class="edit-report-button-area-small-left-margin">* {{'TRANS__REPORTS_V2_PREVIEW_LIMIT_MSG' | translate}}
        </div>

        <div class="edit-report-buttons">
          @if (this.generatedReport.reportStatus !== 'generated') {
            <button [disabled]="reportGenerationInProgress() || this.generatedReport.reportStatus === 'generating'" mat-button>{{'TRANS__REPORTS_V2_BUTTON_CANCEL' | translate}}</button>
            <button [disabled]="reportGenerationInProgress() || this.generatedReport.reportStatus === 'generating'" mat-button color="accent"
                    (click)="requestReportPdf(this.generatedReport.id)">
                @if (reportGenerationInProgress() || this.generatedReport.reportStatus === 'generating') {
                  {{'TRANS__REPORTS_V2_BUTTON_CREATING_PDF' | translate}}...
                } @else {
                  {{'TRANS__REPORTS_V2_BUTTON_CREATE_PDF' | translate}}
                }
            </button>
          } @else {
            <button [disabled]="downloadPdfStarted()" mat-button color="accent"
                    (click)="downloadSinglePdf(this.generatedReport.id)">{{'TRANS__REPORTS_V2_BUTTON_DOWNLOAD_PDF' | translate}}
            </button>
          }

        </div>
      </div>
    </div>
      }
  </div>
</div>
