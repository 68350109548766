import {SearchContainer} from '../../../core/definitions/search-container';
import {CheckFilter, Facet, RangeGroup, SearchViewMenu} from '../../../core/definitions/search-objects';

export type FilterChip = MenuFilterChip | CheckfilterChip | RangeFilterChip;

type BaseType = {
  key: string;
  title?: string;
  noTransTitle?: string;
};

type MenuFilterChip = BaseType & {
  type: "searchMenu";
  menu: SearchViewMenu;
}

type CheckfilterChip = BaseType & {
  type: 'checkFilter';
  filter: CheckFilter;
}

type RangeFilterChip = BaseType & {
  type: 'rangeFilter';
  filterGroup: RangeGroup;
  filter: Facet;
}

export function searchContainerToChipList(searchContainer: SearchContainer, categoryMenus: SearchViewMenu[]): FilterChip[] {
  const result: FilterChip[] = [];

  if (categoryMenus.length) {
    for (const menuFilter of categoryMenus) {
      if (menuFilter.checked) {
        result.push({
          key: `search-menu-${menuFilter.title}-${menuFilter.path}`,
          type: 'searchMenu',
          title: menuFilter.title,
          menu: menuFilter
        })
      }
    }
  }

  if (searchContainer.filtersFacets?.filterGroups?.length) {
    for (const filterGroup of searchContainer.filtersFacets.filterGroups) {
      if (filterGroup?.checkFilters?.length) {
        for (const checkFilter of filterGroup.checkFilters) {
          if (checkFilter.checked) {
            result.push({
              key: `check-filter-${filterGroup.title}-${checkFilter.title}-${checkFilter.noTransTitle}`,
              type: 'checkFilter',
              title: checkFilter.title,
              noTransTitle: checkFilter.noTransTitle,
              filter: checkFilter
            })
          }
        }
      }
    }
  }

  if (searchContainer.filtersFacets?.facetRangeGroups?.length)  {
    for (const filterRange of searchContainer.filtersFacets.facetRangeGroups) {
      if (filterRange.facet_ranges?.length > 0) {
        for (const range of filterRange.facet_ranges) {
          if (range.checked) {
            result.push({
              key: `range-filter-${filterRange.title}-${range.title}`,
              type: 'rangeFilter',
              title: range.title,
              filter: range,
              filterGroup: filterRange
            });
          }
        }
      }
    }
  }

  return result;
}
