import { Injectable } from '@angular/core';
import {CmsApiService} from "../core/cms-api.service";
import {SortOrderMenu} from "../core/definitions/search-objects";

@Injectable({
  providedIn: 'root'
})
export class SearchViewSortOrderMenuService {
  private sortOrderMenus: {[name: string]: SortOrderMenu[]} = null;

  constructor(private cms: CmsApiService) {
  }

  async getSortOrderMenus(name: string): Promise<SortOrderMenu[]> {
    if (!this.sortOrderMenus) {
      this.sortOrderMenus = await this.cms.getSearchViewSortOrderMenus();
    }
    return this.sortOrderMenus[name] || [];
  }
}
