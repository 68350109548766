<div class="selector-dropdown__menu">
  <mat-nav-list class="selector-dropdown__dropdown-list">
    <mat-tree [dataSource]="categories()" [treeControl]="menuTreeControl">
      <mat-tree-node *matTreeNodeDef="let menuItem" [attr.aria-selected]="menuItem.path === searchContainer.currentPathView.path_name">
        <a class="selector-dropdown__list-item" mat-list-item (click)="categorySelected(menuItem)" [disabled]="menuItem.disabled || false">
          <div class="selector-dropdown__item-content">
            @if (menuItem.icon) {
              <mat-icon class="material-symbols-outlined">{{ menuItem.icon }}</mat-icon>
            }
            @else {
              <mat-icon class="material-symbols-outlined">category</mat-icon>
            }
            <span>
              {{ menuItem.label() }}
              @if (menuItem.count) {
                ({{ menuItem.count }})
              }
            </span>
          </div>
        </a>
      </mat-tree-node>

      <mat-nested-tree-node *matTreeNodeDef="let menuItem; when: hasChild">
        <button mat-list-item matTreeNodeToggle class="selector-dropdown__expandable-button" [disabled]="menuItem.disabled || false">
          <div matListItemTitle class="selector-dropdown__expandable-button">
            <div class="selector-dropdown__expandable-content">
              @if (menuItem.icon) {
                <mat-icon class="material-symbols-outlined">{{ menuItem.icon }}</mat-icon>
              }
              @else {
                <mat-icon class="material-symbols-outlined">category</mat-icon>
              }
              <span>{{ menuItem.label() }}</span>
            </div>

            <mat-icon class="material-symbols-outlined">
              @if (menuTreeControl.isExpanded(menuItem)) {
                expand_less
              }
              @else {
                expand_more
              }
            </mat-icon>
          </div>
        </button>

        @if (menuTreeControl.isExpanded(menuItem)) {
          <div role="group">
            <ng-container matTreeNodeOutlet></ng-container>
          </div>
        }
      </mat-nested-tree-node>
    </mat-tree>
  </mat-nav-list>
</div>
