import {Component, inject} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {CurrentObjectContext} from '../current-object.context';
import {ObjectViewModule} from '../../object-view/object-view.module';
import {M3GlobalKplStylesComponent} from '../../m3-global-kpl-styles/m3-global-kpl-styles.component';
import {
  AutoExpandableOverflowContainerComponent
} from '../../auto-expandable-overflow-container/auto-expandable-overflow-container.component';
import {ActivatedRoute, Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {AsyncPipe} from '@angular/common';

@Component({
  selector: 'app-object-page-v2-changelog',
  standalone: true,
  imports: [
    TranslateModule,
    ObjectViewModule,
    M3GlobalKplStylesComponent,
    AutoExpandableOverflowContainerComponent,
    AsyncPipe,
  ],
  templateUrl: './object-page-v2-changelog.component.html',
  styleUrl: './object-page-v2-changelog.component.scss'
})
export class ObjectPageV2ChangelogComponent {

  router = inject(Router);
  route = inject(ActivatedRoute);
  changelogType$ = this.route.paramMap.pipe(map(params => params.get('changelogType')));

  constructor(private currentObjectContext: CurrentObjectContext) {
  }

  get object() {
    return this.currentObjectContext.art
  }

}
