import {Component, EventEmitter, Input, OnInit, Output, WritableSignal} from '@angular/core';
import {
  AdvFieldQuery,
  AdvFieldQueryGroup,
  AdvFieldQueryLogicalOperator
} from "../../../core/definitions/advanced-search-params";
import {AdvancedSearchToolsService} from "../../../core/advanced-search-tools.service";
import {SearchContainer} from "../../../core/definitions/search-container";

@Component({
  selector: 'app-advanced-search-query-builder-group',
  templateUrl: './advanced-search-query-builder-group.component.html',
  styleUrl: './advanced-search-query-builder-group.component.scss'
})
export class AdvancedSearchQueryBuilderGroupComponent implements OnInit {
  @Input() group: AdvFieldQueryGroup;
  @Input() parentGroup: AdvFieldQueryGroup = null;
  @Input() rootGroup: AdvFieldQueryGroup;
  @Input() darken: boolean;
  @Input() searchContainer: SearchContainer;
  @Input() toggleUpdateFieldSelector: WritableSignal<boolean>;

  @Output() removeSelf = new EventEmitter<AdvFieldQueryGroup>();
  @Output() updateSelf = new EventEmitter<AdvFieldQueryGroup>();

  advancedFieldGroup: AdvFieldQueryGroup;

  constructor(
    private advancedSearchTools: AdvancedSearchToolsService
  ) {
  }

  ngOnInit() {
    this.advancedFieldGroup = this.group;

    this.advancedFieldGroup.field_queries = this.group.field_queries || [];
    this.advancedFieldGroup.sub_groups = this.group.sub_groups || [];

    if (this.advancedFieldGroup.field_queries.length === 0) {
      this.addField();
    }
  }

  addField() {
    const relationInfo = this.advancedSearchTools.getRelationInfoFromGroup(
      this.group, this.rootGroup);
    let newField: AdvFieldQuery = {
      parent_field_ids: [],
      relation_superobject_type_id: relationInfo?.superobjectTypeId,
      context_field: relationInfo?.contextField,
      child_document_type: relationInfo?.childDocumentType,
      is_array_context_field: relationInfo?.isArrayContextField,
      field_title: "",
      field_name: "",
      input_type: "",
      is_array_field: true,
      operator_selected: undefined,
      operators: [],
      path: "",
      reference_id: "",
      superobject_type_id: '',
      value: undefined,
      valueDisplay: '',
      valid: true
    };

    this.advancedSearchTools.addFieldQuery(this.searchContainer, newField, this.advancedFieldGroup, this.rootGroup);
  }

  addGroup() {
    this.advancedSearchTools.addQueryGroup(
      this.searchContainer, 'AND', this.advancedFieldGroup, this.rootGroup);
  }

  clearGroup(group: AdvFieldQueryGroup) {
    this.advancedSearchTools.clearQueryGroup(group, this.searchContainer, this.parentGroup);

    this.updateSelf.emit(group);
  }

  getOperatorDisplayLabel() {
    if (this.advancedFieldGroup.field_logical_operator === 'OR') {
      return 'TRANS__OPERATOR__OR';
    } else if (this.advancedFieldGroup.field_logical_operator === 'AND') {
      return 'TRANS__OPERATOR__AND';
    } else if (this.advancedFieldGroup.field_logical_operator === 'NOT') {
      return 'TRANS__OPERATOR__NOT';
    }
  }

  removeField(field: AdvFieldQuery) {
    this.advancedSearchTools.removeFieldQuery(this.searchContainer, field, this.advancedFieldGroup);

    if (this.advancedSearchTools.shouldResetFieldRestrictions(this.advancedFieldGroup)) {
      let temp = this.advancedFieldGroup;
      temp.relation_superobject_type_id = null;
      temp.child_document_type = null;
      temp.header = 'TRANS__ADVANCED_SEARCH__HEADER__OBJECTINFORMATION';

      this.updateSelf.emit(temp);
      this.toggleUpdateFieldSelector.set(!this.toggleUpdateFieldSelector());
    }

    if (this.advancedFieldGroup.field_queries.length === 0 && this.advancedFieldGroup.sub_groups.length === 0) {
      this.removeSelf.emit(this.advancedFieldGroup);
    }
  }

  removeGroup(groupToRemove: AdvFieldQueryGroup) {
    this.advancedSearchTools.removeFieldQueryGroup(this.searchContainer, groupToRemove, this.advancedFieldGroup);
  }

  updateField(newField: AdvFieldQuery, oldField: AdvFieldQuery, isFirstGroupField = false) {
    const relationInfo = this.advancedSearchTools.getRelationInfoFromGroup(
      this.advancedFieldGroup, this.rootGroup);
    const relationId = relationInfo?.superobjectTypeId || undefined;
    const newChildDocType = newField.child_document_type;
    const groupHasFields = this.advancedFieldGroup.field_queries.length > 1 &&
      this.advancedSearchTools.hasSelectedFields(this.advancedFieldGroup);
    if (!isFirstGroupField && newChildDocType && this.rootGroup.relation_superobject_type_id && this.advancedFieldGroup.child_document_type !== newChildDocType) {
      // If adding an inline array based field to a relation-based group, must create a new subgroup for the new field
      this.advancedSearchTools.addSubGroup(this.searchContainer, this.advancedFieldGroup, this.rootGroup, newField);
      this.removeField(oldField);
    } else if (newField.relation_superobject_type_id && newField.relation_superobject_type_id !== relationId && groupHasFields) {
      // If adding a relation based field to an existing group, and the relation type is different that the one
      // used in the current group, must create a new group for the new field
      this.advancedSearchTools.addSiblingQueryGroup(
        this.searchContainer, newField, this.advancedFieldGroup, this.rootGroup);
      this.removeField(oldField);
    } else if (newField.child_document_type && this.advancedFieldGroup.child_document_type !== newField.child_document_type && groupHasFields) {
      // If adding an inline array based field to an existing group, and the array type is different that the one
      // used in the current group, must create a new group for the new field
      const newGroup = {...this.advancedFieldGroup};
      newGroup.child_document_type = newField.child_document_type;
      this.advancedSearchTools.addSiblingQueryGroup(
        this.searchContainer, newField, newGroup, this.rootGroup);
      this.removeField(oldField);
    } else if (relationId || (newField.relation_superobject_type_id && this.advancedFieldGroup.field_queries.length === 1)) {
      // Setting correct relation for a group/field, I guess...
      this.advancedFieldGroup.relation_superobject_type_id = relationId || newField.relation_superobject_type_id;
      this.advancedSearchTools.updateFieldQuery(this.searchContainer, oldField, newField, this.advancedFieldGroup);
    } else if ((relationId !== newField.relation_superobject_type_id ||
      relationInfo?.childDocumentType !== newField.child_document_type ||
      relationInfo?.contextField !== newField.context_field) && groupHasFields) {
      // TODO: intellij claims that the above condition is same as line 124. Is this correct?
      this.advancedSearchTools.addSiblingQueryGroup(
        this.searchContainer, newField, this.advancedFieldGroup, this.rootGroup
      );
      this.removeField(oldField);
    } else {
      this.advancedSearchTools.updateFieldQuery(this.searchContainer, oldField, newField, this.advancedFieldGroup);
    }

    this.toggleUpdateFieldSelector.set(!this.toggleUpdateFieldSelector());
    this.updateSelf.emit(this.advancedFieldGroup);
  }

  updateGroup(newGroup: AdvFieldQueryGroup, oldGroup: AdvFieldQueryGroup) {
    this.advancedSearchTools.updateFieldQueryGroup(this.searchContainer, oldGroup, newGroup, this.advancedFieldGroup);
    this.updateSelf.emit(this.advancedFieldGroup);
  }

  updateLogicalOperator($event: AdvFieldQueryLogicalOperator, field: AdvFieldQuery) {
    this.advancedFieldGroup.field_logical_operator = $event;

    this.updateSelf.emit(this.advancedFieldGroup);
  }

  updateFieldLogicalOperator($event: AdvFieldQueryLogicalOperator) {
    let temp = this.group;
    temp.field_logical_operator = $event;
    this.updateSelf.emit(temp);
  }

  updateLogicalOperatorGroup($event: AdvFieldQueryLogicalOperator) {
    let temp = this.group;
    temp.logical_operator = $event;
    this.updateSelf.emit(temp);
  }
}
