import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MediaPageComponent} from './media-page/media-page.component';
import {ObjectViewModule} from '../object-view/object-view.module';
import {ObjectSearchModule} from '../object-search/object-search.module';
import {MediaCarouselModule} from '../media-carousel/media-carousel.module';
import {OperationsModule} from '../operations/operations.module';
import {OverviewFieldsModule} from '../overview-fields/overview-fields.module';
import {MatIconModule} from '@angular/material/icon';
import {ObjectContentTabModule} from '../object-content-tab/object-content-tab.module';
import {SharedModule} from '../shared/shared.module';
import {ObjectPageLayoutComponent} from '../object-page-v2/object-page-layout/object-page-layout.component';
import {
  ObjectOverviewFieldsV2Component
} from '../object-page-v2/ui/object-overview-fields-v2/object-overview-fields-v2.component';
import {
  ObjectPageHeadingComponent
} from '../object-page-v2/ui/object-page-header/object-page-heading/object-page-heading.component';
import {MatCard} from '@angular/material/card';
import {
  ObjectPageOperationsToolbarComponent
} from '../object-page-v2/ui/object-page-header/object-page-operations-toolbar/object-page-operations-toolbar.component';
import {
  ObjectPageSearchNavigationComponent
} from '../object-page-v2/ui/object-page-header/object-page-search-navigation/object-page-search-navigation.component';
import {MatProgressSpinner} from '@angular/material/progress-spinner';



@NgModule({
  declarations: [
    MediaPageComponent
  ],
    imports: [
        CommonModule,
        ObjectViewModule,
        ObjectSearchModule,
        MediaCarouselModule,
        OperationsModule,
        OverviewFieldsModule,
        MatIconModule,
        ObjectContentTabModule,
        SharedModule,
        ObjectPageLayoutComponent,
        ObjectOverviewFieldsV2Component,
        ObjectPageHeadingComponent,
        MatCard,
        ObjectPageOperationsToolbarComponent,
        ObjectPageSearchNavigationComponent,
        MatProgressSpinner
    ]
})
export class MediaPageModule { }
