<div class="report-overview-toolbar" xmlns="http://www.w3.org/1999/html">
  <app-admin-selection-action-menu [selectedItems]="selectedReports">
    <app-admin-selection-action label="TRANS__REPORTS_V2_DELETE_HEADER" [action]="deleteReports"></app-admin-selection-action>
  </app-admin-selection-action-menu>

  <input matInput
         placeholder="{{'TRANS__REPORTS_V2_FILTER_IN_REPORTLIST' | translate}}"
         [(ngModel)]="searchText"
         (ngModelChange)="filterReports()" >

</div>
@if (generatedReports.status === 'loading') {
  <div class="loading-spinner">{{'TRANS__REPORTS_V2_LOADING_YOUR_REPORTS' | translate}}...</div>
} @else if (generatedReports.status === 'loaded') {
  @for(template of filteredReports; track template.id) {
    <div class="report-overview-box">
      <div class="report-preview-image">
        <mat-checkbox
          [checked]="selectedReports.includes(template.id)"
          (change)="reportSelectionChanged($event, template.id)">
        </mat-checkbox>
        <i class="material-icons">edit</i>
      </div>
      <div class="report-preview-icon">
        <i class="material-icons">insert_drive_file</i>
      </div>
      <div class="report-overview-fields" [routerLink]="['/reports/generated-reports', template.id]">
          <h1>{{template.name}}</h1>
          <label>{{ 'TRANS__REPORTS_V2_DESCRIPTION' | translate }}: <div class="report-overview-field">{{template.shortDescription}}</div></label>
          <label>{{ 'TRANS__REPORTS_V2_TYPE' | translate}}: <div class="report-overview-field">{{'TRANS__REPORTS_V2_CUSTOM_REPORT_TYPE' | translate}}</div></label>
          <label>{{ 'TRANS__REPORTS_V2_REPORT_TEMPLATE' | translate}}: <div class="report-overview-field">
            @if (template.reportViewType) {
              {{template.reportViewType.name}}
            } @else {
              {{'TRANS__REPORTS_V2_NO_REPORT_TEMPLATE_CHOSEN' | translate}}
            }
          </div></label>
          <label>{{ 'TRANS__REPORTS_V2_STATUS' | translate}}: <div class="report-overview-field">
            @if (template.reportStatus === 'generated') {
              {{ 'TRANS__REPORTS_V2_PDF_GENERATED' | translate }}
            } @else if (template.reportStatus === 'generating') {
              {{ 'TRANS__REPORTS_V2_PDF_GENERATION_STARTED' | translate}} {{template.reportDateStart|date:'dd/MM/yyyy kl. HH:mm:ss'}}
            } @else {
              {{'TRANS__REPORTS_V2_NEW' | translate}}
            }</div></label>
      </div>
    </div>
  }
}

<router-outlet></router-outlet>
