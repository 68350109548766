<div class="report-toolbar-area">
  <app-admin-selection-action-menu [selectedItems]="selectedTemplates">
    <app-admin-selection-action label="TRANS__REPORTS_V2_DELETE_LAYOUTS" [action]="deleteTemplates"></app-admin-selection-action>
  </app-admin-selection-action-menu>
</div>

@if (reportTemplates.status === 'loaded') {
  @for (template of reportTemplates; track template.id) {
    <div class="report-overview-box">
      <div class="report-preview-image">
        <mat-checkbox
          [checked]="selectedTemplates?.includes(template.id)"
          (change)="templateSelectionChanged($event, template.id)">
        </mat-checkbox>
      </div>
      <div class="report-preview-icon">
        <i class="material-icons">insert_drive_file</i>
      </div>
      <div class="report-overview-fields">
        <h1>{{ template.name }}</h1>
        <label>{{'TRANS__REPORTS_V2_REPORT_TEMPLATE' | translate}}:
          <div class="report-overview-field">
            @if (template.reportViewType) {
              {{template.reportViewType.name}}
            } @else {
              {{ 'TRANS__REPORTS_V2_NO_REPORT_TEMPLATE_CHOSEN' | translate }}
            }
          </div>
        </label>
      </div>
    </div>
  }

}
