
<main>
  <app-welcome-screen-ui
    [canUseAdvancedSearch]="canUseAdvancedSearch()"
    [savedSearches]="savedSearches()"
    [objectList]="objectList()"
    [categories]="categories()"
    (listItemClick)="onListItemClick($event)"
    (searchSubmit)="onSearchSubmit($event)"
    (advancedSearchClick)="onAdvancedSearchClick()"
    [loadingObjectList]="loadingObjectList()"
    [loadingCategories]="loadingCategories()"
    [loadingSavedSearches]="loadingSavedSearches()"
    (editFocusesClick)="onEditFocusesClick()"
    (searchSuggestionClick)="onSearchSuggestionClick($event)"
    [museumInstance]="museum"
    [recentRegistrations]="recentRegistrations()"
    [loadingRecentRegistrations]="loadingRecentRegistrations()"
    (recentRegistrationClick)="onRecentRegistrationClick($event)"
    [onlyMyOwnRegistrations]="onlyMyOwnRecentRegistrations()"
    (onlyMyOwnRegistrationsChange)="onOnlyMyOwnRegistrationsChange($event)"
  >

  </app-welcome-screen-ui>

  <section>
    <div>
      <div class="widget-header density--4">
        <span class="widget-title">{{ 'TRANS__TIPS_AND_TRICKS_WIDGET__TITLE' | translate }}</span>
      </div>
      <app-tips-and-tricks-widget></app-tips-and-tricks-widget>
    </div>

    <div>
      <div class="widget-header density--4">
        <span class="widget-title">{{ 'TRANS__FEATURE_FLAGS_WIDGET__TITLE' | translate }}</span>
        <button mat-icon-button [matMenuTriggerFor]="infoMenu">
          <mat-icon>info</mat-icon>
        </button>

        <mat-menu #infoMenu="matMenu">
          <p class="p-4">
            {{ 'TRANS__FEATURE_FLAGS_WIDGET__DESCRIPTION' | translate }}
          </p>
        </mat-menu>
      </div>
      <app-feature-flags-widget></app-feature-flags-widget>
    </div>
  </section>

  @if (createNewOperations()?.length) {
    <button mat-fab extended class="fab-button" (click)="onCreateNewClick()">
      <mat-icon class="material-symbols-outlined">add</mat-icon>
      {{'TRANS__WELCOME_SCREEN__CREATE_NEW' | translate}}
    </button>

  }
</main>
