@if (showLimitDescription && filterQuery()?.length < 2 && options()?.length > 0) {
  <span class="limit-descriptor">
    {{'TRANS__ADVANCED_SEARCH__QUERY_BUILDER_VALUE__SHOWING_MOST_USED' | translate}}
  </span>
}

<mat-tree class="options-view" [dataSource]="treeDataSource" [treeControl]="treeController">
  <!-- Is leaf node -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding class="options-view__node" matTreeNodePaddingIndent="16" [ngClass]="{'hidden': !node.visible && filterMode === 'self'}">
    <!-- Disabled to preserve padding -->
    <button mat-icon-button disabled (click)="nodeExpanded($event, node)"></button>
    @if (multiselect) {
      <mat-checkbox class="checkbox" (click)="nodeArraySelected(node)" [checked]="isChecked(node)"></mat-checkbox>
    }
    <button [disabled]="node.input_type === 'marker'" class="options-view__leaf-button" mat-button (click)="nodeSelected(node)" [innerHtml]="getHighlightedName(node)"></button>
  </mat-tree-node>

  <!-- Has children -->
  <mat-tree-node *matTreeNodeDef="let node; when: nodeHasChildren" matTreeNodePadding class="options-view__node" matTreeNodePaddingIndent="16" [ngClass]="{'hidden': !node.visible && filterMode === 'self'}">
    <button mat-icon-button matTreeNodeToggle (click)="nodeExpanded($event)">
      <mat-icon>
        {{treeController.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    @if (multiselect) {
      <mat-checkbox class="checkbox" (click)="nodeArraySelected(node)" [checked]="isChecked(node)"></mat-checkbox>
    }
    <button class="options-view__expandable-button" mat-button matTreeNodeToggle (click)="nodeExpanded($event, node)" [innerHtml]="getHighlightedName(node)"></button>
  </mat-tree-node>
</mat-tree>

@if (showLimitDescription && filterQuery()?.length < 2 && options()?.length > 0) {
  <span class="limit-descriptor">
    {{'TRANS__ADVANCED_SEARCH__QUERY_BUILDER_VALUE__TYPE_TO_SHOW_MORE' | translate}}
  </span>
}
