<div class="new_report_sidemenu">
  <span class="new_report_sidemenu_close" (click)="cancelNewReport()"><i class="material-icons">close</i></span>
  <h1>{{'TRANS__REPORTS_V2_CREATE_SIDEBAR_TITLE' | translate}}</h1>

  <div>{{'TRANS__REPORTS_V2_CREATE_SIDEBAR_GENERATE_FOR' | translate}} {{ searchContainer.selections.selectedItems.length }} {{'TRANS__REPORTS_V2_CREATE_SIDEBAR_ITEMS_SELECTED' | translate}}.</div>

  @if (newReport) {
    <form class="new_report_sidemenu_single_column_form">
      <div class="form-field">{{'TRANS__REPORTS_V2_CREATE_SIDEBAR_REPORT_TITLE' | translate}}</div>
      <input [ngClass]="{'new_report_form_field_error': newReportNameError}" class="form-field" type="text" name="title" placeholder="{{'TRANS__REPORTS_V2_CREATE_SIDEBAR_GIVE_TITLE' | translate}}."
             [(ngModel)]="newReport.name"/>
      @if (newReportNameError) {
        <div class="form-field-error">{{ newReportNameError | translate}}</div>
      }

      <div class="form-field">{{'TRANS__REPORTS_V2_CREATE_SIDEBAR_SHORT_DESC' | translate}}</div>
      <textarea [ngClass]="{'new_report_form_field_error': newReportShortDescriptionError}" class="form-field" name="shortDescription" placeholder="{{'TRANS__REPORTS_V2_CREATE_SIDEBAR_SHORT_DESC_PLACEHOLDER' | translate}}."
                [(ngModel)]="newReport.shortDescription"></textarea>
      @if (newReportShortDescriptionError) {
        <div class="form-field-error">{{ newReportShortDescriptionError | translate}}</div>
      }

      <div class="form-field">{{'TRANS__REPORTS_V2_CREATE_SIDEBAR_CHOOSE_REPORT_VIEW' | translate}}</div>

      <mat-form-field>
        <mat-label>{{'TRANS__REPORTS_V2_CREATE_SIDEBAR_CHOOSE_REPORT_VIEW_SHORT' | translate}}</mat-label>
        <mat-select [ngClass]="{'new_report_form_field_error': newReportViewTypeError}" [(value)]="newReport.reportViewTypeId" (selectionChange)="reportViewTypeChanged()">
          @for (reportView of this.reportViewTypes; track reportView.id) {
            <mat-option [value]="reportView.id">{{ reportView.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      @if (newReportViewTypeError) {
        <div class="form-field-error">{{ newReportViewTypeError | translate}}</div>
      }
    </form>
  }

  <div class="new_report_sidemenu_button-area">
    <button mat-flat-button (click)="cancelNewReport()">{{'TRANS__REPORTS_V2_BUTTON_CANCEL' | translate}}</button>
    <button mat-flat-button color="accent" (click)="validateForm()">{{ 'TRANS__REPORTS_V2_BUTTON_NEXT' | translate }}</button>
  </div>
</div>

