import {Component, Input, OnChanges} from '@angular/core';
import {CmsApiService} from '../../../core/cms-api.service';
import {CommonsService} from '../../../core/commons.service';
import {SuperObjectModel} from '../../../core/definitions/super-object-model';
import {BaseModel} from '../../../core/definitions/base-model';
import {DateToolsService} from '../../../core/date-tools.service';

export interface LogItem {
  updated_at: string;
  updated_by_id: string;
}

@Component({
  selector: 'app-object-update-log',
  templateUrl: './object-update-log.component.html',
  styleUrls: ['./object-update-log.component.scss']
})
export class ObjectUpdateLogComponent implements OnChanges {

  @Input() contextId: string;
  @Input() getLogItems: boolean;
  @Input() order?: 'asc' | 'desc' = 'asc';

  logItems: Array<LogItem> = [];
  finishedLoading: boolean;

  displayedColumns = ['updated_at', 'updated_by_id'];

  constructor(private cms: CmsApiService,
              private commons: CommonsService,
              private dateTools: DateToolsService) {
  }

  ngOnChanges() {
    if (this.contextId && this.getLogItems) {
      this.loadLogItems().then();
    }
  }

  private async loadLogItems() {
    this.logItems = [];
    this.finishedLoading = false;
    let updateLogs = await this.cms.getUpdateLog({contextId: this.contextId});
    updateLogs = this.commons.orderArray(updateLogs, 'updated_at', this.order === 'desc');
    this.logItems = updateLogs.map((data: SuperObjectModel) => this.getLogFields(data));
    this.finishedLoading = true;
  }

  private getLogFields(item: BaseModel) {
    return {
      updated_at: this.dateTools.isoDateToString(item.updated_at),
      updated_by_id: item.updated_by_id_value
    };
  }

}
