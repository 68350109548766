<div class="search-view-selector {{stylingClass}}"
     [ngClass]="{'template-selector':
     searchContainer.searchView.view_name === 'selector_template' ||
     searchContainer.searchView.view_name === 'selector_folders' ||
     searchContainer.searchView.view_name === 'selector_folder_objects' ||
     searchContainer.searchView.view_name === 'selector_search_field_group',
     'spectrum-procedures-selector': searchContainer.searchView.view_name === 'selector_spectrum_procedures',
     'modalIsOpen': modalIsOpen}"
     *ngIf="selectorContainer.selectorEnabled" [@slideSelector]>
  <div class="search-view-selector__container dropdownIgnoreClicks" id="searchMainContent">
    <div class="search-view-selector__content" id="innerCon">
      <div class="search-view-selector__top">
        <h5 *ngIf="searchContainer.searchView.view_name === 'selector_template'"
             class="search-view-selector__template-headline">
          <i class="icon-template"></i>
          <span translate="TRANS__ARTIFACT__CHOOSE_TEMPLATE_GROUP"></span>
          <button mat-icon-button [matMenuTriggerFor]="tooltipMenu">
            <i class="icon-help search-view-selector__template-headline__help-icon"></i>
          </button></h5>
        <button mat-button class="search-view-selector__close"
             (click)="disableSelector()">
          <mat-icon>close</mat-icon></button>
      </div>

      <div *ngIf="searchContainer.searchView.view_name === 'selector_template' && searchContainer.selections.originalItems[0]"
           class="search-view-selector__template-name">
        <span class="search-view-selector__template-name-text">
          <span translate="TRANS__ARTIFACT__SELECT_TEMPLATE_GROUP"></span>&nbsp;
          <app-object-name
            [object]="nameObject"
            class="template-text-blue"></app-object-name>
        </span>
        <button mat-stroked-button color="primary"
                class="search-view-selector__template-name-btn"
                (click)="cancelTemplate()">
          <mat-icon>close</mat-icon>
          <span translate="TRANS__SEARCH_VIEW_SELECTOR__REMOVE_SELECTED_TEMPLATE_GROUP"></span>
        </button>
      </div>

      <app-search-query
        class="search-view-selector__search-query"
        *ngIf="searchContainer"
        [searchContainer]="searchContainer"
        [placeholder]="searchPlaceholder"
        [setFocus]="setSearchQueryFocus"
        [newLayout]="newLayout"
      ></app-search-query>

      <div class="search-view-selector__inner-content flex items-center justify-end"
           *ngIf="searchContainer">

        <app-search-menu-toggle-restrictions
          *ngIf="searchContainer.restrictions"
          class="search-view-selector__toggle-restrictions"
          [searchContainer]="searchContainer"
        ></app-search-menu-toggle-restrictions>

        <app-search-menu-select-chosen
          class="flex-grow"
          [searchContainer]="searchContainer"
        ></app-search-menu-select-chosen>

        <app-search-filter-menu-small
          [searchContainer]="searchContainer"
          [showFilters]="true"
        ></app-search-filter-menu-small>

        <app-dropdown-menu-animated
          class="search-view-selector__sort-button sort-container"
          [ngClass]="{'active': searchContainer.toggleSortMenu}"
          [menuContainer]="{
          view: { description: 'TRANS__SEARCH__ORDER_ON', icon: 'sort',
          name: 'selector'},
          buttonClass: 'old-button',
          disableContainer: searchContainer.reportContainer,
          disableProp: 'show'}">

          <app-search-menu-sort
            [searchContainer]="searchContainer"
            [viewName]="'selector'"
          ></app-search-menu-sort>
        </app-dropdown-menu-animated>

      </div>

      <app-search-result-view
        *ngIf="searchContainer"
        class="search-view-selector__search-result"
        [searchContainer]="searchContainer"
      ></app-search-result-view>

      <app-search-result-pager-list
        class="float-left"
        *ngIf="searchContainer"
        [searchContainer]="searchContainer"
      ></app-search-result-pager-list>

    </div>
  </div>
</div>

<mat-menu #tooltipMenu="matMenu" xPosition="before" yPosition="below" class="function-tooltip">
  <div class="function-tooltip__inner-container">
    <div class="function-tooltip__title">
      <mat-icon>lightbulb_outline</mat-icon>
      <span class="bold" translate="TRANS__ARTIFACT__TEMPLATE_HELP_TITLE"></span>
    </div>

    <div class="function-tooltip__text" translate="TRANS__ARTIFACT__TEMPLATE_HELP_TEXT">
    </div>
  </div>
</mat-menu>
