@if (!objectPageV2()) {
  <div class="media-page object-and-media-page"
       [ngClass]="{'edit': currentObjectService.isEditing,
       'imageFullScreenOpen': imageFullScreen}">
    <!--<search-header search-container="searchContainer"></search-header>-->
    <div class="media-page__container object-and-media-page__container">
      <div class="media-page__content object-and-media-page__content row" id="artCon">

        <app-section-menu
          [sectionMenuContainer]="sectionMenuContainer"
        ></app-section-menu>

        <app-object-search-menu
          [object]="art"
          [isEditing]="currentObjectService.isEditing"
          [stylingClass]="'media-page'"
        ></app-object-search-menu>

        <div class="small-12 medium-12 large-6 large-push-6 columns media-page__media-carousel
        object-and-media-page__media-carousel"
             id="artifactImgContainer">
          <div class="media-page__media-carousel-template object-and-media-page__template"
               *ngIf="!mediumScreen">
            <div class="media-page__media-carousel-template-content"></div>
          </div>
          <div class="media-page__media-carousel-container
          object-and-media-page__media-carousel-content">
            <div class="media-page__media-carousel-content"
                 [ngClass]="{'selector-enabled': operationContainer?.selectorEnabled}">
              <app-media-carousel
                *ngIf="mediaContainer && mediaId"
                [startMediaId]="mediaId"
                [mediaContainer]="mediaContainer"
                [sectionsContainer]="operationContainer?.rootSectionsContainer"
                [curAnn]="currentObjectService.curAnn"
                [isEditing]="currentObjectService.isEditing"
                (mediaUpdated)="selectMedia($event)"
                (openImageFullScreen)="openImageFullScreen($event)"></app-media-carousel>
            </div>
            <app-search-view-selector
              *ngIf="operationContainer?.selectorEnabled"
              [stylingClass]="'media-page'"
              [selectorContainer]="operationContainer"></app-search-view-selector>
          </div>

        </div>
        <div class="small-12 medium-12 large-2 large-pull-2 columns
        media-page__object-menus
        object-menus-container
        landing-page-top
        landing-page-top__action-menu">
          <div class=" media-page__object-menus__content"
               *ngIf="operationContainer">
            <app-operation-menus-object
              *ngIf="!smallObjectMenus"
              [operationContainer]="operationContainer"
            ></app-operation-menus-object>
            <app-operation-menus-object-small
              *ngIf="smallObjectMenus"
              [operationContainer]="operationContainer"
            ></app-operation-menus-object-small>
          </div>
        </div>
        <div class="small-12 medium-12 columns landing-page-main-content">
          <div class="overlay"
               *ngIf="operationContainer?.selectorEnabled"
               (click)="selectorOverlay()"></div>
          <div class="landing-page-main-content__content" id="innerCon">
            <div class="landing-page-main-content__overview-fields">
              <div *ngIf="!operationContainer?.rootSectionsContainer"
                   class="loading-object-overview-fields"
                   [ngClass]="{'isEditing': currentObjectService.isEditing}">
                <div class="loading-object-overview-fields__header"></div>
                <div class="loading-object-overview-fields__line"></div>
                <div class="loading-object-overview-fields__line"></div>
                <div class="loading-object-overview-fields__line"></div>
                <div class="loading-object-overview-fields__line"></div></div>

              <app-object-overview-fields
                *ngIf="operationContainer?.rootSectionsContainer"
                [objectView]="operationContainer.rootSectionsContainer.objectView"
                class="landing-page-main-content__overview-fields-item"
                [isEditing]="currentObjectService.isEditing"
              ></app-object-overview-fields>
            </div>
            <app-section-menu-scroll [sectionMenuContainer]="sectionMenuContainer"
                                     class="landing-page-lower"
                                     id="artifactLower">

              <div class="landing-page-lower__container">

                <div class="landing-page-lower__content-tabs-container"
                     [ngClass]="{'isEditing': currentObjectService.isEditing}">
                  <div  *ngIf="!finishedLoading" class="loading-object-content-tabs">
                    <div class="loading-object-content-tabs__selected-first-menu"
                         [ngClass]="{'isEditing': currentObjectService.isEditing}">
                      <span class="bold text-blue" translate="TRANS__OVERVIEW_MENU__OVERVIEW"></span>
                      <mat-icon class="text-blue">expand_more</mat-icon></div></div>

                  <app-object-content-tabs
                    class="landing-page-lower__content-tabs"
                    *ngIf="contentInfo.artifact"
                    [contentInfo]="contentInfo"
                    (finishedLoading)="isFinishedLoading()"
                    [isEditing]="currentObjectService.isEditing"
                  ></app-object-content-tabs>
                </div>

                <div class="landing-page-lower__content">
                  <div *ngIf="contentInfo.curListName === 'overview'"
                       class="landing-page-lower__overview">
                    <app-object-view-and-edit
                      *ngIf="operationContainer?.rootSectionsContainer"
                      [isEditing]="currentObjectService.isEditing"
                      [sectionsContainer]="operationContainer.rootSectionsContainer"
                    ></app-object-view-and-edit>
                  </div>

                  <div *ngIf="contentInfo.curListName !== 'overview'"
                       class="landing-page-lower__content-list">
                    <div class="large-12 columns landing-page-lower__content-list__container">
                      <app-object-content-list
                        [contentInfo]="contentInfo"
                        (objectRefresh)="objectRefresh($event)"
                      ></app-object-content-list>
                    </div>
                  </div>
                </div>
              </div>
            </app-section-menu-scroll>
          </div>
        </div>
      </div>
      <div *ngIf="operationContainer?.currentOperation">
        <app-operation-toolbar
          *ngIf="operationContainer.currentOperation.$$currentStep && operationContainer.rootSectionsContainer"
          [operationContainer]="operationContainer"
          [operationStep]="operationContainer.currentOperation.$$currentStep"
          [selectorEnabled]="operationContainer.selectorEnabled"
          [stylingClass]="'media-page'"
        ></app-operation-toolbar>
      </div>
    </div>
  </div>

} @else {

  <app-object-page-layout [disableNavigation]="true" defaultImageDrawerWidth="50%">
    <app-object-page-heading
      slot="heading"
      [title]="currentObjectService?.currentObject?.artifact_name"
      [loading]="!operationContainer?.rootSectionsContainer"
    ></app-object-page-heading>

    <app-object-page-operations-toolbar
      [disabled]="currentObjectService?.isEditing"
      slot="operations"
      [actions]="headerActions"
      (actionClick)="onOperationClick($event)"
    ></app-object-page-operations-toolbar>

    <app-object-page-search-navigation
      slot="search-navigation"
      [artifactId]="currentObjectService?.currentObject?.artifact_id"
      [searchContext]="currentSearchContext"
      (closeClick)="onCloseClick()">
    </app-object-page-search-navigation>

    @if (!operationContainer?.rootSectionsContainer) {
      <div class="flex justify-center p-4">
        <mat-spinner diameter="20"></mat-spinner>
      </div>
    } @else {
      <mat-card class="overview-card">
        <app-object-overview-fields-v2
          *ngIf="operationContainer?.rootSectionsContainer"
          [objectView]="operationContainer?.rootSectionsContainer?.objectView"
          [isEditing]="currentObjectService?.isEditing"
        ></app-object-overview-fields-v2>
      </mat-card>

      <app-object-view-and-edit
        *ngIf="operationContainer?.rootSectionsContainer"
        [isEditing]="currentObjectService.isEditing"
        [sectionsContainer]="operationContainer.rootSectionsContainer"
      ></app-object-view-and-edit>

      <app-operation-toolbar
        *ngIf="operationContainer?.currentOperation?.$$currentStep && operationContainer?.rootSectionsContainer"
        [operationContainer]="operationContainer"
        [operationStep]="operationContainer.currentOperation.$$currentStep"
        [selectorEnabled]="operationContainer.selectorEnabled"
        [stylingClass]="'media-page'"
      ></app-operation-toolbar>
    }

    <div slot="right-drawer" class="h-full">

      @if (!operationContainer?.rootSectionsContainer) {
        <div class="skeleton-imagedrawer-container">
          <div class="image-container">
            <div class="skeleton"></div>
          </div>
          <div class="thumnails">
            <div class="skeleton"></div>
            <div class="skeleton"></div>
            <div class="skeleton"></div>
          </div>
        </div>
      } @else {
        <app-media-carousel
          *ngIf="mediaContainer && mediaId"
          [startMediaId]="mediaId"
          [mediaContainer]="mediaContainer"
          [sectionsContainer]="operationContainer?.rootSectionsContainer"
          [curAnn]="currentObjectService.curAnn"
          [isEditing]="currentObjectService.isEditing"
          (mediaUpdated)="selectMedia($event)"
          (openImageFullScreen)="openImageFullScreen($event)"></app-media-carousel>
      }
    </div>

  </app-object-page-layout>

  @if (operationContainer) {
    <app-search-view-selector
      *ngIf="operationContainer?.selectorEnabled"
      [stylingClass]="'media-page'"
      [selectorContainer]="operationContainer"></app-search-view-selector>
  }
}

<app-image-full-screen
  *ngIf="imageFullScreen"
  class="image-full-screen-container"
  (closeImageFullScreen)="closeImageFullScreen()"
  [mediaContainer]="mediaContainer"
  [curAnn]="currentObjectService.curAnn"
  [toggleAnnotations]="toggleAnnotations"></app-image-full-screen>
