<div class="search-col-overview-fields" #sizeElement>
  <app-object-url
    class="list-overview-fields__headline"
    *ngIf="searchObject"
    [object]="searchObject"
    [rootObjId]="rootObjId"
    [searchItemIndex]="searchItemIndex"
    [listName]="'overview'"
    [disable]="disableLinks">
    <div class="object-url-content">
      <i class="list-item-icon object-icon {{searchObject.$$icon}}"
         [class.text-blue]="searchObject.meta_type === MetaTypes.ADM_EVENT"></i>
      <h2 class="bold list-overview-fields__headline-text text-link">{{searchObject.artifact_name}}</h2>
    </div>
  </app-object-url>

  <div id="fieldContent"
       class="row list-overview-fields__content content-selector-source__content"
       [ngClass]="{'selector': viewName === 'selector'}">
    <div *ngFor="let overviewFields of columns"
         [ngClass]="{'small-12 medium-12 columns': columns.length === 1,
                        'small-6 columns': columns.length === 2,
                        'small-4 columns': columns.length === 3}">

      <div *ngFor="let field of overviewFields" class="{{field.container_class}}">

        <div class="list-overview-fields__field">
          <div class="list-overview-fields__field-title">
            <span>{{field.title}}:</span></div>

          <div class="list-overview-fields__field-content">
            <app-overview-field
              [overviewField]="field"
              [viewName]="viewName"
              [disableLinks]="disableLinks"
              [toneDownTextColor]="true"
            ></app-overview-field>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
