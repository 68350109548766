import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {DamsGridItem} from "../operation-view-dams-dialog.component";
import {NgClass} from "@angular/common";
import {MatCheckbox} from "@angular/material/checkbox";
import {FormsModule} from "@angular/forms";
import {DamsService} from "../../../core/dams.service";

@Component({
  selector: 'app-operation-view-dams-dialog-item',
  standalone: true,
  imports: [
    MatIcon,
    NgClass,
    MatCheckbox,
    FormsModule
  ],
  templateUrl: './operation-view-dams-dialog-item.component.html',
  styleUrl: './operation-view-dams-dialog-item.component.scss'
})
export class OperationViewDamsDialogItemComponent {
  @Input() index: number;
  @Input() item: DamsGridItem;
  @Input() image: any;

  @Output() batchToggleItems = new EventEmitter();

  firstError: boolean = true;

  brokenImage() {
    // Skip setting the hasImage flag first time
    // Because the delay from item rendered, to image load triggers error on ALL img tags regardless
    // then again when it gets a URL to attempt to load and fails
    if (this.firstError) {
      this.firstError = false;
    }
    else {
      // If it fails loading the image the second time, flag is set
      this.item.$$hasImage = false;
    }
  }

  toggleItemSelected(event: MouseEvent) {
    this.batchToggleItems.emit({
      index: this.index,
      state: !this.item.selected,
      shift: !!event.shiftKey
    });
  }

  protected readonly DamsService = DamsService;
}
