import {Component, inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogRef} from "@angular/material/dialog";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatFormField} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {FormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {MatCheckbox} from "@angular/material/checkbox";
import {FocusServiceImplementation} from "../../../search-focus.service";

export interface SavedSearchDialogData {
  currentName?: string;
  editMode: boolean;
  fsi: FocusServiceImplementation;
}

@Component({
  selector: 'app-saved-search-dialog',
  standalone: true,
  imports: [
    MatIconButton,
    MatIcon,
    MatFormField,
    MatInput,
    MatButton,
    FormsModule,
    MatDialogClose,
    TranslateModule,
    MatCheckbox,
  ],
  templateUrl: './saved-search-dialog.component.html',
  styleUrl: './saved-search-dialog.component.scss'
})
export class SavedSearchDialogComponent implements OnInit {
  data = {
    givenName: '',
    setOnClose: false,
    setAsDefault: false
  }

  error: string | null = null;

  readonly dialogRef = inject(MatDialogRef<SavedSearchDialogComponent>);
  readonly initData = inject<SavedSearchDialogData>(MAT_DIALOG_DATA);

  ngOnInit() {
    if (this.initData.currentName) {
      this.data.givenName = this.initData.currentName;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  validateAndClose() {
    let nameIsUnique = true;

    if (this.data.givenName === '') {
      this.error = 'TRANS__FILTER_MENU__NO_EMPTY_NAME';
      console.log(this.error);
    }
    else {
      this.error = null;
    }

    const focuses = this.initData.fsi.getFocuses();

    for (const focus of focuses) {
      if (this.data.givenName === focus.focus.name) {
        this.error = 'TRANS__FILTER_MENU__MUST_BE_UNIQUE';
        nameIsUnique = false;
      }
    }

    if (this.error === null && nameIsUnique) {
      this.dialogRef.close(this.data);
    }
  }

  get name(): string {
    return this.data.givenName;
  }

  set name(newValue: string) {
    this.data.givenName = newValue;
  }

  get setOnClose(): boolean {
    return this.data.setOnClose;
  }

  set setOnClose(newValue: any) {
    this.data.setOnClose = newValue;
  }

  get setAsDefault(): boolean {
    return this.data.setAsDefault;
  }

  set setAsDefault(newValue: boolean) {
    this.data.setAsDefault = newValue;
  }
}
