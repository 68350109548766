import {Component, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
import {MediaHelperService} from '../../core/media-helper.service';
import {Media} from '../../core/definitions/media';
import {objectTypeToMatSymbol} from '../../shared/material-icons';

@Component({
  selector: 'app-media-carousel-top-menu',
  templateUrl: './media-carousel-top-menu.component.html',
  styleUrls: ['./media-carousel-top-menu.component.scss']
})
export class MediaCarouselTopMenuComponent implements OnChanges {

  showInformationButton = false;
  showFullScreenButton = false;
  showZoom = false;
  showDownload = false;
  is3dModel = false;

  @ViewChild('zoomInButton') zoomInButton;

  @Input() zoomValue;
  @Input() isEditing;
  @Input() mediaElements: Media[];
  @Input() imageGallery;
  @Input() mediaIndex;
  @Input() toggleAnnotations;
  @Input() collapseZoomIn;
  @Input() mediumScreen;
  @Output() changedZoomValue = new EventEmitter<string>();
  @Output() openImageFullScreen = new EventEmitter<object>();
  @Output() openModelViewer = new EventEmitter<object>();

  constructor(private mediaHelper: MediaHelperService) { }

  ngOnChanges() {
    if (this.collapseZoomIn) {
      this.collapseZoomInButton();
    }
    this.setMediaMenu(this.mediaIndex);
  }

  get mediaElement() {
    return this.mediaElements[this.mediaIndex];
  }

  get mediaIcon() {
    return objectTypeToMatSymbol(this.mediaElement.mediaObject.object_type);
  }


  onChangedZoomValue(zoomValue) {
    this.changedZoomValue.emit(zoomValue.value);
  }

  private setMediaMenu(index) {
    const idPrefix = this.mediaHelper.getArtifactIdPrefix(this.mediaElements[index].mediaObject.artifact_id);
    switch (idPrefix) {
      case this.mediaHelper.PREFIX_IMAGE:
        this.showInformationButton = true;
        this.showFullScreenButton = true;
        this.showZoom = true;
        this.showDownload = true;
        this.is3dModel = false;
        break;
      case this.mediaHelper.PREFIX_VIDEO:
      case this.mediaHelper.PREFIX_AUDIO:
      case this.mediaHelper.PREFIX_ATTACHMENT:
        this.showInformationButton = true;
        this.showFullScreenButton = false;
        this.showZoom = false;
        this.showDownload = true;
        this.is3dModel = false;
        break;
      case this.mediaHelper.PREFIX_3D_MODEL:
        this.showInformationButton = true;
        this.showFullScreenButton = true;
        this.showZoom = false;
        this.showDownload = false;
        this.is3dModel = true;
        break;
      default:
        console.log(`Unknown media type ${idPrefix}`);
    }
  }

  collapseZoomInButton() {
    if (this.showZoom) {
      this.zoomInButton.closeMenu();
    }
  }

  openFullScreen() {
    this.collapseZoomInButton();

    if (this.is3dModel) {
      const activeModel = {
        modelId: this.mediaElements[this.mediaIndex].mediaObject.artifact_id,
        modelIndex: this.mediaIndex
      };

      this.openModelViewer.emit(activeModel);
    }
    else {
      const activeImage = {
        imageId: this.mediaElements[this.mediaIndex].mediaObject.artifact_id,
        imgIndex: this.mediaIndex,
        toggleAnnotations: this.toggleAnnotations
      };

      this.openImageFullScreen.emit(activeImage);
    }
  }
}
