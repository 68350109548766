<div class="wrapper">
  <div class="title">
    <mat-icon>{{ mediaIcon }}</mat-icon>
    <span class="file-name">
      @if (showInformationButton && !imageGallery && mediaElements[mediaIndex]) {
        <a [routerLink]="mediaElements[mediaIndex].mediaObject.$$routerLink"
           [queryParams]="mediaElements[mediaIndex].mediaObject.$$queryParams"
           queryParamsHandling="merge">{{ mediaElement.mediaObject.artifact_name }}</a>
      } @else {
        {{ mediaElement.mediaObject.artifact_name }}
      }
    </span>
  </div>
  <div class="buttons">
    @if (showZoom) {
      <button mat-icon-button
              [matMenuTriggerFor]="zoomIn"
              [matTooltip]="'TRANS__MEDIA_CAROUSEL__ZOOM' | translate"
              #zoomInButton="matMenuTrigger">
        <mat-icon class="material-symbols-outlined">zoom_in</mat-icon>
      </button>

      <mat-menu #zoomIn="matMenu">
        <div mat-menu-item>
          <mat-slider min="1" max="2" step="0.1"
                      (click)="$event.stopPropagation()" #ngSlider><input matSliderThumb [value]="zoomValue" (change)="onChangedZoomValue({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})" #ngSliderThumb="matSliderThumb" /></mat-slider></div>
      </mat-menu>
    }

    @if (showFullScreenButton) {
      <button (click)="openFullScreen()"
              [matTooltip]="'TRANS__MEDIA_CAROUSEL__FULL_SCREEN' | translate"
              [disabled]="isEditing" mat-icon-button>
        <mat-icon>fullscreen</mat-icon>
      </button>
    }

    @if (showDownload) {
      <app-download-url [object]="mediaElements[mediaIndex].mediaObject">
        <button mat-icon-button [matTooltip]="'TRANS__MEDIA_CAROUSEL__DOWNLOAD' | translate">
          <mat-icon>get_app</mat-icon>
        </button>
      </app-download-url>
    }
  </div>

</div>
