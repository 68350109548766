<div class="{{menuContainer.buttonClass}} {{menuContainer.view.name}}">
  <button
    [disabled]="menuContainer.disableContainer && menuContainer.disableContainer[menuContainer.disableProp]"
    [matMenuTriggerFor]="dropdownMenu"
    mat-stroked-button
  >
    <mat-icon matIconPrefix class="dropdown-menu-animated__button-icon">{{menuContainer.view.icon}}</mat-icon>
    <span class="dropdown-menu-animated__button-text">{{buttonLabel}}</span>
    <mat-icon iconPositionEnd class="dropdown-menu-animated__button-arrow">{{menuToggled ? 'expand_less' : 'expand_more'}}</mat-icon>
  </button>
  <mat-menu #dropdownMenu="matMenu"
            [overlapTrigger]="false">
    <!-- TODO(mat-3): This is not accessible. mat-menu-item must be content of this component -->
    <ng-content></ng-content>
  </mat-menu>
</div>

