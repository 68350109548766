import {Component, computed, ElementRef, inject, OnInit, signal, ViewChild} from '@angular/core';
import {WidgetContainerComponent} from '../widget-container/widget-container.component';
import {MatButton} from '@angular/material/button';
import {HelpdeskApiService, TipsArticle} from '../../shared/helpdesk-api.service';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {FreshArticlePreviewComponent} from '../../shared/fresh-article-preview/fresh-article-preview.component';

const animationTime = 200;
@Component({
  selector: 'app-tips-and-tricks-widget',
  standalone: true,
  imports: [
    WidgetContainerComponent,
    MatButton,
    MatProgressSpinner,
    TranslateModule,
    FreshArticlePreviewComponent
  ],
  templateUrl: './tips-and-tricks-widget.component.html',
  styleUrl: './tips-and-tricks-widget.component.scss',
})
export class TipsAndTricksWidgetComponent implements OnInit {

  private helpdeskApiService = inject(HelpdeskApiService);
  private translation = inject(TranslateService);

  private tipsAndTricksArticles = signal<TipsArticle[]>([]);
  private _currentIndex = signal(0);
  get currentIndex() {
    return this._currentIndex.asReadonly();
  }

  private _loading = signal(true);
  get loading() {
    return this._loading.asReadonly();
  }
  get articleLength() {
    return computed(() => this.tipsAndTricksArticles()?.length || 0);
  }

  currentTip = computed(() => {
    if (!this.tipsAndTricksArticles()?.length) {
      return null;
    }
    return this.tipsAndTricksArticles()[this._currentIndex()];
  })

  @ViewChild('articleContainer') articleContainer: ElementRef;

  ngOnInit() {
    this.init();
    this.translation.onLangChange.subscribe((e) => {
      this.init();
    })
  }

  onNextClick() {
    this._currentIndex.set((this._currentIndex() + 1) % this.tipsAndTricksArticles().length);
    this.articleContainer?.nativeElement?.scrollTo(0, 0);
  }

  onPreviousClick() {
    this._currentIndex.set((this._currentIndex() - 1 + this.tipsAndTricksArticles().length) % this.tipsAndTricksArticles().length);
    this.articleContainer?.nativeElement?.scrollTo(0, 0);
  }

  private init() {
    this._loading.set(true);
    this.helpdeskApiService.getTipsAndTricksArticles(this.translation.currentLang).subscribe({
      next: (response) => {
        this.tipsAndTricksArticles.set(response.items);
        this._currentIndex.set(Math.floor(Math.random() * response.items.length));
      },
      complete: () => {
        this._loading.set(false);
      },
    });
  }

  protected readonly length = length;
}
