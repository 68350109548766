import {Injectable, WritableSignal} from '@angular/core';
import {CommonsService} from './commons.service';
import {CmsApiService} from './cms-api.service';
import {PrimusRouteService} from './primus-route.service';
import {RefParams, RefService, StateParams} from './ref.service';
import {SuperObjectModel} from './definitions/super-object-model';
import {BaseModel} from './definitions/base-model';
import {Media} from './definitions/media';
import {ModelRelationsService} from './model-relations.service';
import {SearchParameters} from './definitions/search-parameters';
import {SolrFilterService} from './solr-filter.service';
import {SearchObject} from './definitions/search-object';
import {ObjectMediaContainer, ObjectMediaType} from './definitions/object-media-container';
import {MediaItem} from './definitions/media-item';
import {SearchService} from "./search.service";
import {FeatureFlagsService} from "./feature-flags.service";
import {ImageUrls} from "./definitions/image-url";
import {LoggerService} from "./logger.service";
import {TranslateService} from "@ngx-translate/core";
import {snackbarObject} from "../media-selector/media-selector-general/media-selector-general.component";

export interface MediaMetaData {
  objectType: string;
  arrayName: string;
  mediaName: string;
}

@Injectable({
  providedIn: 'root'
})
export class MediaHelperService {
  onDragStart: any;
  onDrag: any;
  onDragEnd: any;
  dragActive = false;
  draggingStarted: number;
  container: any;
  lastZoomValue: number;
  imageLoaded: number;
  draggableImage: any;
  lastMousePosition = {x: null, y: null};
  imageSize = {width: 0, height: 0};
  zoomValue: number;
  PREFIX_IMAGE = 'IMG';
  PREFIX_VIDEO = 'VID';
  PREFIX_ATTACHMENT = 'ATT';
  PREFIX_AUDIO = 'AUDIO';
  PREFIX_3D_MODEL = '3D';

  private imageBlacklist = new Set();
  private mediaObjectTypes = ['Image', 'Attachment', 'Video', 'Audio'];

  private imageMediaMetaData = {
    objectType: 'Image', arrayName: 'images', mediaName: 'TRANS__MEDIA_NAME__IMAGES'
  } as MediaMetaData;
  private attachmentMediaMetaData = {
    objectType: 'Attachment', arrayName: 'attachments', mediaName: 'TRANS__MEDIA_NAME__ATTACHMENTS'
  } as MediaMetaData;
  private videoMediaMetaData = {
    objectType: 'Video', arrayName: 'videos', mediaName: 'TRANS__MEDIA_NAME__VIDEOS'
  } as MediaMetaData;
  private audioMediaMetaData = {
    objectType: 'Audio', arrayName: 'audios', mediaName: 'TRANS__MEDIA_NAME__AUDIOS'
  } as MediaMetaData;
  private modelMediaMetaData = {
    objectType: 'model_3d', arrayName: 'models_3d', mediaName: 'TRANS__MEDIA_NAME_MODELS_3D'
  } as MediaMetaData;

  mediaMetaDataList: MediaMetaData[] = [
    this.imageMediaMetaData,
    this.videoMediaMetaData,
    this.attachmentMediaMetaData,
    this.audioMediaMetaData,
    this.modelMediaMetaData
  ];

  constructor(private logger: LoggerService,
              private cms: CmsApiService,
              private commons: CommonsService,
              private ref: RefService,
              private primusRoute: PrimusRouteService,
              private modelRelationsService: ModelRelationsService,
              private solrFilter: SolrFilterService,
              private searchService: SearchService,
              private featureFlagsService: FeatureFlagsService,
              private translate: TranslateService) {
  }

  public getDmsApiUrl(): string {
    return this.cms.getApiUrl('dms', true);
  }

  public async getImageUrl(media: BaseModel, imageSize: string): Promise<string> {
    let res = '';
    const imageId = this.getMediaId(media);
    const imageUrls = await this.getImageUrlsFromImageIds([imageId], [imageSize]);
    if (imageUrls) {
      res = imageUrls[imageId][imageSize];
    }
    return res;
  }

  public async getImageUrlsForObjects(media: BaseModel[], imageSizes: string[]): Promise<ImageUrls> {
    const imageIds = this.getMediaIds(media);
    if (imageIds.length) {
      return await this.getImageUrlsFromImageIds(imageIds, imageSizes);
    }
  }

  public async getImageUrlsFromImageIds(imageIds: string[], imageSizes: string[]): Promise<ImageUrls> {
    let res: ImageUrls;
    if (this.featureFlagsService.getFeatureFlags().temporary.multipleImageUrls) {
      try {
        if (this.featureFlagsService.getFeatureFlags().temporary.newImageUrlApi) {
          res = await this.cms.getImageUrls({image_ids: imageIds, sizes: imageSizes});
        } else {
          res = await this.cms.getImageUrlsOld({image_ids: imageIds, sizes: imageSizes});
        }
        this.setPlaceholderImages(res);
      } catch (e) {
        this.logger.error(`An error occurred getting image urls: ${e.message}`);
      }
    } else {
      res = await this.oldGetImageUrlsFromImageIds(imageIds, imageSizes);
    }
    return res;
  }

  public getAudioPlaceholderImageUrl() {
    return 'primus-assets/img/audio.png';
  }

  private setPlaceholderImages(imageUrls: ImageUrls) {
    for (const imageId in imageUrls) {
      for (const size in imageUrls[imageId]) {
        if (this.artifactIdIsAudio(imageId)) {
          imageUrls[imageId][size] = this.getAudioPlaceholderImageUrl();
        }
      }
    }
  }

  private async oldGetImageUrlsFromImageIds(imageIds: string[], imageSizes: string[]): Promise<ImageUrls> {
    const res = {};
    // Using the old getImageUrl call
    for (let imageSize of imageSizes) {
      for (let imageId of imageIds) {
        if (this.artifactIdIsAudio(imageId)) {
          res[imageId] = res[imageId] || {};
          res[imageId][imageSize] = this.getAudioPlaceholderImageUrl();
          continue;
        }
        if (this.imageBlacklist.has(imageId)) {
          continue;
        }
        const params = {
          image_id: imageId,
          size: imageSize
        };
        try {
          const imageUrl = await this.cms.getImageUrl(params);
          res[imageId] = res[imageId] || {};
          res[imageId][imageSize] = imageUrl.url;
        } catch (e) {
          this.imageBlacklist.add(params.image_id);
          console.error(`Getting image url failed for ${imageId}: ${e.error.message} 😥🚫`);
        }
      }
    }
    return res;
  }

  public async setThumbnailUrl(item: SearchObject, imageIdField: string, snackBar: WritableSignal<snackbarObject> = null) {
    let url: string;
    if (item.object_type?.toLowerCase() === 'attachment' || item.object_type?.toLowerCase() === 'video') {
      url = await this.getAttachmentThumbnailUrl(item);
    } else if (item.thumbnail_url) {
      url = item.thumbnail_url;
      if (item.artifact_id && this.artifactIdIsAudio(item.artifact_id)) {
        url = this.getAudioPlaceholderImageUrl();
      }
    } else if (item.thumbnail_id) {
      const urls = await this.getImageUrlsFromImageIds([item.thumbnail_id], ['thumbnail']);
      url = urls ? urls[item.thumbnail_id]['thumbnail'] : '';
    } else if (!imageIdField || imageIdField === '0') {
      url = await this.getThumbnailUrl(item, 'thumbnail_id');
    } else if (imageIdField) {
      url = await this.getThumbnailUrl(item, imageIdField);
    }
    if (!url) {
      return url;
    }
    item.$$thumbnailUrl = this.getImageUrlWithRandom(url);
    await this.checkWaitForUploading(item, url, snackBar);
  }

  cancelUploadWait(item: SearchObject) {
    item.$$uploadWaitCancel = true;
  }

  private async checkWaitForUploading(item: SearchObject, url: string, snackBar: WritableSignal<snackbarObject> = null) {
    if (item.thumbnail_id && item.$$uploading) {
      await this.waitForDoneUploadStatus(item, url, 60, snackBar);
    }
  }

  private getImageUrlWithRandom(url: string) {
    let random = '';
    if (!url.startsWith('primus-assets')) {
      random = `&random=${String(Math.random())}`;
    }
    return `${url}${random}`;
  }

  private async waitForDoneUploadStatus(item: SearchObject, url: string, downCount: number, snackBar: WritableSignal<snackbarObject> = null) {
    if (item.$$uploadWaitCancel) {
      if (item.$$uploadTimeoutId) {
        clearTimeout(item.$$uploadTimeoutId);
      }
      return;
    }
    if (!downCount) {
      item.$$uploading = false;
      return;
    }
    const status = await this.cms.getUploadStatus({artifact_id: item.thumbnail_id});
    item.$$dmsStatus = status;
    if (status === 'init' || status === 'convert' || status === 'converting') {
      if (status === 'converting') {
        item.$$uploadProgress = await this.cms.getUploadProgress({artifact_id: item.thumbnail_id});
        console.log('Progress: ' + item.$$uploadProgress);
      }
      item.$$uploadTimeoutId = setTimeout(() => {
        this.waitForDoneUploadStatus(item, url, downCount - 1, snackBar).then();
      }, 1000);
    } else if (status === 'done') {
      if (!!snackBar) {
        snackBar.set({
          text: this.translate.instant('TRANS__IMAGE_SELECTOR__CONVERTED', {name: item.artifact_name}),
          type: 'success'
        });
      }
      item.$$uploading = false;
      item.$$thumbnailUrl = this.getImageUrlWithRandom(url);
    } else {
      console.error(`Got status ${status} from server`);
    }
  }

  public async getThumbUrl(object: BaseModel, imageIdField?: string): Promise<string> {
    let res = '';
    const mediaId = this.getMediaId(object, imageIdField);
    const imageUrls = await this.getImageUrlsFromImageIds([mediaId], ['thumbnail']);
    if (imageUrls) {
      res = imageUrls[mediaId]['thumbnail'];
    }
    return res;
  }

  public async getMediaPlaybackUrls(media: SuperObjectModel): Promise<Array<any>> {
    if (media.object_type === 'Video' || media.object_type === 'Audio') {
      const type = media.object_type === 'Video' ? 'video' : 'audio';
      try {
        const videoJson = await this.cms.orderPlaybackUrls({artifact_id: this.getMediaId(media)});
        // @ts-ignore
        const dmsId = videoJson.dms_id;
        const res = [];
        // @ts-ignore
        for (const [key, url] of Object.entries(videoJson.urls)) {
          res.push({
            url: url,
            dmsId: dmsId,
            // url: $sce.trustAsResourceUrl(url),
            type: `${type}/${key}`
          });
        }
        console.log('234567890');
        console.log(res);
        return res;
      } catch (response) {
        console.error(`Unable to retrieve media URLS for ${this.getMediaId(media)}: ${response.message}: ${response.status}`);
      }
    } else if (media.object_type !== 'Image') {
      throw new Error(`Media type "${media.object_type} not supported yet`);
    }
  }

  public async getMediaUploadStatus(media: SuperObjectModel): Promise<string> {
    let status = '';
    try {
      status = await this.cms.getUploadStatus({artifact_id: media.artifact_id});
    } catch (response) {
      console.error(`Unable to retrieve media upload status for ${media.artifact_id}: ${response.message} : ${response.status}`);
    }
    return status;
  }

  public async getMediaUploadProgress(media: SuperObjectModel): Promise<string> {
    let progress = '';
    try {
      progress = await this.cms.getUploadProgress({artifact_id: media.artifact_id});
    } catch (response) {
      console.error(`Unable to retrieve media upload status for ${media.artifact_id}: ${response.message} : ${response.status}`);
    }
    return progress;
  }

  public async addImageUrls(objects: BaseModel[]) {
    const imageUrls = await this.getImageUrlsForObjects(objects, [
      'original', 'large', 'medium', 'thumbnail', 'max']);
    if (!imageUrls) {
      return;
    }
    for (const obj of objects) {
      const imageUrlsForObject = imageUrls[this.getMediaId(obj)];
      if (imageUrlsForObject) {
        obj.$$maxImageUrl = imageUrlsForObject['max'];
        obj.$$originalImageUrl = imageUrlsForObject['original'];
        obj.$$largeImageUrl = imageUrlsForObject['large'];
        obj.$$imageUrl = imageUrlsForObject['medium'];
        obj.$$thumbUrl = imageUrlsForObject['thumbnail'];
      }
    }
  }

  public async addThumbnailImageUrl(objects: BaseModel[]) {
    if (objects[0].$$thumbUrl) {
      // Already set
      return;
    }
    if (objects[0].thumbnail_url) {
      // Already set server side
      for (const object of objects) {
        object.$$thumbUrl = object.thumbnail_url;
      }
      return;
    }
    const thumbnailUrls = await this.getImageUrlsForObjects(objects, ['thumbnail']);
    if (!thumbnailUrls) {
      return;
    }
    for (const obj of objects) {
      const thumbnailUrlsForObject =  thumbnailUrls[this.getMediaId(obj)]
      if (thumbnailUrlsForObject) {
        obj.$$thumbUrl = thumbnailUrlsForObject['thumbnail'];
      }
    }
  }

  public async setMediaProps(mediaList: BaseModel[], parent: SuperObjectModel) {
    let contextIds = this.commons.getContextIds(parent.contexts);

    if (!contextIds) {
      contextIds = [parent.artifact_id];
    }
    for (const media of mediaList) {
      const ref = await this.ref.makeRef(new RefParams(
        <SuperObjectModel>media,
        {
          contextIds: contextIds,
          parentId: parent.artifact_id,
          parentObjectType: parent.object_type,
          rootObjId: this.primusRoute.params.rootObjId,
          rootObjType: this.primusRoute.params.rootObjType
        } as StateParams));
      media.$$parentId = ref.param.parentId;
      media.$$parentObjectType = ref.param.parentObjectType;
      media.$$routerLink = ref.routerLink;
      media.$$queryParams = ref.queryParams;
    }
  }


  public async getMediaContainerForAllObjectMedia(obj: SuperObjectModel): Promise<ObjectMediaContainer> {
    const objectMediaContainer = new ObjectMediaContainer();
    const mediaMetaDataDict = {};
    let order = 0;
    for (const mediaMetaData of this.mediaMetaDataList) {
      const mediaArrayMeta = obj.$$meta[mediaMetaData.arrayName];
      if (mediaArrayMeta) {
        mediaMetaDataDict[order++] = mediaMetaData;
      }
    }
    const mediaMetaDataList = Object.keys(mediaMetaDataDict)
      .map(key => Number(key))
      .sort((a, b) => a - b)
      .map(key => mediaMetaDataDict[key]);
    for (const mediaMetaData of mediaMetaDataList) {
      const media = await this.getMediaObjects(mediaMetaData, obj);
      if (media.length) {
        const objectMediaType = new ObjectMediaType();
        objectMediaType.mediaType = mediaMetaData.objectType;
        objectMediaType.mediaElements = media;
        objectMediaType.objectMediaArrayName = mediaMetaData.arrayName;
        objectMediaType.objectMediaName = mediaMetaData.mediaName;
        objectMediaContainer.mediaTypes.push(objectMediaType);
      }
    }
    return objectMediaContainer;
  }

  // Get object type specific media container. The media items originates from the object in 'artTarget'
  public async getMediaContainerForObjectAndObjectType(sourceObject: SuperObjectModel, mediaObjectType: string): Promise<ObjectMediaContainer> {
    let mediaMetaData: MediaMetaData;
    switch (mediaObjectType) {
      case 'Image':
        mediaMetaData = this.imageMediaMetaData;
        break;
      case 'Video':
        mediaMetaData = this.videoMediaMetaData;
        break;
      case 'Audio':
        mediaMetaData = this.audioMediaMetaData;
        break;
      case 'Attachment':
        mediaMetaData = this.attachmentMediaMetaData;
        break;
      default:
        console.warn(`Unknown media type ${mediaObjectType}`);
        return;
    }
    const mediaContainer = new ObjectMediaContainer();
    const mediaType = new ObjectMediaType();
    mediaContainer.mediaTypes.push(mediaType);
    mediaType.mediaType = mediaMetaData.objectType;
    mediaType.mediaElements = await this.getMediaObjects(mediaMetaData, sourceObject);
    mediaType.objectMediaArrayName = mediaMetaData.arrayName;
    mediaType.objectMediaName = mediaMetaData.mediaName;
    return mediaContainer;
  }

  public async getMediaContainerForMediaObject(mediaObject: SuperObjectModel): Promise<ObjectMediaContainer> {
    const mediaContainer = new ObjectMediaContainer();
    const mediaType = new ObjectMediaType();
    mediaContainer.mediaTypes.push(mediaType);
    mediaType.mediaType = mediaObject.object_type;
    const media = new Media();
    media.mediaObject = mediaObject;
    mediaType.mediaElements = [media];
    await this.addImageUrls([mediaObject]);
    return mediaContainer;
  }

  public getPosition(position: number, containerSize: number, itemSize: number) {
    if (position > 0) {
      position = 0;
    }
    if (position < (containerSize - itemSize)) {
      position = containerSize - itemSize;
    }
    return position;
  }

  public imgDrag(container: any, imageLoaded: number, draggableImage: any, imageSize: any, zoomValue: number) {
    this.container = container;
    this.imageLoaded = imageLoaded;
    this.draggableImage = draggableImage;
    this.imageSize = imageSize;
    this.zoomValue = zoomValue;
    this.dragActive = true;
    this.onDragStart = this.dragStart.bind(this);
    this.onDrag = this.drag.bind(this);
    this.onDragEnd = this.dragEnd.bind(this);
    this.container.addEventListener('mousedown', this.onDragStart, true);
    this.container.addEventListener('mousemove', this.onDrag, true);
    this.container.addEventListener('mouseup', this.onDragEnd, true);
    this.container.addEventListener('touchstart', this.onDragStart, true);
    this.container.addEventListener('touchmove', this.onDrag, true);
    this.container.addEventListener('touchend', this.onDragEnd, true);
  }

  public dragEnd(event: Event) {
    this.draggingStarted = 0;
    this.stopCallBack.bind(this);
  }

  public dragStart(event: any) {
    if (this.imageLoaded === 1 && this.dragActive) {
      this.draggingStarted = 1;
      if (event.type === 'touchstart') {
        this.lastMousePosition = {
          x: event.touches[0].pageX - this.container.offsetLeft,
          y: event.touches[0].pageY - this.container.offsetTop
        };
      } else {
        this.lastMousePosition = {
          x: event.pageX - this.container.offsetLeft,
          y: event.pageY - this.container.offsetTop
        };
      }
    }
  }

  public drag(event: any) {
    if (event.type === 'mousemove' && event.buttons === 1) {
      this.setDraggedImagePosition(event);
    }
    if (event.type === 'touchmove') {
      this.setDraggedImagePosition(event);
    }
  }

  private setDraggedImagePosition(event: any) {
    if (this.draggingStarted === 1) {
      let currentMousePosition: any;
      if (event.type === 'touchmove') {
        currentMousePosition = {
          x: event.touches[0].pageX - this.container.offsetLeft,
          y: event.touches[0].pageY - this.container.offsetTop
        };
      } else {
        currentMousePosition = {
          x: event.pageX - this.container.offsetLeft,
          y: event.pageY - this.container.offsetTop
        };
      }

      const changeX = currentMousePosition.x - this.lastMousePosition.x;
      const changeY = currentMousePosition.y - this.lastMousePosition.y;
      this.lastMousePosition = currentMousePosition;

      let img_top_new = this.draggableImage.offsetTop + changeY;
      let img_left_new = this.draggableImage.offsetLeft + changeX;
      const widthValue = this.imageSize.width * (Number(this.zoomValue) - 1);
      const heightValue = this.imageSize.height * (Number(this.zoomValue) - 1);
      const img_width = this.imageSize.width + widthValue;
      const img_height = this.imageSize.height + heightValue;

      if (this.container.offsetHeight > img_height) {
        this.draggableImage.style.top = 'initial';
      } else {
        img_top_new = this.getPosition(img_top_new,
          this.container.offsetHeight, img_height);
        this.draggableImage.style.top = img_top_new + 'px';
      }
      if (this.container.offsetWidth > img_width) {
        this.draggableImage.style.left = 'initial';
      } else {
        img_left_new = this.getPosition(img_left_new,
          this.container.offsetWidth, img_width);
        this.draggableImage.style.left = img_left_new + 'px';
      }
    }
  }

  public stopCallBack() {
    this.dragActive = false;
    this.draggingStarted = 0;
    if (this.container) {
      this.container.removeEventListener('mousedown',
        this.onDragStart, true);
      this.container.removeEventListener('mousemove',
        this.onDrag, true);
      this.container.removeEventListener('mouseup',
        this.onDragEnd, true);
      this.container.removeEventListener('touchstart',
        this.onDragStart, true);
      this.container.removeEventListener('touchmove',
        this.onDrag, true);
      this.container.removeEventListener('touchend',
        this.onDragEnd, true);
    }

  }

  public setNewImageSize(container: any, image: any, width: number, height: number) {
    this.container = container;
    if (width > this.container.offsetWidth ||
      height > this.container.offsetHeight) {

      if (width > height) { // Landscape
        this.imageSize.width = this.container.offsetWidth;
        this.imageSize.height = image.clientHeight;
      } else if (height > width) { // portrait
        this.imageSize.height = this.container.offsetHeight;
        this.imageSize.width = image.clientWidth;
      } else {
        this.imageSize = {
          width: this.container.offsetWidth,
          height: this.container.offsetHeight
        };
      }
    } else {
      this.imageSize = {
        width: width,
        height: height
      };
    }
    return this.imageSize;
  }

  public resizeImage(container: any,
                     zoomValue: number,
                     image: any,
                     imageNaturalSize: any,
                     imageActiveSize: any,
                     annotation: boolean) {
    this.zoomValue = zoomValue;
    this.imageSize = imageActiveSize;
    const widthValue = this.imageSize.width * (Number(zoomValue) - 1);
    const heightValue = this.imageSize.height * (Number(zoomValue) - 1);
    const newWidth = this.imageSize.width + widthValue;
    const newHeight = this.imageSize.height + heightValue;
    let height: string;
    let maxHeight: string;
    let width: string;
    let maxWidth: string;

    if (Number(this.zoomValue) === 1) {
      height = 'auto';
      maxHeight = '100%';
      width = 'auto';
      maxWidth = '100%';
      this.setWidthAndHeight(annotation, image, height, maxHeight, width, maxWidth);
      image.style.top = 'initial';
      image.style.left = 'initial';
      this.stopCallBack.bind(this);
    } else {
      if (imageNaturalSize.width > imageNaturalSize.height) { // Landscape
        height = 'auto';
        maxHeight = 'none';
        width = newWidth + 'px';
        maxWidth = 'none';
        this.setWidthAndHeight(annotation, image, height, maxHeight, width, maxWidth);
      } else if (imageNaturalSize.height > imageNaturalSize.width) { // portrait
        height = newHeight + 'px';
        maxHeight = 'none';
        width = 'auto';
        maxWidth = 'none';
        this.setWidthAndHeight(annotation, image, height, maxHeight, width, maxWidth);
      } else {
        height = newHeight + 'px';
        maxHeight = 'none';
        width = newWidth + 'px';
        maxWidth = 'none';
        this.setWidthAndHeight(annotation, image, height, maxHeight, width, maxWidth);
      }
      if (Number(this.lastZoomValue) !== Number(this.zoomValue)) {
        this.setPosition(container, imageActiveSize, image, newWidth, newHeight);
      }
    }
    this.lastZoomValue = this.zoomValue;
  }

  public setPosition(container: any, imageActiveSize: any, image: any, newWidth: number, newHeight: number) {
    let left: string;
    let top: string;
    if (imageActiveSize.width > container.offsetWidth) {
      const leftPos = this.getPosition(
        image.offsetLeft,
        container.offsetWidth,
        newWidth);
      left = leftPos + 'px';
    } else {
      left = 'initial';
    }
    if (imageActiveSize.height > container.offsetHeight) {
      const topPos = this.getPosition(image.offsetTop,
        container.offsetHeight, newHeight);
      top = topPos + 'px';
    } else {
      top = 'initial';
    }
    image.style.left = left;
    image.style.top = top;
  }

  public setWidthAndHeight(annotation: boolean, image: any, height: string, maxHeight: string, width: string, maxWidth: string) {
    image.style.maxHeight = maxHeight;
    image.style.maxWidth = maxWidth;
    image.style.height = height;
    image.style.width = width;
    if (annotation) {
      if (Number(this.zoomValue) === 1) {
        image.width = this.imageSize.width;
        image.height = this.imageSize.height;

      } else {
        image.width = width;
        image.height = height;
      }
    }
  }

  objectCanHaveImages(object: SuperObjectModel): Promise<boolean> {
    return new Promise(resolve => {
      this.modelRelationsService.objectCanHaveObjectTypes(object, ['Image']).then(res => {
        resolve(res);
      });
    });
  }

  getArtifactIdPrefix(artifactId: string) {
    const idPrefixEnd = artifactId.indexOf('-');
    return artifactId.substring(0, idPrefixEnd);
  }

  objectIsMedia(object: SuperObjectModel) {
    return this.mediaObjectTypes.indexOf(object.object_type) !== -1;
  }

  artifactIdIsAudio(artifactId: string) {
    return this.getArtifactIdPrefix(artifactId) === this.PREFIX_AUDIO;
  }

  private async getAttachmentThumbnailUrl(item: SearchObject) {
    let res = '';
    const attachmentId: string = item.artifact_id;
    if (!attachmentId) {
      return;
    }
    const urls = await this.getImageUrlsFromImageIds([attachmentId], ['thumbnail']);
    if (urls) {
      res = urls[attachmentId]['thumbnail'];
    }
    return res;
  }

  private async getThumbnailUrl(item: SearchObject, propName: string) {
    const thumb_id = item[propName];
    let imgUrl: string;
    if (thumb_id) {
      imgUrl = await this.getThumbUrl(item, propName);
    }
    return imgUrl;
  }

  private async createMediaObjects(parent: SuperObjectModel,
                                   mediaItemArtifactIds: string[],
                                   mediaObjects: SearchObject[],
                                   mediaItems?: MediaItem[]): Promise<Media[]> {
    const res: Media[] = [];
    for (const mediaArtifactId of mediaItemArtifactIds) {
      for (const mediaObject of mediaObjects) {
        if (mediaArtifactId === this.getMediaId(mediaObject)) {
          const mediaObjectCopy = Object.assign({}, mediaObject);
          const media = new Media();
          media.mediaObject = mediaObjectCopy;
          if (mediaItems) {
            this.setMediaItem(media, mediaItems);
          }
          res.push(media);
          break;
        }
      }
    }
    if (parent.artifact_id) {
      const mediaObjects = res.map(media => media.mediaObject);
      await this.setMediaProps(mediaObjects, parent);
      await this.addImageUrls(mediaObjects);
    }
    return res;
  }

  private setMediaItem(media: Media, mediaItems: MediaItem[]) {
    for (const mediaItem of mediaItems) {
      const mediaId = this.getMediaId(mediaItem);
      if (media.mediaObject.artifact_id === mediaId) {
        media.mediaItem = mediaItem;
        mediaItem.$$mediaId = mediaId;
        mediaItem.$$mediaName = this.getMediaName(mediaItem);
        break;
      }
    }
  }

  private async getMediaObjects(mediaMetaData: MediaMetaData, obj: SuperObjectModel): Promise<Media[]> {
    const mediaArtifactIds: string[] = [];
    let res: Media[] = [];
    let objects = [];
    if (mediaMetaData.objectType === obj.object_type) {
      objects = [obj];
      mediaArtifactIds.push(this.getMediaId(obj));
      res = await this.createMediaObjects(obj, mediaArtifactIds, objects);
    } else {
      const mediaItemArray: MediaItem[] = obj[mediaMetaData.arrayName];
      if (mediaItemArray) {
        mediaItemArray.forEach(mediaItem => {
          mediaArtifactIds.push(this.getMediaId(mediaItem));
        });
      }
      if (mediaArtifactIds.length > 0) {
        const params = {} as SearchParameters;
        this.solrFilter.addFq(params, 'artifact_id', mediaArtifactIds);
        params.rows = 1000;
        const mediaObjectSearch = await this.searchService.search(params);
        const mediaObjects = mediaObjectSearch.artifacts ? mediaObjectSearch.artifacts : [];
        if (mediaArtifactIds.length > mediaObjects.length) {
          console.warn(`Did not find all media: ${mediaArtifactIds}`);
        }
        if (mediaObjects.length > 0) {
          res = await this.createMediaObjects(obj, mediaArtifactIds, mediaObjects, mediaItemArray);
        }
      }
    }
    return res;
  }

  private getMediaId(item: BaseModel, imageIdField?: string): string {
    let res: string;
    if (imageIdField) {
      res = item[imageIdField];
    } else {
      res = item[this.commons.getObjectIdField(item)];
    }
    return res;
  }

  private getMediaIds(items: BaseModel[], imageIdField?: string): string[] {
    let res = [];
    for (const item of items) {
      let mediaId: string;
      const myImageIdField = imageIdField || this.commons.getObjectIdField(item);
      if (myImageIdField) {
        mediaId = item[myImageIdField];
        res.push(mediaId);
      } else {
        this.logger.warn(`No image id field found for ${JSON.stringify(item)}`);
      }
    }
    return res;
  }

  private getMediaName(item: BaseModel) {
    const idField = this.commons.getObjectIdField(item);
    let res: string;
    if (idField) {
      res = item[`${idField}_value`];
    }
    return res || 'name not found';
  }

}
