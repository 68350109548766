import {Component, EventEmitter, Input, Output, signal, WritableSignal} from '@angular/core';
import {DamsGridItem} from "../operation-view-dams-dialog.component";
import {DamsService} from "../../../core/dams.service";
import {MatIcon} from "@angular/material/icon";
import {MatButton, MatIconButton} from "@angular/material/button";
import {TranslateModule} from "@ngx-translate/core";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatOption, MatSelect} from "@angular/material/select";
import {MatInput} from "@angular/material/input";
import {FormsModule} from "@angular/forms";
import {
  EditFieldSelectMaterial3Component
} from "../../../object-edit/edit-field-select-material3/edit-field-select-material3.component";
import {FieldParameters} from "../../../core/definitions/field-parameters";
import {DamsImportItem, DamsNameImportItem} from "../../../core/definitions/dams-import-data";
import {
  OperationViewDamsImportNameComponent
} from "../operation-view-dams-import-name/operation-view-dams-import-name.component";

export interface DamsExtended {
  copyright?: any;
  copyright_clauses?: any[];
  description?: string;
  file_size?: number;
  keywords?: string[];
  licenses?: string[];
  mime_type?: string;
  places?: any[];
  producers?: any[];
  production_date?: string;
  resolution?: string;
}

interface DamsSuggestionItem {
  id: string;
  label: string;
}

@Component({
  selector: 'app-operation-view-dams-dialog-edit-item',
  standalone: true,
  imports: [
    MatIcon,
    MatIconButton,
    TranslateModule,
    MatButton,
    MatCheckbox,
    MatFormField,
    MatSelect,
    MatOption,
    MatSuffix,
    MatLabel,
    MatInput,
    FormsModule,
    EditFieldSelectMaterial3Component,
    OperationViewDamsImportNameComponent
  ],
  templateUrl: './operation-view-dams-dialog-edit-item.component.html',
  styleUrl: './operation-view-dams-dialog-edit-item.component.scss'
})
export class OperationViewDamsDialogEditItemComponent {
  @Input() item: DamsGridItem;
  @Input() image: any;

  @Output() removed = new EventEmitter();

  operationData: DamsImportItem = {
    description: '',
    producers: [],
    rights: []
  }
  suggestion: boolean = false;

  constructor() {
    console.log(this.item);
  }

  protected readonly DamsService = DamsService;

  // temporary hardcoding
  fieldParam: FieldParameters = {
    field: {
      //@ts-ignore
      $$hasIf: {
        disable: false,
        edit: false,
        field_warning: false,
        show: false
      },
      array_level: 0,
      display: "yes",
      edit: 'yes',
      field_type: 'map_id',
      field_uuid: 'af4f53a8-971e-45ac-8870-a025f1655ab0',
      input_type: 'map_id',
      key: 'photographer->named_id',
      name: 'named_id',
      order: 0o101,
      parent_name: 'photographer',
      path: 'photographer',
      placeholder: 'TRANS__IMAGE__PHOTOGRAPHER',
      reference_id: 'd12c464d-92b6-47bc-93aa-deb51639f979',
      sub_order: 0,
      title: 'TRANS__IMAGE__PHOTOGRAPHER'
    },
    rootObject: {
      $$meta: {
        photographer: {
          admin_title: 'TRANS__IMAGE__PHOTOGRAPHER',
          display: 'yes',
          edit: 'edit',
          field_type: 'inline',
          field_uuid: '',
          //@ts-ignore
          inline: {
            model: 'ImagePhotographer',
            prop: 'named_id',
          },
          input_type: 'inline',
          name: 'photographer',
          order: 1,
          title: 'TRANS__IMAGE__PHOTOGRAPHER'
        }
      }
    }
  }
}
