import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {ObjectPageComponent} from './object-page.component';
import {Observable} from 'rxjs';
import {PrimusRouterService} from '../../core/primus-router.service';
import {ObjectPageV2Component} from '../../object-page-v2/object-page-v2.component';

@Injectable()
export class CanDeactivateObjectPage  {
  constructor(private primusRouter: PrimusRouterService) {}

  canDeactivate(
    component: ObjectPageComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    if (currentRoute.component === ObjectPageV2Component) {

      if (nextState.url.includes('/overview') && nextState.url.includes('edit=true')) {
        // navigating TO the editing form should not trigger any dialogs
        return true;
      }

      // PRIM-5395: prevent dialog from showing when clicking "save and close"
      if (currentState.url.includes('edit=true') && nextState.url.includes('edit=false')) {
        return true;
      }

    }
    return this.primusRouter.checkCancel();
  }
}


