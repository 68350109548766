import {OperationDef} from '../../../../core/definitions/operation-def';
import {iconToMaterialSymbol} from '../../../../shared/material-icons';
import {ActionMenuItem} from '../menu-group-button/menu-group-button.component';

export type ActionMenuAndOperationDef = ActionMenuItem & OperationDef

export function operationToActionMenu(op: OperationDef): ActionMenuAndOperationDef {
  if (op.operation_type === 'operation') {
    return {
      ...op,
      type: 'action',
      label: op.menu_title,
      id: op.menu_title,
      icon: iconToMaterialSymbol(op.menu_icon),
    }
  } else if (op.operation_type === 'group') {
    return {
      ...op,
      type: 'group',
      label: op.menu_title,
      id: op.menu_title,
      icon: iconToMaterialSymbol(op.menu_icon),
      children: op.sub_operations?.map(operationToActionMenu)
    }
  }
}
