import {Component, Input, OnChanges} from '@angular/core';
import {OperationService} from '../operation.service';
import {CurrentObjectService} from '../../core/current-object.service';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {OperationDef} from '../../core/definitions/operation-def';
import {style, transition, trigger, useAnimation} from '@angular/animations';
import {fadeIn} from '../../shared/animations';
import {OperationContainer} from '../../core/definitions/operation-container';
import {PrimusRouterService} from '../../core/primus-router.service';
import {Router} from '@angular/router';
import {FeatureFlagsService} from '../../core/feature-flags.service';

@Component({
  selector: 'app-operation-menus-object',
  templateUrl: './operation-menus-object.component.html',
  styleUrls: ['./operation-menus-object.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({opacity: 0}),
        useAnimation(fadeIn, {params: {time: '1000ms'}})
      ])
    ])
  ]
})
export class OperationMenusObjectComponent implements OnChanges {

  @Input() operationContainer: OperationContainer;

  constructor(private operationService: OperationService,
              private primusRouter: PrimusRouterService,
              private featureFlagsService: FeatureFlagsService,
              private router: Router,
              private currentObjectService: CurrentObjectService) {
  }

  ngOnChanges(): void {

  }

  get isEditing() {
    return this.currentObjectService.isEditing;
  }

  get mediaPage() {
    return this.operationContainer.operationContextObjects[0].meta_type === 'media';
  }

  get rootObject(): SuperObjectModel {
    return this.operationContainer.operationContextObjects[0];
  }

  openOperation(operation: OperationDef, parentOperation: OperationDef) {
    this.operationService.openOperationView(this.operationContainer, operation, parentOperation).then(res => {
      if (res) {
        console.log('Result: ' + res.status_message);
      }
    });
  }

  toggleOperationMenu(operation: OperationDef) {
    this.operationService.toggleOperationMenu(this.operationContainer, operation).then(res => {
      if (res) {
        console.log('Result: ' + res.status_message);
      }
    });
  }

}
