<div class="saved-search-dialog">
  <div class="saved-search-dialog__header">
    <span class="saved-search-dialog__header-label">
      @if(initData.editMode) {
        {{ 'TRANS__SAVED_SEARCH__DIALOG_TITLE__EDIT' | translate }}
      }
      @else {
        {{ 'TRANS__SAVED_SEARCH__DIALOG_TITLE__CREATE' | translate }}
      }
    </span>

    <button mat-icon-button (click)="closeDialog()">
      <mat-icon>
        clear
      </mat-icon>
    </button>
  </div>

  <div class="saved-search-dialog__content">
    <span>
      {{ 'TRANS__SAVED_SEARCH__DIALOG_CONTENT' | translate }}
    </span>

    <mat-form-field>
      <input
        matInput
        type="text"
        [placeholder]="'TRANS__SAVED_SEARCH__DIALOG_PLACEHOLDER' | translate"
        [(ngModel)]="name"
      />
    </mat-form-field>

    @if (error !== null) {
      <span class="saved-search-dialog__error-label">
        {{ error | translate }}
      </span>
    }

    @if (!initData.editMode) {
      <div class="saved-search-dialog__checkbox-container">
        <mat-checkbox [(ngModel)]="setOnClose">
          {{ 'TRANS__SAVED_SEARCH__DIALOG__SET_AS_FOCUS' | translate }}
        </mat-checkbox>

        <mat-checkbox [(ngModel)]="setAsDefault" [disabled]="!data.setOnClose">
          {{ 'TRANS__SAVED_SEARCH__DIALOG__SET_AS_DEFAULT' | translate }}
        </mat-checkbox>
      </div>
    }
  </div>

  <div class="saved-search-dialog__actions">
    <button mat-flat-button color="primary" (click)="validateAndClose()">
      {{ 'TRANS__SAVED_SEARCH__CONFIRM' | translate }}
    </button>

    <button mat-button (click)="closeDialog()">
      {{ 'TRANS__SAVED_SEARCH__CANCEL' | translate }}
    </button>
  </div>
</div>
